
.label-text-congrats {
  color: var(--NeutralAlpha-800, rgba(64, 67, 91, 0.9));
  text-align: center;

  font-family: SourceSansProBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;

  margin-top: 16px;
  margin-bottom: 16px;
}
.subtext-update {
  color: var(--Neutral-700, #6b6f93);
  text-align: center;

  font-family: SourceSansProRegular;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.container-image{
  display: flex;
  justify-content: center;
}