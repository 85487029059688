.wrapper-description {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;

    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.product-name {
    font-size: 16px;
    font-weight: 600;
    color: #40435B;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

.icon{
    margin-right: 8px;
}