.text {
    color: var(--Semantic-Error-600, #e22e2e);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: 'Source Sans Pro';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
    width: max-content;
    margin-left: 20px;
    cursor: pointer;
}