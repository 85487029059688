.input-picker-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.input-picker {
    border-radius: 8px;
    border: 1px solid var(--Neutral-700, #6b6f93);
    background: var(--Neutral-White, #fff);
}

#form-input-picker-name,
.input-picker-container div div div span {
    color: var(--Neutral-800, #40435b) !important;
    font-feature-settings: 'clig' off, 'liga' off;
    text-overflow: ellipsis;
    white-space: nowrap;

    /* Body/Body 1/Regular */
    font-family: 'Source Sans Pro';
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 20px !important; /* 125% */
}

#placeholder,
.input-picker-container div div div span span {
    color: var(--Neutral-700, var(--700, #6b6f93));
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
}

.input-picker-label {
    color: var(--Neutral-800, #40435b);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Label/Label 2 */
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 128.571% */
}

.input-picker-required {
    color: #e22e2e;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-left: 4px;
}

.input-picker-error {
    border-radius: 8px;
    border: 1px solid var(--Neutral-700, #e22e2e);
    background: var(--Neutral-White, #fff);
}

.form-error-message {
    color: #e22e2e;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.form-input-optional {
    color: var(--Neutral-700, #6b6f93);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Caption/Caption 1 */
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-left: 4px;
}

.form-loader-caret-container {
    position: absolute;
    right: 15px;
    top: 10px;
}

.form-loader-container-skeleton {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px 12px;
}

.form-loader-option-skeleton {
    width: 220px;
    height: 16px;
    border-radius: 4px;
    background-color: #e0e0e0;
    animation: slide 1.5s infinite ease-in-out;
}

@keyframes slide {
    0% {
        background-color: #e0e0e0;
    }
    50% {
        background-color: #f0f0f0;
    }
    100% {
        background-color: #e0e0e0;
    }
}
