.search-results-container {
    /* padding-top: 16px !important;
    padding-bottom: 16px !important; */
    width: 100% !important;
    margin: 0px !important;
    padding: 0px 16px 0 0;
}

.container {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    width: 100% !important;
    margin: 0px !important;
}


.wrapper-paragraph div div p {
    width: 90% !important;
}

.button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}