/* background image box left */
.image {
    grid-area: 1 / 1 / 1 / 1;
    width: 100%;
    background-image: url('/assets/images/BG_inicio de sesion.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

/* Estilos para pantallas pequeñas (menos de 768px) */
@media (max-width: 768px) {
    .image {
        display: none;
    }
}

/* welcome text */
.welcome-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 36px;
    width: 100%;
    height: 100vh;
}

.text {
    color: var(--Neutral-50, #f5f6fa);
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 133%;
    width: 300px;
}
