.cell {
    /* margin-bottom: 4px; */
    position: relative;
    /* background-color: #badbff; */
    border-left: 1px solid var(--Secondary-300, #ffb485);
}

/* LAST CELL PADDING ADD WHEN NOT SHOW CDO */
.cell [class^='CellStyles_price-section'] {
    padding-right: 16px;
}

/* CLOSE RECOMMENDATIONS */
.limit-recommendation-close {
    text-align: center;
}

.limit-recommendation-close > p {
    font-size: 14px;
    color: rgb(1, 113, 230);
    font-weight: 600;
    display: flex;
    height: 82px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.limit-recommendation-close img {
    color: rgb(1, 113, 230);
}
