/* -----------------------TABLE RECOMMENDED------------------------ */
.table-with-transition {
    border-radius: 8px 8px 8px 8px;
    background-color: #fff6ef;
}

/* ANIMATIONS */
/* Animación de achicamiento horizontal */
@keyframes shrink {
    0% {
        width: 100%;
    }
    50% {
        width: 90%; /* Achica la celda al 10% de su tamaño original */
    }
    100% {
        width: 100%; /* Vuelve a su tamaño original */
    }
}

/* Aplicar la animación cuando la celda es clickeada */
.shrink {
    animation: shrink 0.5s;
}

/* NEW STYLE */
.cell {
    width: var(--cell-max-width);
}

.row {
    border-top: 1px solid var(--Secondary-300, #ff8e47);
    border-bottom: 1px solid var(--Secondary-300, #ff8e47);
    border-left: 1px solid var(--Secondary-300, #ff8e47);
    border-right: 1px solid var(--Secondary-300, #ff8e47);
    border-radius: 8px;
    overflow: hidden;
    margin-top: 4px;
    margin-bottom: 4px;
}

.middle::-webkit-scrollbar {
    display: none;
}

.middle > div {
    height: 82px;
}

.middle > div:last-child {
    position: sticky;
    top: 0;
    right: 0;
}

.middle-without-cdo::-webkit-scrollbar {
    display: none;
}

.middle-without-cdo > div {
    height: 82px;
}

/* DEFAULT BACKGROUND CELLS PRICE IN COLUMN MIDDLE */
.middle > div:nth-child(even) {
    background-color: #fff6ef;
}
.middle > div:nth-child(odd) {
    background-color: #ffece0;
}

.middle-without-cdo > div:nth-child(even) {
    background-color: #fff6ef;
}
.middle-without-cdo > div:nth-child(odd) {
    background-color: #ffece0;
}

/* LIMIT RECOMMENDATIONS VISIBLE */
.middle-limit-recommendations::-webkit-scrollbar {
    display: none;
}

.middle-limit-recommendations > div {
    height: 82px;
}

/* .middle-limit-recommendations > div:last-child > div {
    background-color: #badbff;
} */

.middle-limit-recommendations > div:last-child {
    position: sticky;
    top: 0;
    right: 0;
}

/* DEFAULT BACKGROUND CELLS PRICE IN COLUMN MIDDLE */
.middle-limit-recommendations > div:nth-child(even) {
    background-color: #ffece0;
}
.middle-limit-recommendations > div:nth-child(odd) {
    background-color: #ffece0;
}

/* OVERLAP */
.two-overlap::after {
    content: '';
    position: absolute;
    top: 84px;
    right: -4px;
    width: 4px;
    height: 64px;
    border-radius: 0px 4px 4px 0px;
    background: var(--Discount-500, #ffb485);
}

.two-overlap::before {
    content: '';
    position: absolute;
    top: 90px;
    right: -8px;
    width: 4px;
    height: 50px;
    border-radius: 0px 4px 4px 0px;
    background: var(--Discount-500, #ffcdad);
}

.one-overlap::after {
    content: '';
    position: absolute;
    top: 84px;
    right: -4px;
    width: 4px;
    height: 64px;
    border-radius: 0px 4px 4px 0px;
    background: var(--Discount-500, #ffb485);
}

/* ARROW */
.arrow {
    position: absolute;
    right: -16px;
    top: 104px;
    z-index: 1;
    display: flex;
    width: 24px;
    height: 24px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background: var(--Primary-600, #0171e6);
}

/* FIX SCROLLBAR */
.middle, .middle-without-cdo, .middle-limit-recommendations {
    scrollbar-width: none; /* hide horizontal scrollbar in Firefox */
}