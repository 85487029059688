.cell {
    min-width: 306px;
    height: 82px;
    display: grid;
    grid-template-columns: 63px auto minmax(85px, 95px);
    height: 82px;
    padding: 0px 16px 0px 8px;
    align-items: center;
    gap: 8px;
    background-color: #fff6ef;
}

.without-border {
    background: var(--Discount-50, #ffece0);
}

/* --------------- IMG ---------- */
.img {
    grid-column: 1 / 2;
    display: flex;
    padding: 4px;
    height: 63px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    border-radius: 8px;
    border: 0.5px solid var(--Neutral-200, #acaec4);
    background: var(--Neutral-white, #fff);
}

/* --------------- DESCRIPTION ---------- */
.product-description {
    text-align: left;
    grid-column: 2 / 3;
}

.recommended {
    display: flex;
    padding: 1px 8px;
    align-items: center;
    gap: 8px;
    width: max-content;

    border-radius: 4px;
    border: 0.5px solid var(--Discount-Discount, #ff6300);
    background: var(--Discount-50, #ffece0);
    color: var(--Discount-Discount, #ff6300);
    font-feature-settings: 'clig' off, 'liga' off;

    font-family: 'Source Sans Pro';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.description {
    padding-top: 4px;
    min-width: 121px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align: left;
}

.description > p {
    color: var(--Neutral-800, #40435b);
    font-feature-settings: 'clig' off, 'liga' off;

    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    margin: 0;
}

/* --------------- BUTTON ---------- */
.button {
    grid-column: 3 / 4;
}

.button > button {
    width: 100%;
    display: flex;
    height: 32px;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    border-radius: 8px;
    border: 1px solid var(--Primary-600, #0171e6);
    background: var(--Neutral-white, #fff);
}

.button > button:disabled {
    border: 1px solid var(--Neutral-100, #e0e1e9);
}

.button > button:disabled .add-text {
    color: #acaec4;
}

.add-text {
    color: var(--Primary-600, #0171e6);
    font-feature-settings: 'clig' off, 'liga' off;

    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}

@media (max-width: 1100px) {
    .add-text {
        display: none;
    }
}

/* LIMIT */
.limit-recomendation-text {
    width: 100%;
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 60vw;
}

.limit-recomendation-text > p:first-child {
    color: #0b2033;
    display: -webkit-box;
    overflow: hidden;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    word-wrap: break-word;
    white-space: pre-wrap;
    text-overflow: ellipsis;
    text-transform: uppercase;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.limit-recomendation-text > p:last-child {
    color: rgb(107, 111, 147);
    margin: 0;
}

/* PLACEHOLDERS */
.placeholder-row {
    width: 100px;
}

.placeholder-row > div > p {
    width: 100px !important;
}

/* PLACEHOLDERS */
.placeholder-row {
    width: 100px;
}

.placeholder-row > div > p {
    width: 100px !important;
}

.placeholder-description {
    width: 220px;
}

.placeholder-description > div > p {
    width: 220px !important;
}
