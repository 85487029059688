.filename-text {
  color: #40435b;
  font-family: SourceSansProSemiBold;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  justify-content: center;
}

.wrapper-image {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
}