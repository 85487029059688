.container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0171e6;
    border-radius: 8px;
    padding: 10px 24px 10px 24px;
    height: 40px;
    text-decoration: none !important;
    width: 100%;
}

/* .container:hover,
.container:active,
.container:focus {
    border-radius: 8px;
    border: 0.5px dashed var(--Primary-600, #0171e6);
    background: var(--Primary-25, #dcedff);
} */

.container > div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.container > div > p {
    font-family: Source Sans Pro;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    color: #fff !important;
}

/* .container:hover > div > p {
    color: var(--Primary-600, #0171e6);
} */
