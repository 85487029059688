.logoIcon {
    position: absolute;
    height: 100%;
    width: 83%;
    top: 0%;
    right: -3.58%;
    bottom: 0%;
    left: 20.58%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
}
.isoIcon {
    position: absolute;
    height: 100%;
    width: 16.11%;
    top: 0%;
    right: 83.89%;
    bottom: 0%;
    left: 0%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
}
.isologohorizontal {
    width: 247px;
    position: relative;
    height: 33px;
}
.laPlataformaDe {
    position: relative;
    line-height: 160%;
}
.isologohorizontalParent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
}
.mapaDeSitio {
    align-self: stretch;
    position: relative;
    text-transform: uppercase;
    font-weight: 500;
}
.mapaDeSitioWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #bed0e5;
    font-family: 'Golos Text';
}
.calcularAhorro {
    align-self: stretch;
    position: relative;
    color: #fff;
}
.calcularAhorroWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.distribuidoresParent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
}
.frameDiv {
    flex: 1;
    height: 134px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 8px;
    font-size: 12px;
    color: #fff;
    font-family: 'Plus Jakarta Sans';
}
.plataforma {
    position: relative;
    text-transform: uppercase;
    font-weight: 500;
}
.iniciarSesinWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #fff;
    font-family: 'Plus Jakarta Sans';
}
.frameParent1 {
    flex: 1;
    height: 134px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    margin-top: -6px;
}
.vectorIcon {
    width: 13px;
    position: relative;
    height: 13px;
}
.iconTwitter {
    width: 12.9px;
    position: relative;
    height: 12.9px;
}
.vectorIcon1 {
    width: 14px;
    position: relative;
    height: 13px;
}
.vectorIcon2 {
    width: 17px;
    position: relative;
    height: 13px;
}
.vectorParent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
}
.miscelaneasParent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
}
.frameWrapper1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}
.frameWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: -3px;
}
.frameContainer {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 14px;
    color: #bed0e5;
    font-family: 'Golos Text';
}
.frameGroup {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
}
.dividerIcon {
    width: 327px;
    position: relative;
    max-height: 100%;
    object-fit: contain;
}
.extendealTodosLosDerechos {
    width: 330px;
    position: relative;
    letter-spacing: 0.04em;
    line-height: 223%;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}
.extendealTodosLosDerechosRWrapper {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-content: center;
    padding: 8px 8px 8px 0px;
}
.dividerParent {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: center;
    font-size: 8px;
    color: #bed0e5;
}
.frameParent {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 32px;
}
.footerInner {
    width: 327px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.footer {
    border-radius: 24px 24px 0px 0px;
    background: linear-gradient(171.18deg, #216099, #0b2033 94.73%);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 32px 16px 24px;
    box-sizing: border-box;
    text-align: left;
    font-size: 12px;
    color: #fff;
    font-family: 'Plus Jakarta Sans';

    margin-top: 96px;
}

