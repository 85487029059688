.product-row {
    min-height: 73px;
    padding: 16px;
    border-width: 1px 0px 0px 0px;
    border-style: solid;
    border-color: #e3e3e3;
}

.product-name {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 280px;
    color: #646464;
    font-size: 14px;
    margin: 0px;
}

.product-code {
    color: #646464;
    font-size: 12px;
    margin: 0px;
    padding-top: 4px;
}

.product-count {
    display: flex;
    justify-content: flex-end;
    color: #646464;
    font-size: 14px;
    align-items: center;
    height: 40px;
    flex-direction: column;
}
