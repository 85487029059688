.paragraphLoaderImage {
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    overflow: hidden;
    justify-content: center;
    border-radius: 8px;
}

.paragraphLoaderImage div::before
{
    border-bottom: 18px solid var(--rs-bg-card) !important;
    border-left: 9px solid transparent !important;
    border-right: 9px solid transparent !important;
    left: 55px !important;
}

.paragraphLoaderImage div::after
{
    border-bottom: 36px solid var(--rs-bg-card) !important;
    border-left: 19px solid transparent !important;
    border-right: 19px solid transparent !important;
    left: 25px !important;
}




