.button {
  height: 100%;
  grid-column: 2 / 3;
  display: flex;
  align-items: baseline;
  justify-content: end;
  position: relative;
}
.icon {
  display: flex;
  width: 20px;
  max-width: 24px;
  height: 20px;
  justify-content: center;
  align-items: center;
}
.container-popover-menu {
  padding: 8px 0px !important;
  border-radius: 8px;
  border: 1px solid #e0e1e9;
  background: #fff;
  margin-left: 10px !important;
  /* lg */
  box-shadow: 0px 4px 6px -2px rgba(64, 67, 91, 0.05), 0px 10px 15px -3px rgba(64, 67, 91, 0.1);
}
.container-button {
  width: 24px;
  height: 24px;
  border-radius: 8px;
  background: transparent;
  color: #0171e6;
}
.container-button:focus {
  background-color: transparent;
  color: rgb(1, 113, 230) !important;
}

.container-button:hover {
  background-color: #dcedff !important;
  color: rgb(1, 113, 230) !important;
}
.container-button:disabled {
  color: #acaec4 !important;
  background: transparent !important;
}
.container-button-isEnabled {
  color:#acaec4 !important;
  background-color: transparent !important;
}

.container-button-cdo {
  width: 24px;
  height: 24px;
  border-radius: 8px;
  background: transparent;
  color: #fff !important;
}
.container-button-cdo:focus {
  background-color: transparent;
  color: #fff !important;
}

.container-button-cdo:hover {
  background-color: #dcedff !important;
  color: rgb(1, 113, 230) !important;
}
.container-button-cdo:disabled {
  color: #acaec4 !important;
  background: transparent !important;
}