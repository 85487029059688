.required-password {
    color: var(--Neutral-700, var(--700, #6b6f93));
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: 'Source Sans Pro';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}

.password-conditions {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}

.text {
    color: var(--Neutral-700, var(--700, #6b6f93));
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: 'Source Sans Pro';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}
