.container {
    display: flex;
    width: 370px;
    padding: 24px;
    flex-direction: column;
    align-items: center;
}

.title {
    color: #40435b;
    font-size: 18px;
    font-weight: 600;
    padding: 8px 0px;
}

.subtitle {
    color: #6b6f93;
    font-size: 14px;
    font-weight: 400;
}

.icon {
    position: absolute;
    right: 24px;
    top: 24px;
}
