.footer-header {
    font-size: 14px;
    font-weight: 400;
    color: #646464;
    display: flex;
    align-items: center;
    height: 30px;
}

.icon-style {
    width: 18px;
    height: 18px;
    color: #0171e6;
    margin-right: 8px;
}

.button-style {
    color: #0171e6;
    border: 1px solid #0171e6;
    width: 141px;
    height: 30px;
}

.button-style:hover {
    background-color: #dcedff;
    color: #0171e6;
}

.button-style:focus {
    background: #dcedff;
    color: #0171e6;
}

.button-style:active {
    border-radius: 8px;
    animation: pulse 0.7s;
}

.row-container {
    display: flex;
    align-items: center;
}

@keyframes pulse {
    0% {
        outline: 0px solid rgba(35, 130, 220, 1);
        outline-offset: 0.3rem;
    }
    50% {
        outline: 1px solid rgba(35, 130, 220, 1);
        outline-offset: 0.3rem;
    }
    100% {
        outline: 2px solid rgba(35, 130, 220, 1);
        outline-offset: 0.3rem;
    }
}

.button-style-download {
    color: #0171e6;
    border: 1px solid #0171e6;
    width: 80px;
    height: 30px;
}

.button-style-download-disabled {
    color: #acaec4;
    border: 1px solid #acaec4;
    width: 80px;
    height: 30px;
}
.disabled {
    pointer-events: none;
}
