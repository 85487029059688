.container-input {
    position: relative;
}

.autocomplete {
    border-radius: 0px !important;
}

.input-picker {
    width: 100%;
}

.input-picker div div input {
    padding: 8px 40px !important;
}

.input-picker div div div span {
    padding-left: 25px !important;
}

.icon-search {
    position: absolute;
    bottom: 8px;
    left: 13px;
    z-index: 6;

    display: flex;
    align-items: center;
}

.icon {
    color: #0171e6;
}

.loading-icon {
    color: #0171e6;
    font-size: 24px;
}

.container-loading {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* AUTOCOMPLETE STYLES */
.autocomplete-icon-search {
    position: absolute;
    top: 0;
    left: 13px;
    z-index: 6;
    display: flex;
    align-items: center;
}

.container-input > div {
    height: 40px;
}

.container-input > div > input {
    padding-left: 40px !important;
    padding-right: 86px !important;
    color: #40435b;
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    /* nuevos estilos rediseño */
    height: 40px;
    display: flex;
    align-items: center;
    overflow: hidden;
    border: 1px solid var(--Primary-50, #badbff) !important;
    background: var(--Neutral-white, #fff);

    text-overflow: ellipsis;
    white-space: nowrap;
}
.menu {
    padding: 16px 0px !important;
}

.border-custom > div > input {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.shake-horizontal {
	-webkit-animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
	        animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2024-10-22 16:7:24
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation shake-horizontal
 * ----------------------------------------
 */
 @-webkit-keyframes shake-horizontal {
    0%,
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    10%,
    30%,
    50%,
    70% {
      -webkit-transform: translateX(-10px);
              transform: translateX(-10px);
    }
    20%,
    40%,
    60% {
      -webkit-transform: translateX(10px);
              transform: translateX(10px);
    }
    80% {
      -webkit-transform: translateX(8px);
              transform: translateX(8px);
    }
    90% {
      -webkit-transform: translateX(-8px);
              transform: translateX(-8px);
    }
  }
  @keyframes shake-horizontal {
    0%,
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    10%,
    30%,
    50%,
    70% {
      -webkit-transform: translateX(-10px);
              transform: translateX(-10px);
    }
    20%,
    40%,
    60% {
      -webkit-transform: translateX(10px);
              transform: translateX(10px);
    }
    80% {
      -webkit-transform: translateX(8px);
              transform: translateX(8px);
    }
    90% {
      -webkit-transform: translateX(-8px);
              transform: translateX(-8px);
    }
  }
  