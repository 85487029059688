/* ---------- loader ---------- */
.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

/* ---------- dropdown name ---------- */
.placeholder {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}
