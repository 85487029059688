.item {
    width: 100%;
    padding: 8px 16px;
    gap: 24px;
    border-radius: 8px;
    height: 72px;
    background-color: #fff;
}

.item-content {
    width: 100%;
    height: 100%;
    gap: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.item-content > div:first-child {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}

.item-content > div:last-child {
    height: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    width: 131px;
}

.arrow-icon {
    cursor: pointer;
    transform: rotate(-90deg);
}

.circle > div {
    width: 32px !important;
    height: 32px !important;
}

.circle > div:last-child {
    display: flex !important;
    flex-direction: column !important;
    gap: 8px !important;
}

.circle > div:last-child p {
    margin: 0px !important;
    border-radius: 4px !important;
}

.circle > div:last-child > p:first-child {
    width: 141px !important;
    height: 16px !important;
}

.circle > div:last-child > p:last-child {
    width: 83px !important;
    height: 8px !important;
    background: #f5f6fa !important;
}

.button > div {
    width: 114px !important;
    height: 32px !important;
    border-radius: 8px !important;
}

.onOff > div {
    width: 32px !important;
    height: 32px !important;
    border-radius: 8px !important;
}

.onOff > div:last-child {
    display: flex !important;
    flex-direction: column !important;
    gap: 8px !important;
}

.onOff > div:last-child p {
    margin: 0px !important;
    border-radius: 4px !important;
}

.onOff > div:last-child > p:first-child {
    width: 141px !important;
    height: 16px !important;
}

.onOff > div:last-child > p:last-child {
    width: 83px !important;
    height: 8px !important;
    background: #f5f6fa !important;
}
