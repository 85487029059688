.result-header-wrapper {
    top: auto;
    background: #f0f5ff;
    z-index: 1;
    width: 267px;
}

.result-text {
    color: var(--Neutral-800, #40435b);

    font-family: SourceSansProBold;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    word-break: break-all;
    width: 150px;
}

.result-qty-label {
    color: var(--Neutral-700, var(--700, #6B6F93));


    font-family: SourceSansProRegular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}