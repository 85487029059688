.path-container {
  color: #fff;
  font-family: SourceSansProRegular;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.path-container-blur {
  color: #fff;
  font-family: SourceSansProRegular;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.wrapper-icon {
  color: #fff;
  display: flex;
  align-items: center;
  padding-left: 0px;
  cursor: pointer;
}
.wrapper-icon:hover,
.wrapper-icon:active,
.wrapper-icon:focus {
  color: #fff !important;
}

.container-breadcrumb {
  display: flex;
  align-items: center;
  justify-content: flex-start;

}

.text-with-padding {
  padding: 0 8px;
}
.wrapper-image {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 24px 0px 0px;
}