.container-content-mobile {
    border-radius: 16px;
    background: linear-gradient(339deg, #dcedff -5.61%, #fff 122.99%);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 342px;
    padding: 16px;
    gap: 16px;
    z-index: 2;
}

/* Estilos para pantallas pequeñas (menos de 768px) */
@media (min-width: 768px) {
    .container-content-mobile {
        display: none;
    }
}
