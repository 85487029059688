.dropdown-label {
    color: #0171e6;

    font-family: SourceSansProRegular;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}

.dropdown-icon {
    width: 16px;
    height: 16px;
}
.dropdown {
    cursor:pointer;
    z-index: 30;
}
.dropdown ul {
    width: 179px !important;
    padding: 16px !important;
}
.dropdown button {
    border-radius: 8px;
    background: #dcedff !important;
    display: flex !important;
    height: 32px;
    padding: 8px 12px;
    gap: 8px;
    margin: 0 16px;
}

.checkbox-group {
    display: flex;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    flex-direction: column;
}

.item-checkbox {
    color: #40435b;

    font-family: SourceSansProRegular;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;

    width: 100%;
}

.blur-tooltip-container {
    width: 109px;
    height: 32px;
    background: rgba(64, 67, 91, 0.7);
    z-index: 40;
    left: 321px;
    position: absolute;
    border-radius: 7px;
}
