.drawer-body {
    padding: 0px 16px !important;
    margin: 0px;
    height: calc(100% - 190px) !important;
    overflow-x: hidden;
}

.drawer-body::-webkit-scrollbar {
    width: 0;
    /* Ancho de la barra de desplazamiento */
}

.drawer-body::-webkit-scrollbar-thumb {
    background-color: transparent;
    /* Color de fondo transparente para el pulgar de la barra de desplazamiento */
    border: none;
    /* Sin borde en el pulgar de la barra de desplazamiento */
}

/* -------- NUEVO ESTILO DEL SCROLL EN REDISEÑO -------- */
.scrollbar-new-style::-webkit-scrollbar {
    width: 4px;
    /* Ancho de la barra de desplazamiento */
}

.scrollbar-new-style::-webkit-scrollbar-thumb {
    background: var(--Neutral-700, #6b6f93);
    /* Color de fondo transparente para el pulgar de la barra de desplazamiento */
    border-radius: 8px;
    /* Sin borde en el pulgar de la barra de desplazamiento */
}

.empty-cart {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    align-self: stretch;
}

.empty-cart-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
}

.empty-cart-text div:first-child p {
    color: var(--neutral-800, #40435b);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;

    /* Heading/Heading 2 Semibold */
    font-family: Source Sans Pro;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 120% */
}

.empty-cart-text div:last-child {
    padding: 0 35px;
}

.empty-cart-text div:last-child p {
    color: var(--neutral-800, #40435b);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    /* Body/Body 1/Regular */
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 125% */
}
