.container {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 327px;
    margin: 0 auto;
}
.warning-icon {
    width: 56px !important;
    height: 56px !important;
}
.title-warning {
    color: #40435b;
    font-family: SourceSansProBold;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}
.container-list-errors {
    padding: 24px;
    background-color: #fff;
    margin-top: 16px;
    border-radius: 8px;
}
.subtitle {
    text-align: left;
    color: #40435b;
    font-family: SourceSansProSemiBold;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}
.list-errors {
    color: #40435b;
    font-family: SourceSansProRegular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    width: 275px;
}
.list-errors > ul {
    padding-left: 20px;
}
.download-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.download-container svg {
    color: #0171e6;
}
.download-container p {
    margin: 0 8px;
    color: #0171e6;
    text-decoration: underline;
}
.container-buttons {
    display: flex;
    justify-content: center;
    margin-top: 24px;
    width: 100%;
}
.buttons-toolbar {
    width: 100%;
}
.button-action {
    background-color: #0171e6 !important;
    color: #fff !important;
    width: 100%;
    height: 44px !important;
    font-family: SourceSansProRegular;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}
.button-action:hover,
.button-action:action,
.button-action:focus {
    background-color: #0171e6 !important;
    color: #fff !important;
}

.button-action-secondary {
    background-color: #dcedff !important;
    color: #0171e6 !important;
    font-family: SourceSansProRegular;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    width: 100%;
    height: 44px !important;
}
.button-action-secondary:hover,
.button-action-secondary:active,
.button-action-secondary:focus {
    background-color: #dcedff !important;
    color: #0171e6 !important;
}

.text-messages {
    color: #6B6F93;
    font-family: SourceSansProRegular;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.subtitle-text-message {
    color: #6B6F93;
    font-family: SourceSansProRegular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-top: 8px;
}