.container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    margin-top: 24px;
}

.button-action{
    color:#333;
    background-color: #F5F6FA;
    font-family: SourceSansProRegular;
    font-size: 14px;
    font-weight: 600;
    width: 327px;
    height: 40px;
    border: 1px solid  #E0E1E9;
}

.button-action:active  .button-action:focus .button-action:hover {
    background-color: #fff !important;
}


