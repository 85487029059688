.chip-delivery {
    display: flex;
    padding: 0px 8px;
    height: 16px;
    align-items: center;
    border-radius: 8px;
    width: fit-content;
    text-transform: none;
    text-overflow: ellipsis;
    font-family: 'Source Sans Pro';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    white-space: nowrap;
}
.chip-delivery-green {
    background: #ebf9f3;
    overflow: hidden;
    color: #07ad4b;
}
.chip-delivery-alert {
    background: #ffebeb;
    overflow: hidden;
    color: #e22e2e;
}
.chip-delivery-blue {
    background: #ebf5ff;
    overflow: hidden;
    color: #0056b0;
}
.chip-delivery-disabled {
    background: #E0E1E9;
    overflow: hidden;
    color:#ACAEC4;
}