.container-notification {
    position: relative;
}

.container-notification button {
    background-color: transparent !important;
}
.container-badge-notification {
    position: absolute;
    top: 0;
    right: 11px;
}
.badge-notification {
    color: #e22e2e !important;
}
