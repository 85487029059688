/* styles.css */
.footer-container {
    background: #fff;
    height: 50px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.footer-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.footer-text {
    margin: 0 8px;
}