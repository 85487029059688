.container-popover {
    position: relative;
    width: 385px;
    text-align: left;
}
.container-header-popover {
    display: flex;
    align-items: flex-start;
    min-height: 85px;
    height: auto;
}
.container-button-close {
    position: absolute;
    top: 0px;
    right: 8px;
}

.button-close-icon {
    background-color: transparent !important;
    color: #40435b !important;
    padding: 0px !important;
}

.button-close-icon :active,
.button-close-icon :focus,
.button-close-icon :hover {
    background-color: transparent !important;
    color: #40435b !important;
}
.container-image {
    margin-right: 8px;

    min-height: 85px;
}

.container-name-description {
    width: 100%;
}

.image-icon-product {
    border-radius: 8px;
    border: 0.5px solid #e0e1e9 !important;
    background: #fff !important;
    object-fit: cover;
    width: 120px;
    height: auto;
    aspect-ratio: auto;
}
.category-text {
    color: #6b6f93;
    font-family: SourceSansProRegular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-transform: capitalize;

    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}
.medicament-text {
    color: #40435b;

    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;

    width: 90%;

    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.medicament-presentation {
    color: #40435B;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.container-body {
    margin-top: 16px;
    border-radius: 8px;
    background: #f5f6fa;
    padding: 8px;
}
.title-body-detail {
    color:  #6B6F93;
   
    /* Body/Body 2/Regular */
    font-family: SourceSansProRegular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}
.label-body-detail-tag {
    color: #40435b;

    font-family: SourceSansProSemiBold;

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    
    border-radius: 4px !important;
    border: 0.5px solid #ffc700;
    background: #ffea9f !important;
    padding: 0 4px !important;
}

.label-body-detail {
    color: #40435b;

    font-family: SourceSansProSemiBold;

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}
.label-body-detail-lab {
    color: #6b6f93;

    font-family: SourceSansProSemiBold;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}
.divisor-line {
    margin-top: 4px;
    margin-bottom: 4px;
    color: #acaec4;
}
.plus {
    font-size: 15px;
}
.label-body-detail-category {
    color: #40435b;

    font-family: SourceSansProSemiBold;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}
