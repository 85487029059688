/* ----------------- container-panel-uploader DEPRECATED ----------------- */
.container-panel-uploader {
    border-radius: 3px;
    position: absolute;
    z-index: 8;
    left: 122px;
    right: 50px;
    height: 81vh;
    box-shadow: 0px 4px 5px -1px rgba(64, 67, 91, 0.1), 0px 2px 4px -1px rgba(64, 67, 91, 0.06);
}

.container-panel-uploader > div[class='rs-panel'] {
    width: 100%;
    height: 100%;
    padding: 0px;
}

.container-panel-uploader > div[class='rs-panel'] > div {
    width: 100%;
    height: 100%;
    padding: 0px;
}

.container-panel-uploader
    > div[class='rs-panel']
    > div
    > div[class*='rs-uploader rs-uploader-text rs-uploader-draggable'] {
    height: 100% !important;
}

.container-panel-uploader
    > div[class='rs-panel']
    > div
    div[class*='rs-uploader-trigger rs-uploader-trigger-customize'] {
    height: 100% !important;
}

.container-panel-uploader
    > div[class='rs-panel']
    > div
    div[class*='rs-uploader-trigger rs-uploader-trigger-disabled rs-uploader-trigger-customize'] {
    height: 100% !important;
}

.icon-cloud {
    color: #0171e6;
    width: 56px !important;
    height: 56px !important;
    margin-bottom: 16px;
}

.icon-cloud-error {
    color: #e22e2e;
    width: 56px !important;
    height: 56px !important;
    margin-bottom: 16px;
}

.cancel-button {
    position: absolute;
    top: 24px;
    right: 52px;
}

.icon-button-close {
    background-color: transparent !important;
    color: #222;
}
.icon-button-close:hover,
.icon-button-close:focus,
.icon-button-close:active {
    background-color: transparent !important;
    color: #222;
}

.icon-button-close:disabled {
    color: #acacac;
}

/* ---------------- NEW STYLES WITH FF ------------------ */
.wrapper {
    border-radius: 3px;
    position: absolute;
    z-index: 8;
    left: 72px;
    right: 0;
    bottom: 0;
    background-color: rgba(240, 245, 255, 0.8);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 16px;
}

.container-panel-uploader-v2 {
    position: relative;
    height: 100%;
    box-shadow: 0px 4px 5px -1px rgba(64, 67, 91, 0.1), 0px 2px 4px -1px rgba(64, 67, 91, 0.06);
}

.container-panel-uploader-v2 > div[class='rs-panel'] {
    width: 100%;
    height: 100%;
    padding: 0px;
}

.container-panel-uploader-v2 > div[class='rs-panel'] > div {
    width: 100%;
    height: 100%;
    padding: 0px;
}

.container-panel-uploader-v2
    > div[class='rs-panel']
    > div
    > div[class*='rs-uploader rs-uploader-text rs-uploader-draggable'] {
    height: 100% !important;
}

.container-panel-uploader-v2
    > div[class='rs-panel']
    > div
    div[class*='rs-uploader-trigger rs-uploader-trigger-customize'] {
    height: 100% !important;
}

.container-panel-uploader-v2
    > div[class='rs-panel']
    > div
    div[class*='rs-uploader-trigger rs-uploader-trigger-disabled rs-uploader-trigger-customize'] {
    height: 100% !important;
}

.container-cart {
    height: 100%;
}

.container-cart > div[class*='DrawerCart_drawer-wrapper__'] {
    height: 79% !important;
}

.container-cart > div[class*='DrawerCart_is-expanded__'] {
    width: 38vw !important;
}
