.item {
    width: 100%;
    padding: 8px 16px;
    gap: 24px;
    border-radius: 8px;
    height: 72px;
    background-color: #fff;
}

.item:hover {
    box-shadow: 0px 2px 4px -1px #40435B0F;
    box-shadow: 0px 4px 5px -1px #40435B1A;
}

.item-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.item-content > div:first-child {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    flex-grow: 1; /* El primer hijo ocupará el espacio restante */
}

.item-content > div:last-child {
    height: 32px;
    display: flex;
    align-items: center;
    gap: 16px;
    width: 131px;
}

/* esta clase aplica solo cuando no se puede editar el item */
.item-content-large {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.item-content-large > div:first-child {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    flex-grow: 1; /* El primer hijo ocupará el espacio restante */
}

.hovereable {
    cursor: pointer;
}

.slide-out-blurred-right {
    -webkit-animation: slide-out-blurred-right 0.7s cubic-bezier(0.755, 0.05, 0.855, 0.06) both;
    animation: slide-out-blurred-right 0.7s cubic-bezier(0.755, 0.05, 0.855, 0.06) both;
}

@-webkit-keyframes slide-out-blurred-right {
    0% {
        -webkit-transform: translateX(0) scaleY(1) scaleX(1);
        transform: translateX(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(1000px) scaleX(2) scaleY(0.2);
        transform: translateX(1000px) scaleX(2) scaleY(0.2);
        -webkit-transform-origin: 0% 50%;
        transform-origin: 0% 50%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
    }
}
@keyframes slide-out-blurred-right {
    0% {
        -webkit-transform: translateX(0) scaleY(1) scaleX(1);
        transform: translateX(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(1000px) scaleX(2) scaleY(0.2);
        transform: translateX(1000px) scaleX(2) scaleY(0.2);
        -webkit-transform-origin: 0% 50%;
        transform-origin: 0% 50%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
    }
}
