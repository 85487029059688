.container {
    border-radius: 8px;
    background: var(--Primary-10, #ebf5ff);
    padding: 8px;
}
.uploader-format {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
}
.uploader-format div {
    width: 100%;
}
.uploader-format > div > div,
.rs-uploader-trigger-btn:hover {
    cursor: pointer;
    border: none !important;
}

.label-uploader {
    color: var(--Neutral-800, #40435b);
    font-feature-settings: 'liga' off, 'clig' off;

    /* Label/Label 2 */
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 128.571% */
}
.container-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.sub-label-uploader {
    color: var(--Neutral-800, #40435b);
    font-feature-settings: 'liga' off, 'clig' off;

    /* Caption/Caption 2 */
    font-family: 'Source Sans Pro';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
}

.error-format-uploader-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    margin: 8px 0px 0px 0px;
}

.error-format-uploader {
    font-family: Source Sans Pro;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    text-underline-position: from-font;
    color: #e22e2e;
}
