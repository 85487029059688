.minimumPurchaseDetail {
    display: flex;
    flex-direction: column;
    align-items: end;
}

.minimumPurchaseDetailLabel {
    align-self: stretch;
    color: var(--Neutral-700, #6b6f93);
    text-align: right;
    font-feature-settings: 'clig' off, 'liga' off;

    /* Caption/Caption 2 */
    font-family: 'Source Sans Pro';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
}

.minimumPurchaseDetailValue {
    color: var(--Semantic-Error-600, #e22e2e);
    text-align: right;
    font-feature-settings: 'clig' off, 'liga' off;

    /* Button/Button 3 */
    font-family: 'Source Sans Pro';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 133.333% */
}
