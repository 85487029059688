.container-search-orders {
  height: 70vh;
  display: flex;
  justify-content: center;
  position: relative;
}
.image-style {
  width: 200px;
}
.container-banner {
  position: absolute;
  top: 20%;
}
