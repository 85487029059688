.text-messages {
  color: #40435b;
  /* font-family: SourceSansProBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-top: 16px; */
  font-family: SourceSansProRegular;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.link-text-messages {
  color: #0171e6;
  font-family: SourceSansProRegular;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.container-text-message {
  text-align: center;
}
.icon-or-image-cotainer {
  display: flex;
  justify-content: center;
}
.image {
  width: 100%;
  fill: #0171e6;
  -webkit-animation: AnimationName 5s ease infinite;
  -moz-animation: AnimationName 5s ease infinite;
  animation: AnimationName 5s ease infinite;
}
.container-img-icon {
  position: relative;
}
.container-icon {
  position: absolute;
  bottom: 58px;
  left: 64px;
  color: #fff;
}
.subtitle-text-message {
  color: #40435b;
  font-family: SourceSansProRegular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: 8px;
}
.button-upload-file {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}
.button-upload {
  color: #0171e6;
  background-color: #dcedff;
  font-family: SourceSansProRegular;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  padding: 10px 24px;
}
.button-upload:hover,
.button-upload:active,
.button-upload:focus {
  color: #0171e6;
  background-color: #dcedff;
}

@-webkit-keyframes AnimationName {
  0% {
    fill: #0171e6;
  }
  50% {
    fill: #dcedff;
  }
  100% {
    fill: #0171e6;
  }
}
@-moz-keyframes AnimationName {
  0% {
    fill: #0171e6;
  }
  50% {
    fill: #fff;
  }
  100% {
    fill: #0171e6;
  }
}
@keyframes AnimationName {
  0% {
    fill: #0171e6;
  }
  50% {
    fill: #dcedff;
  }
  100% {
    fill: #0171e6;
  }
}
