.close-icon-button {
    position: absolute;
    top: 2px;
    right: 0px;
    z-index: 2100;

    width: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 16px;
    height: 40px;
}
.close-icon-button-home {
   
    z-index: 2100;

    width: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 16px;
    height: 40px;
}
.icon-button-index {
    background-color: transparent !important;
}
.icon-button-index:hover,
.icon-button-index:active,
.icon-button-index:focus {
    background-color: transparent !important;
}
