.wrapper-card {
  background-color: #fff;
  border-radius: 8px;
  width: 660px;
  transition: box-shadow 0.3s ease;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  margin-top: 32px;
  border-top-width: 4px;
  border-top-style: solid;
  margin-bottom: 32px;
}