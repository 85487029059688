.drawer-wrapper {
    width: 25vw !important;
    height: 74vh !important;
    /* top: 87.5px !important;
    right: 69px !important; */
    border-radius: 8px !important;
    padding-top: 16px !important;
    background-color: #fff !important;
    box-shadow: 0px 2px 4px -1px rgba(64, 67, 91, 0.06), 0px 4px 5px -1px rgba(64, 67, 91, 0.10) !important;
    display: none;
    z-index: 2000;
}

.is-expanded {
    width: 44vw !important;
}
.is-expanded-results {
    width: 34vw !important;
}

.drawer-fade-in {
    /* ANIMATION */
    animation-duration: .3s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    animation-name: slideInRight;
    display: block;
}

.drawer-fade-out {
    /* ANIMATION */
    animation-duration: .3s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    animation-name: slideOutRight;
}

@keyframes slideInRight {
    0% {
        opacity: 0;
        transform: translate3d(100%, 0, 0);
    }

    100% {
        opacity: 1;
        transform: none;
    }
}

@keyframes slideOutRight {
    0% {
        opacity: 1;
        transform: none;
    }

    100% {
        opacity: 0;
        transform: translate3d(100%, 0, 0);
        display: none;
        visibility: hidden;
    }
}


.backdrop-step3 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(64, 67, 91, 0.9);
    pointer-events: none;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 0%, 0% 0%);
}

/* Ajusta este estilo dinámicamente con JS para dejar visible el carrito */
.backdrop-step3.visible {
    clip-path: polygon(
        0% 0%, 100% 0%, 100% 100%, 0% 100%, /* Pantalla completa */
        0% 0%, 0% 0%, /* Puntos para crear el "hueco" */
        70% 20%, 90% 20%, 90% 80%, 70% 80%, /* Ajusta estos valores al tamaño del carrito */
        0% 0%, 0% 0%
    );
}