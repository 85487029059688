.cell {
    min-width: 306px;
    height: 82px;
    background: var(--Neutral-White, #fff);
    box-shadow: 0px 1px 3px 0px rgba(64, 67, 91, 0.1), 0px 1px 2px 0px rgba(64, 67, 91, 0.06);
    display: grid;
    /* grid-template-columns: auto 110px; */
    padding: 8px;
    align-items: center;
    gap: 4px;
    /* add styles */
    display: grid;
    grid-column: 1 / 2;
}

/* RECOMMENDED */
.recommended-product {
    color: var(--Secondary-500, #2c4560);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Caption/Caption 2 */
    font-family: 'Source Sans Pro';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    height: 16px;
}

.barcode {
    color: var(--Neutral-700, #6b6f93);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Body/Body 2/Regular */
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.cell-error {
    background: var(--Semantic-Error-50, #ffebeb);
}

/* PRODUCT */
.product {
    grid-column: 1 / 2;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* gap: 8px; */
}

/* DESCRIPTION */
.description {
    overflow: hidden;
    display: -webkit-box;
    /* -webkit-line-clamp: 2; */
    -webkit-box-orient: vertical;
    text-align: left;
}

.description > p {
    color: var(--Neutral-800, #40435b);
    font-feature-settings: 'clig' off, 'liga' off;

    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    margin: 0;
}

/* COUNTER */
.counter {
    grid-column: 2 / 3;
}

.text-label:hover {
    cursor: pointer;
    text-decoration: underline;
}

/* PLACEHOLDERS */
.placeholder-idx > div > p {
    width: 83px !important;
}

.placeholder-description > div > p {
    margin-top: 4px !important;
}

.placeholder-description > div > p:first-child {
    width: 177px !important;
}

.placeholder-description > div > p:last-child {
    width: 147px !important;
}

.trash-icon {
    display: none;
}

.trash-icon > img {
    cursor: pointer;
    width: 16px;
    height: 16px;
}

/* GENERICS */
.box-generic {
    display: flex;
    align-items: center;
    gap: 4;
    cursor: pointer;
}

.box-generic > p {
    color: var(--Discount-Discount, #ff6300);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Caption/Caption 2 */
    font-family: 'Source Sans Pro';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
}

.rotate-arrow {
    transform: rotate(180deg);
}

.cell-generics {
    min-width: 306px;
    height: 82px;
    display: grid;
    /* grid-template-columns: auto 110px; */
    border-left: 2.5px solid #ff6300;
    display: grid;
    grid-column: 1 / 2;
    align-items: center;
    gap: 4px;
    padding: 8px;
    border-left: 2.5px solid var(--Discount-Discount, #ff6300);
    border-bottom: 0.5px solid var(--Secondary-300, #bed0e5);
    border-right: 0.5px solid var(--Secondary-300, #bed0e5);

    border-top-left-radius: 0px !important;
    border-bottom: 0.5px solid var(--Secondary-300, #bed0e5);
}
.container-button {
    grid-column: 2 / 3;
}

.button {
    width: 85px;
    height: 32px;

    color: var(--Neutral-White, var(--Neutral-white, #fff));

    /* Button/Button 2 */
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 128.571% */

    border-radius: 8px;
    background: var(--Primary-600, #0171e6);
}

.button:hover,
.button:active,
.button:focus {
    background: var(--Primary-600, #0171e6);
    color: var(--Neutral-White, var(--Neutral-white, #fff));
}
/* END GENERICS */

.button-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-left: 10px;
}

.split-button,
.split-button:hover,
.split-button:active,
.split-button:focus {
    width: 90px;
    height: 20px;
    padding: 0px !important;
    box-shadow: none;
}

.split-button-activated,
.split-button-activated:active,
.split-button-activated:hover,
.split-button-activated:focus {
    color: var(--Semantic-Success-600, #07ad4b) !important;
    border-radius: 4px !important;
    border: 1px solid var(--Semantic-Success-600, #07ad4b) !important;
    background: var(--Semantic-Success-100, #ddf6e2) !important;
}

.split-button-default,
.split-button-default:active,
.split-button-default:hover,
.split-button-default:focus {
    color: var(--Neutral-700, #6b6f93) !important;
    border-radius: 4px !important;
    border: 1px solid var(--Neutral-700, #6b6f93) !important;
    background: var(--Neutral-50, #f5f6fa) !important;
}

.tooltipSplit {
    font-size: 14px;
    padding: 4px 12px;
    text-align: left;
    min-width: 200px;
    margin-top: 8px;
}

.product-image {
    width: 58px;
    height: 58px;
    padding: 4px;
    gap: 8px;
    border-radius: 8px;
    background: #ffffff;
    border: 0.5px solid #e0e1e9;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wrapper-tag {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2px;
    height: 20px;
    width: max-content;
    padding: 1px 4px;
    border-radius: 4px;
    background-color: #FFFFFF;
}

.tag {
    font-family: Source Sans Pro;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    text-underline-position: from-font;
}

/* background when is bioequivalent */
.bioequivalent {
    /* border-radius: 0px 0px 0px 8px; */ /*si se descomenta genera un borde inferior izquierdo como si estuvieran "separadas" las rows genericas por el momento no se definio*/
    background: linear-gradient(93deg, #bdbdbd 0%, #f5f6fa 100%);
}
