.select-picker {
  width: 231px;
  height: 40px;
  margin-right: 16px;
}
.wrapper-container {
  position: relative;
}

.placeholder-text {
  padding: 0 20px !important;
  font-family: SourceSansProRegular;
  padding-right: 15px !important;
}
.select-input-list {
  height: 40px;
}

.container-icon {
  position: absolute;
  left: 10px;
  top: 30%;
  z-index: 99;
}
.footer-container {
  padding: 10px 2px;
  border-top: 1px solid #e5e5e5;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-button {
  float: right;
  margin-top: 2;
  margin-right: 8px;
}

.button-clear {
  color: #1dcad3;
  background-color: transparent;
}
.button-clear:hover,
.button-clear:focus,
.button-clear:active {
  color: #1dcad3 !important;
  background-color: transparent !important;
}
.select-picker
  div[role="combobox"]
  div
  div:first-child
  input
  span:first-child
  span {
  padding-left: 20px !important;
}
.row-container {
  display: flex;
  flex-direction: column;
}

.name-withReference {
  color: #6b6f93;
  font-size: 12px;
}

.referenceName {
  color: #40435b;
  font-weight: 600;
  font-size: 14px;
}

.name {
  color: #40435b;
  font-weight: 600;
  font-size: 14px;
}
