.title-container {
    display: flex;
    justify-content: center;
}

.title-text {
    color: #40435B;
    font-family: "Source Sans Pro";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.button-container {
    display: flex;
    justify-content: center;
    margin: 16px 0px;
}

.button-connection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 62px;
    width: 100%;
    padding: 10px 24px;
    border-radius: 8px;
    border: 1px solid #0171E6;
    background: transparent;
}

.button-text {
    color: #0171E6;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-transform: capitalize;
}

.button-url {
    color: #6B6F93;
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

