.content {
    /* overflow-x: hidden !important; */
    height: 70vh;
    background-color: #f0f5ff !important;
    padding: 0 48px;
    /* padding-bottom: 26px; */
    overflow-x: hidden;
    -ms-overflow-style: none;
    /* scrollbar-width: thin; */
    overflow-y: auto;
}
.content-analytics {
    height: 70vh;
    background-color: #f0f5ff !important;
    /* padding-bottom: 26px; */
    overflow-x: hidden;
    -ms-overflow-style: none;
    /* scrollbar-width: thin; */
    overflow-y: auto;
}
.content-withoutScroll {
    height: 70vh;
    background-color: #f0f5ff !important;
    padding: 0 48px;
    /* padding-bottom: 26px; */
    overflow-x: hidden;
    -ms-overflow-style: none;
    /* scrollbar-width: thin; */
    overflow-y: hidden !important;
}
.content-withOutPadding {
    height: 70vh;
    background-color: #f0f5ff !important;
    /* padding-bottom: 26px; */
    -ms-overflow-style: none;
    /* scrollbar-width: thin; */
    overflow-x: hidden;
    overflow-y: auto !important;
}
.content-withOutPadding::-webkit-scrollbar {
    width: 8px;
}
.content-withOutPadding::-webkit-scrollbar:hover {
    width: 11px;
}
.content-withOutPadding::-webkit-scrollbar-thumb {
    background-color: #aaa; /* Color del deslizador */
    border-radius: 4px; /* Redondear las puntas del deslizador */
}
.content-withOutPadding::-webkit-scrollbar-track {
    background-color: #d3d3d3; /* Color de fondo del área del deslizador */
}
.content::-webkit-scrollbar {
    width: 8px;
}
.content::-webkit-scrollbar:hover {
    width: 11px;
}
.content::-webkit-scrollbar-thumb {
    background-color: #aaa; /* Color del deslizador */
    border-radius: 4px; /* Redondear las puntas del deslizador */
}
.content::-webkit-scrollbar-thumb:hover {
    background-color: #999; /* Color del deslizador */
    border-radius: 4px;
}

.content::-webkit-scrollbar-track {
    background-color: #d3d3d3; /* Color de fondo del área del deslizador */
}

.content-background-remove {
    background-color: #fff !important;
    overflow: hidden !important;
    overflow-y: auto !important;
    height: 70vh;
    padding: 0 48px;
}

.content-background-v2 {
    background-color: #f0f5ff !important;
    overflow: hidden !important;
    overflow-y: auto !important;
    height: 70vh;
    padding: 0 48px;
}

.content-background-remove::-webkit-scrollbar {
    width: 8px;
}
.content-background-remove::-webkit-scrollbar:hover {
    width: 11px;
}
.content-background-remove::-webkit-scrollbar-thumb {
    background-color: #aaa; /* Color del deslizador */
    border-radius: 4px; /* Redondear las puntas del deslizador */
}
.content-background-remove::-webkit-scrollbar-thumb:hover {
    background-color: #999; /* Color del deslizador */
    border-radius: 4px;
}

.content-background-remove::-webkit-scrollbar-track {
    background-color: #d3d3d3; /* Color de fondo del área del deslizador */
}
.wrapper-card-container-fullblur {
    height: calc(100% - 56px);
    width: 100%;
    background: rgba(64, 67, 91, 0.9);
    position: absolute;
    bottom: 0;
    z-index: 150;
}

.content-progressBarPrice {
    display: inline-flex;
    padding: 60px 243px 32px 243px;
    flex-direction: column;
    /* justify-content: flex-end; */
    justify-content: space-between;
    align-items: center;
    height: 100vh !important;
    overflow: hidden !important;
    /* gap: 95.5px; */
    background: var(--Secondary-25, #f0f5ff);
}

.with-errors {
    overflow-y: auto !important;
}
