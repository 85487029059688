/* STYLES CONTAINER */
.cell-price-container {
    height: 82px;
}

.cell-price-container > div {
    display: grid;
    grid-template-rows: 62px 20px;
    grid-template-columns: 1fr;
    height: 80px; /*1px border top and 1px border bottom*/
}

/* -------------------- TOP SECTION-------------------- */
.top {
    grid-row: 1;
    grid-column: 1;
    padding: 0px 0px 0px 8px;
    display: grid;
    grid-template-rows: 18px 44px;
    grid-template-columns: 1fr;
}

/* ----------- AVATAR SECTION ------------------ */
.avatar-section {
    grid-row: 1;
    color: #fff;
    display: grid;
    grid-template-columns: auto 1fr; /* Dos columnas de igual tamaño */
    grid-auto-flow: column; /* Los elementos se apilan en columnas */
    gap: 10px; /* Espacio entre elementos */
}

.avatar-section > div:first-child {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
}

.avatar-section > div:first-child > p {
    border-radius: 0px 0px 4px 4px;
    padding: 0px 4px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: var(--Neutral-800, #40435b);
    font-feature-settings: 'clig' off, 'liga' off;
    text-overflow: ellipsis;
    font-family: 'Source Sans Pro';
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    background: var(--Neutral-White, #fff);
}

.avatar-section > div:last-child {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    display: flex;
    flex-direction: row;
}

.avatar-section > div:last-child div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 18px;
    border-radius: 0px 0px 4px 4px;
    background: #fff;
}

.avatar-section > div:last-child div > img {
    width: 16px;
    height: 16px;
}

.avatar-section > div:last-child div > svg {
    width: 16px;
    height: 16px;
}

.avatar-section > div:last-child > .agremeent {
    border-radius: 0px 0px 0px 4px;
    border-top: 0.5px solid var(--Secondary-300, #bed0e5);
    background: var(--Semantic-Success-600, #07ad4b);
    padding: 4px;
    width: max-content;
}

.avatar-section > div:last-child > .agremeent p {
    color: var(--Neutral-White, #fff);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Source Sans Pro';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
}

/* TOP SECTIO WITHOUT PADDING */
.top-without-padding {
    grid-row: 1;
    grid-column: 1;
    display: grid;
    grid-template-rows: 18px 44px;
    grid-template-columns: 1fr;
    padding: 0px;
}

/* CDO CELL CHIP BEST PRICE */
.best-price-section {
    text-align: center;
    grid-row: 1;
    color: #fff;
    background: var(--Semantic-Success-600, #0171e6);
    color: #fff;
}

/* ----------------------- PRICE SECTION RADDIO + PRICE ---------------- */
.price-section {
    grid-row: 2;
    padding: 14px 8px 0px 0px;
    display: grid;
    grid-template-rows: 18px 8px;
    grid-template-columns: 16px 1fr;
}

/* RADIO CHECK */
.radio {
    grid-row: 1;
    grid-column: 1 / 2;
    max-height: 16px;
}

/* PRICE TOTAL */
.price {
    grid-row: 1;
    grid-column: 2 / 3;
    color: var(--Neutral-700, var(--Neutral-300, #6b6f93));
    text-align: right;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    display: flex;
    justify-content: flex-end;
}

.price-color-checked {
    color: #40435B;
    font-size: 16px;
}

.price > p {
    max-height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.price:hover {
    color: var(--Neutral-800, #40435b);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
}

/* PRICE DISCOUNT */
.price-discount {
    margin: 0;
    grid-row: 2;
    grid-column: 2 / 3;
}

.price-discount > p {
    margin-top: -4px;
    max-height: 8px;
    color: var(--Neutral-700, var(--Neutral-300, #6b6f93));
    text-align: right;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Source Sans Pro';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    text-decoration-line: line-through;
}

/* -------------- CHIP BOTTOM ----------------- */
.bottom {
    grid-row: 2;
    grid-column: 1;
}

/* CHIP CONTAINER */
.chip {
    padding: 0px 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

/* CHIP BY COLORS */

/* CHIP ORANGE */
.chip-background-orange {
    background-color: #ff6300;
    color: #fff;
}

.chip-background-orange > p {
    overflow: hidden;
    color: var(--Neutral-White, var(--Neutral-white, #fff));
    text-align: right;
    font-feature-settings: 'clig' off, 'liga' off;
    text-overflow: ellipsis;
    font-family: 'Source Sans Pro';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
}

/* CHIP GREEN */
.chip-background-green {
    background: var(--Semantic-Success-600, #07ad4b);
    color: #fff;
}

.chip-background-green > p {
    color: #fff;
    overflow: hidden;
    color: var(--Neutral-White, var(--Neutral-white, #fff));
    text-align: right;
    font-feature-settings: 'clig' off, 'liga' off;
    text-overflow: ellipsis;
    font-family: 'Source Sans Pro';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
}

/* CHIP BLUE */
.chip-background-blue {
    background: var(--Semantic-Success-600, #0171e6);
    color: #fff;
}

.chip-background-blue > p {
    color: #fff;
    overflow: hidden;
    color: var(--Neutral-White, var(--Neutral-white, #fff));
    text-align: right;
    font-feature-settings: 'clig' off, 'liga' off;
    text-overflow: ellipsis;
    font-family: 'Source Sans Pro';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
}

/* CHIP GREEN SPLIT */
.chip-background-strong-green {
    background: var(--Semantic-Success-800, #146D3B);
    color: #fff;
}

.chip-background-strong-green > p {
    color: #fff;
    overflow: hidden;
    color: var(--Neutral-White, var(--Neutral-white, #fff));
    text-align: right;
    font-feature-settings: 'clig' off, 'liga' off;
    text-overflow: ellipsis;
    font-family: 'Source Sans Pro';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
}

/* DISABLED */
.chip-background-disabled {
    background: var(--Semantic-Success-600, #E0E1E9);
    color: #fff;
}

.chip-background-disabled > p {
    color: #fff;
    overflow: hidden;
    color: var(--Neutral-White, var(--Neutral-white, #ACAEC4));
    text-align: right;
    font-feature-settings: 'clig' off, 'liga' off;
    text-overflow: ellipsis;
    font-family: 'Source Sans Pro';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
}

/* --------------- PLACEHOLDER -------------- */
.placeholder-price {
    width: 62px;
}

.placeholder-price > div > p {
    width: 62px !important;
    margin: 0 !important;
}

/* WITHOUT BORDER  */
.without-border {
    border-left: none;
    border-right: none;
}

.placeholder-radio {
    display: inline !important;
}

.placeholder-radio > div {
    width: 16px;
    height: 16px;
}

.placeholder-percent {
    width: 100%;
}

.placeholder-percent > div > p {
    width: 100% !important;
    margin: 0 !important;
}

/* WITHOUT BORDER  */
.without-border {
    border-left: none;
    border-right: none;
}

.without-stock {
    color: var(--Neutral-700, #6b6f93);
    text-align: right;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

/* drugManufacturerDisabled */
.drugManufacturerDisabled {
    color: #acacac !important; /*important para evitar otros colores*/
}

/* TEXT WITH ERROR (COLOR RED) */
.text-with-error {
    color: var(--Semantic-Error-600, #e22e2e);
    text-align: right;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 112.5% */
}

/* INPUT CELL */
.checkbox-round {
    width: 16px;
    height: 16px;
    border-radius: 9999px;
    border: 1px solid var(--Neutral-100, #e0e1e9);
    background: var(--Neutral-white, #fff);
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
}

.checkbox-round:checked {
    border-radius: 9999px;
    border: 5px solid var(--Semantic-Success-600, #07ad4b);
    background: var(--Neutral-White, #fff);
}

.checkbox-round:disabled {
    border-radius: 9999px;
    border: 0.5px solid var(--Neutral-600, #acaec4);
    background: var(--Neutral-100, #e0e1e9);
}

.checkbox-round-error {
    border: 0.5px solid var(--Neutral-600, #e22e2e);
}

.line {
    color: var(--Neutral-700, #6b6f93);
    text-align: right;
    font-feature-settings: 'clig' off, 'liga' off;
    width: 5px;
    /* Body/Body 2/Regular */
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
}
