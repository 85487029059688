.container {
    margin-right: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
}

.alert-icon {
    display: flex;
    align-items: center;
    width: 16px;
}

.error-text {
    display: flex;
    align-items: center;
    font-size: 14px;
}

.root {
    background-color: #0b2033;
    font-size: 16px;
    font-weight: 400;
    z-index: 2000;
}
