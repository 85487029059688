.footer-item{
  display: flex;
  justify-content: center;
  align-items: center;

  height: 56px;
  padding: 16px;
}
.footer-title{
  color:#0171E6;
  font-family: SourceSansProSemiBold;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 22px;

padding: 0 8px;
}
.icon-footer{
  color:#0F7FF4;
}