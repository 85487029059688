.container-out-of-orders {
  background-color: #f5f6fa;
  height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.container-banner{
  position: absolute;
}
