.loader-spin div span:after {
    border-top-color: #0B2033 !important;
}

.container {
    display: flex;
    justify-content: center;
    margin-top: 24px;
}

.container-panel {
    width: 600px;


    border-radius: 8px;
    background: var(--Neutral-white, #FFF);

    /* xs */
    box-shadow: 0px 1px 3px 0px rgba(64, 67, 91, 0.10), 0px 1px 2px 0px rgba(64, 67, 91, 0.06);
}

.container-header {
    display: flex;
    justify-content: space-between;
}

.container-header span {
    color: var(--Neutral-700, var(--700, #6B6F93));
    text-align: right;
    font-feature-settings: 'clig'off, 'liga'off;

    /* Body/Body 2/Regular */
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
}

.container-header p {
    color: var(--Neutral-800, #40435B);
    text-align: center;
    font-feature-settings: 'clig'off, 'liga'off;

    /* Heading/Heading 2 Semibold */
    font-family: "Source Sans Pro";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 120% */
}

.active-date {
    text-transform: capitalize;
    color: var(--Neutral-700, #6B6F93);
    text-align: right;
    font-feature-settings: 'clig'off, 'liga'off;

    /* Body/Body 2/Regular */
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
}