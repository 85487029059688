.container {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    background: var(--Neutral-White, #fff);
    box-shadow: 24px 60px 100px -115px #0f7ff4, 0px -1px 1px 0px rgba(15, 127, 244, 0.15) inset,
        0px 150px 150px 0px rgba(220, 237, 255, 0.3);
    /* padding: 48px; */
    gap: 64px;
    overflow: hidden;
    transition: box-shadow 0.7s ease-in-out;
}

.container div:first-child {
    min-height: 56px;
}

.completed {
    box-shadow: 24px 60px 100px -115px #07ad4b, 0px -1px 1px 0px rgba(7, 173, 75, 0.15) inset,
        0px 150px 150px 0px rgba(235, 249, 243, 0.3);
}

.failed {
    box-shadow: 24px 60px 100px -115px #e22e2e, 0px -1px 1px 0px rgba(226, 46, 46, 0.15) inset,
        0px 150px 150px 0px rgba(255, 235, 235, 0.3);
}
