.wrapper-card {
  background-color: #fff;
  border-radius: 8px;
  width: 660px;
  transition: box-shadow 0.3s ease;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  position: relative;
}

.header {
  padding: 24px;
}

.firts-row {
  padding: 24px;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  color: #333333;
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  border-color: #D1D1D1;
}

.col{
  display:flex;
  align-items:center;
}

.icon{
  color:#0171E6;
  height: 16px;
  width: 16px;
  margin-right: 8px;
}