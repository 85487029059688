.container {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    background: var(--Neutral-White, #fff);
    height: 100vh;
    width: 100%;
}

/* contactanos */
.contact-us {
    display: none;
}

/* Estilos para pantallas pequeñas (menos de 768px) */
@media (max-width: 768px) {
    .container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background: linear-gradient(349deg, #FFF -31.42%, #DCEDFF 105.73%);
        /* overflow: auto; */
        height: 100vh;
        padding: 105px 24px 131.842px 24px;
    }
    .activateUser {
        padding-top: 60px;
    }
}
