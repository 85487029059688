.payment-detail-wrapper {
    display: flex;
}
.payment-detail-container {
    height: 500px;
    width: 430px;
    background-color: #ece9e9;
    border-radius: 10px;
}
.payment-detail-container div[aria-expanded='false'] {
    background-color: #fff;
    height: 100px;
}
.payment-detail-container div[role='region'] {
    height: 430px;
    padding-top: 20px;
}
.payment-detail-title {
    color: #40435b;
    font-family: 'Source Sans Pro';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-top: 8px;
}
.payment-label-openpay {
    margin: 8px;
}
.payment-processBy {
    color: #228b22 !important;
    display: flex !important;
    align-items: center !important;
}
.payment-detail-line {
    border-bottom: 2px solid #f76204;
    margin: 5px 0;
    width: 30px;
}

.payment-customer-detail {
    color: #40435b !important;
    font-family: 'Source Sans Pro' !important;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    margin-top: 8px !important;
}
.payment-total {
    color: #40435b !important;
    font-family: 'Source Sans Pro' !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    margin-top: 8px !important;
}
.payment-amount {
    color: #40435b !important;
    font-family: 'Source Sans Pro' !important;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    margin-top: 8px !important;
}

.container-payment-detail-title {
    height: 50px;
}
.container-payment-total-amount {
    height: 50px;
}

.container-concept-detail {
    height: 50px;
    margin-top: 34px;
}
.payment-concept{
    color: #40435b !important;
    font-family: 'Source Sans Pro' !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    margin-top: 8px !important;
}
