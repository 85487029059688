.first-row {
  padding: 24px;
  border-bottom: 1px solid #d1d1d1;
}

.second-row {
  padding: 16px 24px;
  border-bottom: 1px solid #d1d1d1;
}

.secondRow-line {
  display: flex;
  margin-bottom: 16px;
  justify-content: space-between;
}

.third-row {
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.thirdRow-button {
  width: 88px;
  height: 34px;
  border-radius: 8px;
  background: #e0e1e9;
}
