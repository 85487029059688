.message {
    padding: 16px 20px;

    /* lg */
    box-shadow: 0px 4px 6px -2px rgba(64, 67, 91, 0.05), 0px 10px 15px -3px rgba(64, 67, 91, 0.1);

    /* position: absolute;
    top: 56px;
    z-index: 50; */

    display: block;
}
.message-hidden {
    display: none;
}
.message div {
    padding: 0 !important;
}
.message div span svg {
    color: #40435b;
    margin: 0 20px;
    font-size: 16px;
}
.container {
    display: flex;
    align-items: center;
}
.icon-type {
    margin-right: 16px;
}
.container-buttons {
    justify-content: flex-end;
    align-items: flex-end;
}
.header-styles {
    font-family: SourceSansProSemiBold;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: #40435b;
}
.subtext-styles {
    font-family: SourceSansProRegular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: #40435b;
    margin-top: 0 !important;
}
.wrapper-container {
    display: flex;
    align-items: center;
}
.buttons {
    padding: 0px 16px !important;
    text-decoration: underline;
    color: #0171e6;
}
.buttons-coins {
    padding: 0px 24px !important;
    text-decoration: underline;
    color: #0171e6;
}
.buttons-update {
    color: #0171e6;
    background-color: transparent !important;
    border: 1px solid #0171e6;
    padding: 10px 24px;
    border-radius: 8px;
    margin-right: 24px;
}
.buttons-update:hover,
.buttons-update:focus,
.buttons-update:active {
    color: #0171e6 !important;
    background-color: transparent !important;
}
.flex-container-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.flex-container-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0px 24px;
    position: relative;
    color: #0171e6;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    text-decoration: underline;
    margin-right: 20px;
}
.container-icon {
    bottom: 15px;
    right: 10px;

    background-color: transparent !important;
}
.container-icon:hover,
.container-icon:focus,
.container-icon:active {
    background-color: transparent !important;
}
.icon {
    width: 16px !important;
    height: 16px !important;
}
