.chip-discount {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 16;
    cursor: pointer;
}

.chip-check-volume-discount {
    display: flex;
    height: 20px;
    width: 100%;
    padding: 0px 8px;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    cursor: pointer;
    background-color: #07ad4b;
}

.volume-discount-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
}

.volume-discount-container:hover .chip-child-left {
    text-decoration: underline;
}

.volume-discount-text {
    overflow: hidden;
    text-align: right;
    font-feature-settings: clig off, liga off;
    text-overflow: ellipsis;
    font-family: Source Sans Pro;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    max-width: 100%;
    white-space: nowrap;
}
.icon-gift {
    display: flex;
    width: 16px;
    height: 16px;
    padding: 0.667px 1.333px 1.333px 1.333px;
    justify-content: center;
    align-items: center;
}
