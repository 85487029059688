.container-drawer {
 

  width: 473px !important;
}
.container-drawer div[role='document'] div[class='rs-drawer-content'] {
  border-radius: 16px 0px 0px 16px !important;
  background: var(--neutral-white, #fff) !important;

  /* all */
  box-shadow: 0px 0px 6px -2px rgba(64, 67, 91, 0.2),
    0px 0px 15px -3px rgba(64, 67, 91, 0.2) !important;
}
.container-body {
  padding: 32px !important;
  /* overflow: hidden !important; */

  /* display: flex;
  align-items: center; */
}
.container-body-success {
  display: flex !important;
  align-items: center !important;
}
.header-drawer {
  display: none;
}
.wrapper-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.title-body {
  font-family: SourceSansProSemiBold;
  color: var(--neutral-alpha-800, rgba(64, 67, 91, 0.9));
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.container-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 32px;
}
.button-omit-action {
  display: flex;
  height: 40px;
  padding: 10px 24px;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 8px;
  border-radius: 8px;
  font-family: SourceSansProRegular;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: var(--primary-600, #0171e6);
  background-color: transparent !important;
}
.button-omit-action:active,
.button-omit-action:hover,
.button-omit-action:focus {
  color: var(--primary-600, #0171e6);
  background-color: transparent !important;
}
.button-action {
  display: flex;
  height: 40px;
  padding: 10px 24px;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 8px;
  border-radius: 8px;
  background: var(--primary-600, #0171e6);
  color: var(--neutral-white, #fff);
  font-family: SourceSansProRegular;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.button-action:active,
.button-action:focus,
.button-action:hover {
  background: var(--primary-600, #0171e6);
  color: var(--neutral-white, #fff);
}
.close-button-action {
  background: transparent !important;
  color: #40435B;
}
.close-button-action  svg {
  color: #40435B;

}
.close-button-action :hover , .close-button-action :action, .close-button-action :focus {
  background: transparent !important;
  color: #40435B;
}