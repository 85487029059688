.title {
    font-weight: 600;
    font-size: 20px;
    color: #333333;
}

.line {
    width: 608px;
    height: 1px;
    background-color: #E0E1E9;
    margin-top: 24px;
    margin-bottom: 24px;
}