.alert-message {
  border-radius: 8px;
  padding: 16px;
  font-size: 14px;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;
  width: 100%;
  height: 100%;
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}
