/* ERRORS */
.errors {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.errors > div:first-child {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    flex-wrap: nowrap;
    min-width: max-content;
    height: 24px;
}

.errors > div:last-child {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    word-break: break-all;
}

.text-error {
    color: var(--Neutral-800, #40435b);
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.box {
    display: flex;
    height: 24px;
    padding: 0px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    border: 1px solid var(--NeutralAlpha-White, rgba(255, 255, 255, 0.8));
    background-color: #ffffffcc;
}

.box > p {
    color: var(--Neutral-800, #40435b);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}

.fade-in {
    -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
