/* ---------------------FOOTER--------------------------- */
.footer {
    /* grid-row: 5; */
    position: sticky;
    z-index: 999;
    bottom: 80px;
    background-color: #fff;
    box-shadow: 0px -4px 15px -3px rgba(64, 67, 91, 0.1), 0px -4px 6px -2px rgba(64, 67, 91, 0.1);
}

/* BORDER TOP  */
.footer::before {
    content: '';
    position: absolute;
    top: 0;
    left: calc(-1 * (100vw - 100%) / 2);
    width: 100vw;
    border-top: 0.5px solid #acaec4;
    box-shadow: 0px -4px 15px -3px rgba(64, 67, 91, 0.1), 0px -4px 6px -2px rgba(64, 67, 91, 0.1);
}

/* PRODUCT COLUMN */
.footer > div:first-child {
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 0.5px solid #BED0E5;
}

.footer > div:first-child::before {
    content: '';
    position: absolute;
    top: 0;
    left: -20px;
    width: 20px;
    height: 100%;
    background-color: #fff;
    border-top: 0.5px solid #acaec4;
    box-shadow: 0px -4px 0px -3px rgba(64, 67, 91, 0.1), 0px -4px 0px -2px rgba(64, 67, 91, 0.1);
    border-bottom: 0.5px solid #BED0E5;
}

.footer > div:last-child::after {
    content: '';
    position: absolute;
    top: 0;
    right: -20px;
    width: 20px;
    height: 100%;
    background-color: #fff;
    border-top: 0.5px solid #acaec4;
    box-shadow: 0px -4px 15px -3px rgba(64, 67, 91, 0.1), 0px -4px 6px -2px rgba(64, 67, 91, 0.1);
}

/* SCROLL BAR HORIZ */
/* VERTICAL SCROLL HIDDEN */
.footer > div:nth-child(2) {
    overflow-y: hidden;
}
/* Modificar el estilo de la barra de desplazamiento horizontal */
.footer > div:nth-child(2)::-webkit-scrollbar {
    height: 4px;
    background-color: #e0e1e9;
}

/* Modificar el estilo del control deslizante de la barra de desplazamiento horizontal */
.footer > div:nth-child(2)::-webkit-scrollbar-thumb {
    cursor: pointer;
    background-color: #6b6f93;
    border-radius: 8px;
}

/* Estilo para la barra de desplazamiento cuando el mouse no está encima de ella */
/* Cambia el color de fondo cuando no se está interactuando */
.footer > div:nth-child(2)::-webkit-scrollbar-track {
    background-color: #e0e1e9;
}

.footer > div:nth-child(2) > div {
    /* min-width: 146px; */
    /* width: var(--cell-max-width); */
    min-width: 146px;
}

/* ARROW */
.arrow {
    border-radius: 8px 8px 0px 0px;
    background: var(--Gradient, linear-gradient(180deg, #0f7ff4 0%, #1759df 100%));
    box-shadow: 0px -4px 5px -1px rgba(64, 67, 91, 0.1), 0px -2px 4px -1px rgba(64, 67, 91, 0.06);
    position: absolute;
    top: -25px;
    left: -3px; /*diff 19px table - 16px arrow */
    width: 40px;
    height: 25px;
    z-index: 999;
}

.arrow:hover {
    cursor: pointer;
}

/* DEFAULT CELL */
.cell-price {
    border-top: 0.5px solid var(--Secondary-300, #bed0e5);
    border-bottom: 0.5px solid var(--Secondary-300, #bed0e5);
    border-left: 0.5px solid var(--Secondary-300, #bed0e5);
    background: var(--Secondary-25, #f0f5ff);
    box-shadow: 0px 4px 5px -1px rgba(64, 67, 91, 0.1), 0px 2px 4px -1px rgba(64, 67, 91, 0.06);
    padding: 16px 8px 0px 8px;
    display: grid;
    grid-row: 1;
    align-items: flex-start;
    border-bottom: 0.5px solid var(--Secondary-300, #bed0e5);
}
.cell-price-with-vendor{
    border-top: 0.5px solid var(--Secondary-300, #bed0e5);
    border-bottom: 0.5px solid var(--Secondary-300, #bed0e5);
    border-left: 0.5px solid var(--Secondary-300, #bed0e5);
    background: var(--Secondary-25, #f0f5ff);
    box-shadow: 0px 4px 5px -1px rgba(64, 67, 91, 0.1), 0px 2px 4px -1px rgba(64, 67, 91, 0.06);
    padding: 0px 8px 0px 8px;
    display: flex;
    justify-content: center;
    grid-row: 1;
    align-items: center;
    border-bottom: 0.5px solid var(--Secondary-300, #bed0e5);
}
.cell-price:last-child {
    border-right: 0.5px solid var(--Secondary-300, #bed0e5);
    border-radius: 0px 0px 8px 0px;
    overflow: hidden;
}

/* CELL WHEN EXPANDED FOOTER */
.cell-price-expanded {
    border-top: 0.5px solid var(--Secondary-300, #bed0e5);
    border-bottom: 0.5px solid var(--Secondary-300, #bed0e5);
    border-left: 0.5px solid var(--Secondary-300, #bed0e5);
    background: var(--Secondary-25, #f0f5ff);
    box-shadow: 0px 4px 5px -1px rgba(64, 67, 91, 0.1), 0px 2px 4px -1px rgba(64, 67, 91, 0.06);
    padding: 8px;
    display: grid;
    grid-template-rows: 52px 16px;
    row-gap: 4px;
    text-align: end;
}
.cell-price-expanded-with-vendor{
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 0.5px solid var(--Secondary-300, #bed0e5);
    border-bottom: 0.5px solid var(--Secondary-300, #bed0e5);
    border-left: 0.5px solid var(--Secondary-300, #bed0e5);
    background: var(--Secondary-25, #f0f5ff);
    box-shadow: 0px 4px 5px -1px rgba(64, 67, 91, 0.1), 0px 2px 4px -1px rgba(64, 67, 91, 0.06);
    padding: 8px;
}
.cell-price-expanded:last-child {
    border-right: 0.5px solid var(--Secondary-300, #bed0e5);
    border-radius: 0px 0px 8px 0px;
    overflow: hidden;
}

/* CONTAINER */
.subtotal {
    grid-row: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    color: #40435b;
    text-align: center;
    font-family: 'Source Sans Pro';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    text-transform: none;
}
.container-expanded {
    grid-row: 1;
    display: grid;
    grid-template-rows: 20px 16px 16px;
    justify-content: flex-end;
}
.total-column {
    grid-row: 1;
    color: var(--Neutral-800, var(--800, #40435b));
    text-align: right;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
}

.quantity-products {
    grid-row: 2;
    display: flex;
    justify-content: flex-end;
}
.quantity-units {
    grid-row: 3;
}
.quantity-products,
.quantity-units > p {
    color: var(--Neutral-700, #6b6f93);
    text-align: right;
    font-feature-settings: 'clig' off, 'liga' off;
    /* Caption/Caption 2 */
    font-family: 'Source Sans Pro';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
}
.container-percentage-expanded {
    grid-row: 2;
    border-radius: 4px;
    border: 0.5px solid var(--Secondary-300, #bed0e5);
    padding: 0px 8px;
}
.container-percentage-expanded > p {
    margin: 0;
    color: var(--Secondary-500, #2c4560);
    text-align: right;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Source Sans Pro';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
}
/* NUMBERS */
.number {
    color: #40435b;
    font-family: 'Source Sans Pro';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}
.numberInt {
    color: #40435b;
    font-family: 'Source Sans Pro';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin: 0px;
}
.numberDec {
    color: #40435b;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-left: -3px;
}
.tax {
    color: #40435b;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
/* DEFAULT BACKGROUND CELLS PRICE IN COLUMN MIDDLE */
.footer > div:nth-child(2) > div:nth-child(even) {
    background-color: #fff;
}
.footer > div:nth-child(2) > div:nth-child(odd) {
    background-color: #f0f5ff;
}
/* TOTALS */
.total {
    color: var(--Neutral-800, var(--800, #40435b));
    text-align: right;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
}
.total-with-vendor {
    color: var(--Neutral-800, var(--800, #40435b));
    text-align: right;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
.numberInt {
    color: #40435b;
    text-align: right;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin: 0;
}
.numberDec {
    color: #40435b;
    text-align: right;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Source Sans Pro';
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin: 0;
    margin-left: -3px;
}

/* NEW STYLE */
.middle > div:last-child {
    background-color: #badbff;
}

.middle > div:last-child {
    position: sticky;
    top: 0;
    right: 0;
}

/* CDO */
.cell-cdo-disabled {
    padding: 0px 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}