.wrapper-items-links {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 16px 24px;
}

.tooltip-component {
    background-color: #0b2033 !important;
    color: #fff;
    font-family: SourceSansProRegular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    left: 74px !important;
}

.tooltip-component::after {
    border-right-color: #0b2033 !important;
}

.border-line::before {
    content: '';
    position: absolute;
    left: 0px;
    top: 16px;
    width: 4px;
    height: 24px;
    background-color: #0171e6;
}

.container-popover-new {
    border-radius: 8px;
    background: var(--Primary-600, #0171e6);
    width: 365px;
}
.container-onboarding {
    border-radius: 8px;
    background: var(--Primary-600, #0171e6);
    width: 365px;

    z-index: 99999;
    position: absolute;
    left: 83px;
    top: 70%;
    padding: 24px;
}

.container-onboarding::before {
    content: '';
    position: absolute;
    top: 10%; /* Centra verticalmente */
    left: -20px; /* Ajusta según el tamaño */
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-width: 10px;
    border-style: solid;
    border-color: transparent #0171e6 transparent transparent; /* Define los colores de los bordes */
}
.container-popover-new ::after {
    border-right-color: #0171e6 !important;
}
.analytics-tags {
    border-radius: 100px;
    background: var(--Discount-Discount, #ff6300);
    display: flex;
    align-self: center;
    justify-content: center;
    text-align: center;
    padding: 4px 4px 5px 4px;

    color: var(--Neutral-White, #fff);
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 180% */
}
