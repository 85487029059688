.container-text{
    display: flex;
}
.text-value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.quantity {
    padding-left: 8px;
}

.actionCheckbox label{
    color: #ACAEC4 !important;
}

.actionCheckbox label > span > span::before {
    background: #E0E1E9 !important;
    border: 1px solid #ACAEC4;
}