

.label-with-icon {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center;
}
.required-asterisk {
    color: red;
   padding-left: 4px;
}

.info-label {
    color: var(--Neutral-700, #6b6f93);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.label-form {
    color: var(--Neutral-800, #40435b);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Label/Label 2 */
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 128.571% */
}
