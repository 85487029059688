.container-payment-form {
    /* background-color: #0171e6;
    height: 100vh; */
 /*    //padding: 24px !important;
    display: flex;
    justify-content: center;
    align-items: center; */
    width: 100%;
    display: flex;
    justify-content: center;
}




.panel-style {
    background-color: #fff;
    width: 50%;
    height: 730px;
}
.panel-style div[aria-expanded="false"]{
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}


.container-form-contact {
    padding: 24px;
}


.payment-action-button {
    margin-top: 32px;
}

.payment-button {
    width: 60%;
}

.payment-footer {
    display: flex;
    justify-content: center;
    align-items: center;
}
.payment-footer span {
    margin-right: 8px;
    color: #40435b;
    font-family: 'Source Sans Pro';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-top: 8px;
}

.form-contact{
    color: #40435b;
    font-family: 'Source Sans Pro';
    font-size: 20px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 8px;
}

.payment-advice-privacity{
    color: #40435b;
    font-family: 'Source Sans Pro';
    font-size: 16px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin: 24px 0px;
}

.container-payment-button{
    display: flex;
    justify-content: flex-end;
}