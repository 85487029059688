.wrapper-row {
  position: relative;
}
.col-wrapper {
  display: flex;
  justify-content: center;
}
.wrapper-paginator {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  height: 50px;
  width: 660px;
}
.wrapper-button {
  position: absolute;
  top: 0px;
  right: 10%;
  bottom: 0;
  display: flex;
  align-items: center;
}
