.arrow-left {
    display: flex;
    width: 32px;
    height: 32px;
    padding: 8px;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    border: 4px solid var(--Primary-500, #84c0fe);
    background: var(--Primary-600, #0171e6);
    transform: rotate(180deg);
}

.arrow-right {
    display: flex;
    width: 32px;
    height: 32px;
    padding: 8px;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    border: 4px solid var(--Primary-500, #84c0fe);
    background: var(--Primary-600, #0171e6);
}
