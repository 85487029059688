
.container-dropdown-menu {
  padding: 0px !important;
}
.container-dropdown-menu li {
  padding: 12px 16px;
  text-align: start;
  color: #40435b;

  font-family: SourceSansProRegular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}