.button-settings {
  display: flex;
  height: 32px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #dcedff;
  cursor:pointer;
  color: #0171e6;

  font-family: SourceSansProRegular;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  margin-right: 8px;
}
.button-settings:hover,
.button-settings:active,
.button-settings:focus {
  background: #dcedff;

  color: #0171e6;
}

.button-settings-disabled{
  color: #c5c6c7; 
  background-color: var(--rs-btn-default-disabled-bg);
}

.settings-icon {
  width: 16px !important;
  height: 16px !important;

  display: flex !important;
}