.text-informative {
    display: flex;
    width: 309px;
    padding: 8px 0px;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    border: 0.5px solid var(--Semantic-Information-600, #1a66fc);
    background: var(--Semantic-Information-50, #f2f7ff);
    margin-bottom: 0.5px;
}

.text-informative > span {
    color: var(--Semantic-Information-600, #1a66fc);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}
