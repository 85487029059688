.first-col {
  font-size: 16px;
  color:#646464;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.second-col {
  width:100%;
  height:100%;
  display: flex;
  justify-content: flex-end;
  font-size: 16px;
  font-weight: 700;
}

.total_products{
  color: #6B6F93;
  font-size:14px;
  font-weight: 600;
}

.unit_products{
  color: #6B6F93;
  font-size:14px;
  font-weight: 400;
}

.first-row-title{
  color: #40435B;
  font-size: 16px;
  font-weight: 700;
}