.container-table {
    background-color: #fff;
    margin-top: 24px;
}

.container-label-status div {
    display: flex !important;
    align-items: center !important;
}

.label-active-status {
    color: var(--Semantic-Success-600, #07ad4b);
    font-feature-settings: 'liga'off, 'clig'off;

    /* Body/Body 2/SemiBold */
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    /* 128.571% */

    text-transform: capitalize;
}

.label-pending-status {
    color: var(--Semantic-Information-600, #1a66fc);
    font-feature-settings: 'liga'off, 'clig'off;

    /* Body/Body 2/Regular */
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */

    text-transform: capitalize;

}

.label-defeated-status {
    color: var(--Neutral-700, #6b6f93);
    font-feature-settings: 'liga'off, 'clig'off;

    /* Body/Body 2/Regular */
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */

    text-transform: capitalize;

}

.header-cell {
    background: #D2F4F6;
    border-radius: 0px 4px 4px 0px;
    color: var(--Neutral-800, #40435B);
    font-feature-settings: 'liga'off, 'clig'off;
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    /* 128.571% */
}

.table-cell {
    color: var(--Neutral-800, #40435B);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    
}
.table-cell-email {
    color: var(--Neutral-800, #40435B);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    overflow: hidden;
    white-space: nowrap; /* Evita el salto de línea */
    text-overflow: ellipsis; /* Agrega los tres puntos */
    max-width: 320px; /* Ajusta el ancho según tu diseño */
}

.table>div>div>div[role="row"]:hov {
    background-color: red !important;
}

.table {
    height: 250px;
}

.container-label-status {
    display: flex;
    align-items: center;
    position: relative;
}

.copy-icon {
    visibility: hidden;
    margin-left: 8px;
    background: transparent !important;
    transition: visibility 0.3s ease;
}

.cell-email:hover .copy-icon-email {
    display: block;
}

.copy-icon-email {
    display: none;
    margin-left: 8px;
    background-color: transparent !important;
    transition: visibility 0.3s ease;
}

.ellipsis {
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.container-label-status:hover .copy-icon {
    visibility: visible;
}