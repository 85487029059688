.add_icon {
    cursor: pointer;
}

.discount-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.discount-text > p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.multiple-discount {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4;
    text-decoration: underline;
}

.multiple-discount:hover {
    cursor: pointer;
}
