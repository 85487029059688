.wrapper-container-images{
    display: flex;
    justify-content: center;
    align-items: center;
}
.image-container {
  position: relative;
}

.image-vector {
  position: absolute;
  left: 0;
  top: -10%;
  bottom: 0;
  z-index: 2;
}

.container-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  margin-bottom: 50px;
}
.title-out-of-orders{
    color:#333;
    font-family: SourceSansProRegular;
    font-size: 20px;
    font-weight: 700;
    margin-top: 35px;
    margin-bottom: 8px;
    width: 335px;
}
.subtitle-out-of-orders{
    color:#333;
    padding-bottom: 32px;
    font-family: SourceSansProRegular;
    font-weight: 400;
    width: 300px;
}

.action-button{
    font-family: SourceSansProRegular;
    font-size: 14px;
    font-weight: 600;
}