.payment-container-maskedinput{
    margin-top: 8px;
}
.payment-input-masked{
    border: 0.5px solid #40475B;
}
.payment-input-masked:hover, .payment-input-masked:active, .payment-input-masked:focus {
    border: 0.5px solid #40475B !important;
    outline: #40475B !important;
}
.payment-input-masked-error {
    border:0.5px solid #ff4d4f !important;
    
}
.payment-input-masked-error:hover, .payment-input-masked-error:active, .payment-input-masked-error:focus {
    border:0.5px solid #ff4d4f !important;
    outline: #ff4d4f !important;
}
.payment-maskedInput-label {
    color: #40435b;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}
.error-message {
    color: #ff4d4f; /* Color rojo para el mensaje de error */
    font-size: 13px; /* Tamaño de fuente más pequeño */
    font-weight: 500; /* Peso de fuente para darle más énfasis */
    margin-top: 0.25rem; /* Espaciado arriba para separar del input */
    line-height: 1.25; /* Alineación vertical para mejor legibilidad */
    display: block; /* Asegura que el mensaje se muestre en una nueva línea */
    padding: 0.25rem 0; /* Padding para separar un poco el texto */
}