.dropdown-content {
    z-index: 1000;
    margin: 0px;
}

.dropdown-content[role='menu'] {
    margin-top: 14px !important;
}
.dropdown-content:hover,
.dropdown-content:active,
.dropdown-content:focus {
    color: #fff !important;
}

.dropdown-content button {
    background: none !important;
    display: flex !important;
    align-items: center !important;
    justify-content: flex-end !important;
    padding: 0px !important;
}

.dropdown-content button:hover,
.dropdown-content button:focus,
.dropdown-content button:active {
    color: #fff !important;
}

.dropdown-content ul {
    height: auto !important;
}

.container-setting {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 56px;
}

.wrapper-dropdown-item {
    display: flex;
    align-items: center;
}

.wrapper-icon {
    display: flex;
    align-items: center;
    margin-right: 8px;
}

.container-blur {
    height: 44px;
    width: 200px;
    position: absolute;
    z-index: 9999;
    opacity: 0.9;
    background: linear-gradient(
        90deg,
        #fff 89.92%,
        rgba(255, 255, 255, 0) 51.07%,
        rgba(255, 255, 255, 0) 34.95%,
        #fff 81.9%
    );
}
