.name-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.name-section p {
    margin: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
    max-width: 153px;
}

.name {
    font-family: Source Sans Pro;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    color: var(--800, #40435b);
}

.error {
    font-family: Source Sans Pro;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    color: #e22e2e;
}
