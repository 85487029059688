.container-shopping-cart {
    cursor: pointer;
    padding-top: 7px;
    display: flex;
    height: 56px;
    padding-left: 24px;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
}
.container-cart {
    position: relative;
    cursor: pointer;
}

.icon-index {
    width: 24px;
    height: 24px;
    color: #fff;
}

.container-badge {
    position: absolute;
    bottom: 22px;
    left: 11px;
    right: 10px;
    z-index: 25;
}

.badge-index {
    width: 24px;
    display: flex;
    justify-content: center;
    background-color: #ff6300;
}

.container-shopping-cart-blur {
    width: 150%;
    z-index: 9999;
    opacity: 0.9;
    height: 47px;
    position: absolute;
    top: -10px;
}
