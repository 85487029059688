.accordion {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: flex-start;
    align-items: flex-start;
}

.accordion-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease-in-out;
}

.accordion-content.show {
    max-height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    gap: 8px;
}
