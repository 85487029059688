.container {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    width: 100% !important;
    margin: 0px !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.container > div:first-child {
    width: 68%;
}

.container > div:last-child {
    width: 32%;
}

.icon {
    display: none;
}

.container:hover {
    .icon {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 0px;
    }
}

.description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.wrapper-description {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}
/* 
.product-name {
    font-size: 16px;
    font-weight: 600;
    color: #40435B;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
} */

.barcode {
    font-size: 14px;
    font-weight: 400;
    color: #6B6F93;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

.button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}