.text-field-component div {
  height: 40px;
}
.input {
  width: 275px;
}

.search-button {  
  background-color: #fff !important;
}

.icon-svg {
  font-weight: bolder !important;
  color: #0171e6;
}


.icon-svg-disabled {
  font-weight: bolder !important;
  color: #ACAEC4;
}

.input-search::placeholder {
  padding-left: 8px;
}
.input-search::target-text {
  padding-left: 8px;
}
.input-search {
  padding-left: 15px !important;
}

.container-input {
  width: 448px;
  height: 40px !important;
  margin-right: 16px;
}

.input-search:disabled {
  background: #fff !important;
}



@media (max-width: 1366px) {
  .container-input {
    width: 300px;
  }
}
