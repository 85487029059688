.modal {
    width: 400px;
}
.modal div[role='document'] > div:first-child {
    padding: 40px 32px !important;
    border-radius: 10px;
}
.modal-header {
    padding-right: 0;
}
.container-image-modal {
    display: flex;
    justify-content: center;
}
.badge-modal {
    background-color: #ff6300;

    color: #fff;

    font-family: SourceSansProRegular;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;

    padding: 0 8px;
}
.body-modal {
    margin-top: 0px !important;
    padding-bottom: 24px !important;
}
.title-modal {
    margin-top: 8px !important;

    color: #40435b;
    font-family: SourceSansProSemiBold;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}
.subtitle-modal {
    color: #6b6f93;
    font-family: SourceSansProRegular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
.action-button-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0171e6 !important;
    color: #fff !important;
    padding: 10px 24px !important;
    overflow: hidden;
    position: relative;
}
.action-button-modal:hover,
.action-button-modal :active,
.action-button-modal :focus {
    background-color: #0062c8 !important;
    color: #fff !important;
    box-shadow: none !important;
    outline: none !important;
}
.action-button-modal span {
    margin-right: 8px !important;
}

.text-button-active {
    color: #fff;
    font-family: SourceSansProRegular;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-left: 8px;
}
.required-container-modal {
    display: flex;
    justify-content: center;
    align-items: center;
}
.required-container-modal p {
    color: #acaec4;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-top: 16px;
}
.success-button-active {
    display: flex;
    justify-content: center;
    background-color: #07ad4b;
    color: #fff;
    padding: 10px 24px !important;
}
.success-button-active:hover,
.success-button-active:active,
.success-button-active:focus {
    background-color: #07ad4b;
    color: #fff;
    padding: 10px 24px !important;
}
.action-button-modal-loading:focus,
.action-button-modal-loading:active {
    box-shadow: none !important;
    outline: none !important;
}
.action-button-modal-loading {
    background: linear-gradient(to left, #0171e6 50%, #0062c8 50%);
    background-size: 200% 100%;
    background-position: 100 bottom;
    animation: fillEffect 3s linear forwards, opacityEffect 3s linear forwards 3s;
    opacity: 1;
    pointer-events: none;
}
@keyframes fillEffect {
    to {
        background-position: -100% bottom;
    }
}
@keyframes opacityEffect {
    0% {
        background-color: #0171e6 !important;
        opacity: 1;
    }
    
    99%{
        background-color: #0171e6 !important;
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
