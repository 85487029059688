.container-out-of-orders {
    background-color: #f0f5ff;
    height: 100%;
    display: flex;
    justify-content: center;
    position: relative;
}

.container-banner {
    position: absolute;
}

.margin {
    height: 32px;
}
.container-disclaimer {
    width: 656px;
    text-align: justify;
    hyphens: auto;
    word-break: break-word;
    white-space: normal;
    color: var(--Neutral-700, #6b6f93);
    /* Caption/Caption 2 */
    font-family: 'Source Sans Pro';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;

    margin-bottom: 32px;
}
