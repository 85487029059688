.wrapper-card {
    background-color: #fff;
    border-radius: 8px;
    width: 660px;
    transition: box-shadow 0.3s ease;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    margin-top: 32px;
    border-top-width: 4px;
    border-top-style: solid;
}

.details-card {
    border-radius: 8px;
    box-shadow: 0px 2px 4px -1px #40435b0f;
    box-shadow: 0px 4px 5px -1px #40435b1a;
    padding: 16px 24px;
    margin-bottom: 24px;
    max-width: 660px;
    background-color: white;
}

.card-title {
    font-size: 16px;
    font-weight: 600;
    color: #333;
    margin: 0px;
    padding: 0px;
}

.card-type {
    font-size: 14px;
    color: #ff6300;
    margin: 0px;
    padding: 0px;
}

.container-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.button-web {
    display: flex;
    margin-right: 24px;
    color: #0171e6;
    align-items: center;
    cursor: pointer;
}

.button-web:hover,
.button-web:active,
.button-web:focus {
    color: #0171e6;
}

.button-download {
    height: 32px;
    width: 32px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s;
    border-radius: 8px;
}
.button-download:hover {
    background: #dcedff;
}
.button-download:active {
    border-radius: 8px;
    animation: pulse 0.7s;
}

.avatar_nameDrug {
    display: flex;
    width: 32px;
    height: 32px;
    padding: 0px 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 999px;
    background: #ebf5ff;
    margin-right: 16px;
    color: #0171e6;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
}
.container-delivery-number {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: var(--Neutral-800, var(--800, #40435b));

    /* Caption/Caption 1 */
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}
.label {
    margin-left: 4px;
}
.hash-container {
    display: flex;
    justify-content: flex-end;

    color: var(--Neutral-800, var(--800, #40435b));

    /* Caption/Caption 2 */
    font-family: 'Source Sans Pro';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
}
.hash-container p {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 160px;
}
.container-delivery {
    margin: 0 24px;
}
@keyframes pulse {
    0% {
        outline: 0px solid rgba(35, 130, 220, 1);
        outline-offset: 0.3rem;
    }
    50% {
        outline: 1px solid rgba(35, 130, 220, 1);
        outline-offset: 0.3rem;
    }
    100% {
        outline: 2px solid rgba(35, 130, 220, 1);
        outline-offset: 0.3rem;
    }
}

.container-body {
    margin-top: 16px;
    margin-bottom: 16px;
    padding: 16px;
    background-color: #f5f6fa;
    border-radius: 8px;
}

.line {
    height: 1px;
    background-color: #e3e3e3;
}

