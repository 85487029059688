/* container form */
.form-container {
    width: 100%;
    text-align: start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
}

/* title description */
.box-description {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.title {
    color: var(--Secondary-800, #0b2033);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: 'Source Sans Pro';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin: 0px;
    width: 100%;
}

.subtitle {
    color: var(--Secondary-800, #0b2033);
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin: 0px;
}

/* form gral */
.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
}

.form > div:first-child {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
}

@media (max-height: 598px) {
    .form > div:first-child {
        height: 233px;
        overflow-y: auto;
    }
}

/* Estilo para la barra de scroll vertical */
.form > div:first-child::-webkit-scrollbar {
    width: 4px; /* Ancho del scrollbar */
}

/* Estilo para el fondo del scrollbar */
.form > div:first-child::-webkit-scrollbar-track {
    background: transparent; /* Fondo transparente, si prefieres que no tenga color */
}

/* Estilo para el thumb (la parte que se mueve del scrollbar) */
.form > div:first-child::-webkit-scrollbar-thumb {
    background: var(--Neutral-700, #6b6f93); /* Fondo del thumb */
    border-radius: 8px; /* Borde redondeado */
}

/* Opcional: Estilo al hacer hover sobre el thumb */
.form > div:first-child::-webkit-scrollbar-thumb:hover {
    background: #5a5f81; /* Color del thumb al hacer hover */
}

/* all input */
.input {
    width: 100%;
    margin: 0px !important;
}

.box-password {
    display: flex;
    flex-direction: column;
    gap: 4px;
}
/* quitar error text */
.input > div > div:last-child {
    display: none;
}

/* aplicar padding y gap personalizado entre el startIcon y el input */
.input > div > div {
    padding: 10px 16px;
    gap: 8px;
    height: 48px;
}

/* aplicar altura nueva al startIcon e input */
.input > div > div * {
    height: 24px;
}

/* remover padding de startIcon */
.input > div > div > span {
    padding: 0px;
}

/* remover padding de input */
.input > div > div > div > input {
    height: 100%;
    padding: 0px;
}

/* quitar fondo azul porque sino le aplica solo al texto (input) */
.input > div > div > div > input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
    background-color: #fff !important;
}

/* quitar fondo azul porque sino le aplica solo al texto (input) */
.input > div > div > div > input:autofill {
    background-color: #fff !important;
}

/* aplicar overflow hidden al input para evitar desborde y perdida de border */
.input > div > div > div {
    overflow: hidden;
}

/* link */
.redirect-login {
    color: var(--Primary-600, #0171e6);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: center;
}

/* Estilos para pantallas pequeñas (menos de 768px) */
@media (max-width: 768px) {
    .form {
        margin-top: 16px;
    }
    .form {
        gap: 16px;
    }
    .title {
        display: none;
    }
    .box-description {
        display: none;
    }
    .input > div > div {
        background-color: #fff !important;
    }
}