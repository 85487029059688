.wrapper-card {
  background-color: #fff;
  border-radius: 8px;
  width: 660px;
  transition: box-shadow 0.3s ease;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  position: relative;
}

.header {
  padding: 24px;
}

.firts-row {
  padding: 24px;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  display: flex;
  flex-direction: row;
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  border-color: #d1d1d1;
}

.col {
  display: flex;
  align-items: center;
}

.icon {
  color: #0171e6;
  margin-right: 8px;
}

.svg path { 
  fill: blue !important;

}
