.header{
  padding:24px;
  background: linear-gradient(180deg, #0F7FF4 0%, #1759DF 100%);
  border-radius: 8px 8px 0px 0px;
  height: 94px;
}

.header-load{
  padding:24px;
  background: linear-gradient(180deg, #0F7FF4 0%, #1759DF 100%);
  border-radius: 8px 8px 0px 0px;
}

.title{
  font-size: 20px;
  font-weight: 700;
  color:#ffff;
}

.sub-title{
  font-size: 14px;
  font-weight: 600;
  color:#ffff;
}

.rs-placeholder-paragraph {
 color: red;
  padding: 8px;
}