.container {
    width: 100%;
    height: 130px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    padding: 16px;
    border-radius: 8px;
    background: #ebf5ff;
}

.container > div:first-child {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
}

.container > div:first-child > p:first-child {
    font-family: Source Sans Pro;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    color: var(--800, #40435b);
    margin: 0px;
}

.container > div:first-child > p:last-child {
    font-family: Source Sans Pro;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: var(--800, #40435b);
    margin: 0px;
}

.placeholder {
    width: 100% !important;
}

.placeholder > div {
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    gap: 4px !important;
}

.placeholder > div > p {
    margin: 0px !important;
    border-radius: 4px !important;
}

.placeholder > div > p:first-child {
    width: 141px !important;
    height: 20px !important;
}

.placeholder > div > p:last-child {
    width: 340px !important;
    height: 18px !important;
    background: #f5f6fa !important;
}

.button > div {
    width: 340px !important;
    height: 40px !important;
    border-radius: 8px !important;
}
