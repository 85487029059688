.avatars {
    width: 32px !important;
    height: 32px !important;
    border-radius: 999px;
    border: 1px solid #fff;
    background: #ebf5ff;

    /* xs */
    box-shadow: 0px 1px 2px 0px rgba(64, 67, 91, 0.06), 0px 1px 3px 0px rgba(64, 67, 91, 0.1);

    color: #0171e6;
    text-align: center;

    font-family: SourceSansProRegular;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    text-transform: uppercase;
}
.avatars_d {
    width: 42px !important;
    height: 24px !important;
    border-radius: 999px;
    border: 1px solid #fff;
    background: #ebf5ff;

    /* xs */
    box-shadow: 0px 1px 2px 0px rgba(64, 67, 91, 0.06), 0px 1px 3px 0px rgba(64, 67, 91, 0.1);

    color: #0171e6;
    text-align: center;

    font-family: SourceSansProRegular;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    text-transform: uppercase;
}
.avatars-unassigned {
    width: 32px !important;
    height: 32px !important;
    border-radius: 999px;
    border: 1px solid #fff;
    background: #ffe0e0;
    /* xs */
    box-shadow: 0px 1px 2px 0px rgba(64, 67, 91, 0.06), 0px 1px 3px 0px rgba(64, 67, 91, 0.1);

    color: #d20612;
    text-align: center;

    font-family: SourceSansProRegular;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    text-transform: uppercase;
}
.avatars-unassigned_d {
    width: 42px !important;
    height: 24px !important;
    border-radius: 999px;
    border: 1px solid #fff;
    background: #ffe0e0;
    /* xs */
    box-shadow: 0px 1px 2px 0px rgba(64, 67, 91, 0.06), 0px 1px 3px 0px rgba(64, 67, 91, 0.1);

    color: #d20612;
    text-align: center;

    font-family: SourceSansProRegular;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    text-transform: uppercase;
}
.avatars-tolerance_firstIdx{
    width: 32px !important;
    height: 32px !important;
    border-radius: 999px;
    border: 1px solid #fff;
    background: #fff4d9;
    /* xs */
    box-shadow: 0px 1px 2px 0px rgba(64, 67, 91, 0.06), 0px 1px 3px 0px rgba(64, 67, 91, 0.1);

    color: #a16205;
    text-align: center;

    font-family: SourceSansProRegular;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    text-transform: uppercase;
}
.avatars-tolerance{
    width: 32px !important;
    height: 32px !important;
    border-radius: 999px;
    border: 1px solid #fff;
    /* xs */
    box-shadow: 0px 1px 2px 0px rgba(64, 67, 91, 0.06), 0px 1px 3px 0px rgba(64, 67, 91, 0.1);
    background: #ebf5ff;

    color: #0171e6;
    text-align: center;

    font-family: SourceSansProRegular;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    text-transform: uppercase;
}
.avatars-tolerance_d {
    width: 42px !important;
    height: 24px !important;
    border-radius: 999px;
    border: 1px solid #fff;
    background: #fff4d9;
    /* xs */
    box-shadow: 0px 1px 2px 0px rgba(64, 67, 91, 0.06), 0px 1px 3px 0px rgba(64, 67, 91, 0.1);

    color: #a16205;
    text-align: center;

    font-family: SourceSansProRegular;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    text-transform: uppercase;
}
.avatar-component-all {
    width: 107px;
    height: 32px;

    border-radius: 999px;
    border: 0.5px solid #e0e1e9;
    background: #fff;

    box-shadow: 0px 1px 2px 0px rgba(64, 67, 91, 0.06), 0px 1px 3px 0px rgba(64, 67, 91, 0.1);

    color: #40435b;
    text-align: center;

    font-family: SourceSansProRegular;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}
.container-n-avatars {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.avatars-n-droguerias {
    width: 32px !important;
    height: 32px !important;
    border-radius: 999px;
    border: 1px solid #fff;
    background: #ebf5ff;

    color: #0171e6;
    text-align: center;

    font-family: SourceSansProRegular;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;

    padding: 0 5px;
    text-transform: uppercase;

    margin-right: 8px;
}

.avatars-n-droguerias_d{
    width: 42px !important;
    height: 24px !important;
    border-radius: 999px;
    border: 1px solid #fff;
    background: #ebf5ff;

    color: #0171e6;
    text-align: center;
    text-transform: uppercase;
    font-family: SourceSansProRegular;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    margin-right: 8px;
}
.avatars-unassigned-n-droguerias_d {
    width: 56px !important;
    height: 24px !important;
    border-radius: 999px;
    border: 1px solid #fff;
    background: #ffe0e0;

    color: #d20612;
    text-align: center;
    flex-direction: row;
    font-family: SourceSansProRegular;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    text-transform: uppercase;
    padding: 0 5px;

    margin-right: 8px;
}
.avatars-unassigned-n-droguerias {
    width: 32px !important;
    height: 32px !important;
    border-radius: 999px;
    border: 1px solid #fff;
    background: #ffe0e0;

    color: #d20612;
    text-align: center;

    font-family: SourceSansProRegular;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    text-transform: uppercase;
    padding: 0 5px;

    margin-right: 8px;
}
.text-avatar-n-droguerias {
    color: #40435b;
    text-align: center;

    font-family: SourceSansProRegular;

    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 150% */
}
.line-divider {
    margin: 8px 0 !important;
}

.avatar-component-all_d{
    width: 107px;
    height: 24px;

    border-radius: 999px;
    border: 0.5px solid #e0e1e9;
    background: #fff;

    box-shadow: 0px 1px 2px 0px rgba(64, 67, 91, 0.06), 0px 1px 3px 0px rgba(64, 67, 91, 0.1);

    color: #40435b;
    text-align: center;

    font-family: SourceSansProRegular;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}

.avatars-unassigned-distribution {
    width: 32px !important;
    height: 32px !important;
    border-radius: 999px;
    border: 1px solid #E0E1E9;
    background: #E0E1E9;
    /* xs */
    box-shadow: 0px 1px 2px 0px rgba(64, 67, 91, 0.06), 0px 1px 3px 0px rgba(64, 67, 91, 0.1);

    color: #40435B;
    text-align: center;

    font-family: SourceSansProRegular;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    text-transform: uppercase;
}