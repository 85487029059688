.container {
    width: 370px;
}

.container > div > div {
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.header > span {
    display: none;
}

.title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;
    text-underline-position: from-font;
    color: #40435B;
}

.body {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    text-underline-position: from-font;
    color: #6B6F93;
}

.footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}