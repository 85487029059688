.modal {
    border-radius: 10px;
    width: 413px;
    height: auto;
    gap: 32px;
}
.modal-header {
    padding-right: 0;
    margin-top: 0px !important;
    margin-bottom: 8px !important;
}
.modal-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.body-modal {
    margin-top: 0px !important;
    padding-bottom: 16px !important;
    display: flex;
}
.title-modal {
    color: #40435b;
    font-family: SourceSansProSemiBold;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 122.222% */
}
.title-modal-drugmanufacturer {
    color: var(--Neutral-800, #40435b);
    font-feature-settings: 'clig' 0, 'liga' 0;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 128.571% */
}
.subtitle-modal {
    color: #6b6f93;
    font-family: SourceSansProRegular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    align-self: stretch;
}
.discount-container {
    display: flex;
    width: 100%;
    padding: 8px 16px 8px 0;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.5px solid var(--Neutral-100, #e0e1e9);
}
.volume-discount-quantity-text {
    color: var(--Neutral-White, #fff);
    font-feature-settings: 'clig' 0, 'liga' 0;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 128.571% */
}
.background-volume-discount-quantity {
    display: flex;
    width: 70px;
    padding: 2px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    background: var(--Discount-Discount, #ff6300);
}
.quantity-text-container {
    display: flex;
    align-items: center;
    gap: 12px;
}
.apply-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1 0 0;
}
.text-applied {
    color: var(--Semantic-Success-600, #07ad4b);
    font-feature-settings: 'clig' 0, 'liga' 0;
    font-family: 'Source Sans Pro';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
}
