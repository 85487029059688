.container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.container-chart {
    margin: 0;
    display: flex;
    align-items: center;
    z-index: 999;
}

.span-complete-order {
    font-size: 14px;
    color: #333333;
    font-weight: 500;
    padding: 0px,
}

.span-complete-order-bolder {
    font-size: 14px;
    color: #333333;
    font-weight: 700;
    margin-left: 4px;
}