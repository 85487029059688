/* CDO */
.cell-cdo {
    padding: 0px 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.button-request-cdo {
    display: flex;
    height: 34px;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid var(--Primary-600, #0171e6);
    background: var(--Neutral-White, #fff);
}
.button-request-cdo:hover {
    background-color: #dcedff;
}
.button-request-cdo > p {
    color: var(--Primary-600, #0171e6);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}
.isRequestedText {
    color: #0171e6;
    text-align: center;
    font-feature-settings: clig off, liga off;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
}
