.container-list {
  padding: 10px;
}

.list-items {
  padding: 16px 0px !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.wrapper-menu-item:hover {
  background-color: #EBF5FF;
}

.wrapper-menu-item:hover .button-add-item {
  background-color: #0171E6 !important;
  color: #fff !important;
}

.wrapper-menu-item {
  display: flex;
  align-items: center;
  height: 60px;
}

.container-image-text-menu {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.wrapper-text-menu-item {
  margin: 0 16px;
}

.wrapper-button-menu-item {
  display: flex;
  justify-content: flex-end;
}

.button-add-item {
  background-color: #DCEDFF;
  color: #0171E6 !important;
  padding: 8px 24px;
}

.button-add-item:hover,
.button-add-item:focus,
.button-add-item:active {
  background-color: #DCEDFF !important;
  color: #0171E6 !important
}

.text-button-item {
  padding-left: 8px;
}

.container-panel {
  margin-top: 2px;
  height: 715px;
  border-radius: 6px;
  box-shadow: 0px 2px 4px -1px rgba(64, 67, 91, 0.06), 0px 4px 5px -1px rgba(64, 67, 91, 0.10) !important;
}

.backdrop {
  opacity: 0.5;
  background: var(--neutral-alpha-500, rgba(172, 174, 196, 0.64));
  backdrop-filter: blur(1px);
}

.container-panel>div {
  box-shadow: none;
  height: 100%;
}

.wrapper-row {
   height: auto; 
  overflow-y: scroll
}

.wrapper-row::-webkit-scrollbar {
  /* Ancho de la barra de desplazamiento */
  width: 5px;
}

.wrapper-row::-webkit-scrollbar-thumb {
  /* Establece el color de fondo de la barra de desplazamiento en transparente */
  background: rgba(172, 174, 196, 1);
  /* Elimina el borde de la barra de desplazamiento */
  border: none;
  /* Elimina el radio de la barra de desplazamiento */
  border-radius: 0;
}

/* Establece el color de fondo de la pista de la barra de desplazamiento en transparente */
.wrapper-row::-webkit-scrollbar-track {
  background: transparent;
}


.container-quantity-item {
  padding-left: 16px;
  padding-bottom: 8px ;
}

.quantity-items {
  color: #ACAEC4;
  font-family: SourceSansProRegular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.wrapper-paragraph {
  width: 20% !important;
  padding: 0px;
}

.liner-divider{
  margin-top: 0px !important;
}

.panel-header{
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
  overflow: unset;
}