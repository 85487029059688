.line-divider{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 16px;
  background-color: #ACAEC4;
  opacity: 65%;
  margin-bottom: 1px;
  width: 1px;
}
.line-divider hr {
  width: 2px; 
  height: 100%; 
  margin: 0;
  border: none; 
}
.line-divider-blur{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  opacity: 0.9;
  background: linear-gradient(90deg, #FFF 44.92%, rgba(255, 255, 255, 0.00) 51.07%, rgba(255, 255, 255, 0.00) 78.95%, #FFF 81.9%);
}

.line-divider-blur hr {
  width: 2px; 
  height: 100%; 
  margin: 0;
  border: none; 
}
.col-container-left{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.col-container-rigth{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}