.container-pagination {
  margin-top: 16px;
}

.pagination {
  font-family: SourceSansProRegular;
}

.pagination div div span span:last-child {
  display: none !important;
}

.pagination div div div[role='combobox'] {
  width: 80px !important;
  display: flex !important;
  justify-content: center !important;
  background-color: #f5f6fa !important;
  font-family: SourceSansProRegular;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  color: #40435b;
}
.pagination div div div[role='combobox'] div div svg {
  color: #40435b !important;
}

.wrapper-pagination {
  display: flex;
  justify-content: flex-start;
}
.menu {
  width: 100%;
}
.text-seeing {
  color: var(--neutral-700, var(--700, #6B6F93));
  display: flex;
  align-items: center;
  padding: 0 16px;
  font-family: SourceSansProRegular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.select-picker-container {
  width: 79px;
}
.select-picker-container div[role='combobox'] {
  height: 32px;
  font-family: SourceSansProRegular;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  color: #40435b;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left:  16px !important;
  padding-right: 8px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border-radius: 8px;
  background-color: #f5f6fa !important;
}
.select-picker-container div[role='combobox'] div div:nth-child(2) {
  padding-left: 16px !important;
  padding-right: 8px !important;
}
.select-picker-container div[role='combobox']  div div svg {
  display: flex;
}