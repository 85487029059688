.wrapper-content {
    height: 100vh !important;
    width: calc(100% - 70px) !important;
    right: 0 !important;
    top: 0;
}

.wrapper-content-large {
    height: 100vh !important;
    width: calc(100% - 70px) !important;
    right: 0 !important;
    top: 0;
}

.wrapper-content > div > div {
    padding: 0px 48px 16px 48px;
    background: linear-gradient(180deg, rgba(220, 237, 255, 0.5) 0%, #fff 100%), #fff;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

.wrapper-content-large > div > div {
    padding: 0px 48px 16px 48px;
    background: linear-gradient(180deg, rgba(220, 237, 255, 0.5) 0%, #fff 100%), #fff;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

.wrapper-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width: calc(100% - 95px);
    min-width: 760px;
    height: 56px;
    z-index: 1000;
    border-bottom: 1.5px solid #acaec4;
    background-color: aliceblue;
}

.header {
    color: #0b2033;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Source Sans Pro;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
}

.wrapper-body {
    width: 760px;
    margin: 0 auto;
    margin-top: 80px;
    display: flex;
    padding: 32px 40px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    background: #fff;
    box-shadow: 0px 2px 4px -1px rgba(64, 67, 91, 0.06), 0px 4px 5px -1px rgba(64, 67, 91, 0.1);
}

.sections-title {
    color: #1e334a;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
}

.wrapper-body p:not(.sections-title) {
    color: #1e334a;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.sections-body {
    text-align: justify;
}
