/* FOOTER */
.wrapper-footer {
  padding: 8px 48px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  background: rgba(255, 255, 255, 0.80);
}

.wrapper-footer div {
  width: 100%;
  display: flex;
  align-items: center;
}

.wrapper-footer-blur{
  width: calc(100vw - 72px) !important;
  height: 46px;
  position: absolute;
  z-index: 99999;
  bottom: 0;
  background-color: rgba(64, 67, 91, 0.9);
}
.wrapper-footer>div>div:first-child {
  gap: 24px;
  justify-content: flex-start;
}

.wrapper-footer>div>div:last-child {
  gap: 16px;
  justify-content: flex-end;
}

.wrapper-footer div a {
  margin: 0;
  color: #6B6F93;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  cursor: pointer;
  text-decoration: none;
}

.wrapper-footer-expanded {
  padding: 8px 48px;
  /* height: 93px !important; */
  /* margin-top: -93px !important;  */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -30px 30px -15px rgba(193, 206, 218, 0.30);
}

.wrapper-footer-expanded div {
  width: 100%;
  display: flex;
  align-items: center;
}

.wrapper-footer-expanded>div>div:first-child {
  gap: 24px;
  justify-content: flex-start;
}

.wrapper-footer-expanded>div>div:last-child {
  gap: 16px;
  justify-content: flex-end;
}

.wrapper-footer-expanded div a {
  margin: 0;
  color: #6B6F93;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  cursor: pointer;
  text-decoration: none;
}

.is-active {
  color: #0171E6;
}

.wrapper-footer-address {
  display: flex;
  gap: 8px !important;
}

.wrapper-footer-address p {
  color: #ACAEC4;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin: 0;
}

.wrapper-footer-section-bottom {
  /* ANIMATION */
  animation-duration: .3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-name: slideInRight;
}

@keyframes slideInRight {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}