.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  padding: 8px 16px;
  pointer-events: initial;
}
.container:hover {
  background-color: #ebf5ff;
}
.container svg {
  margin-right: 16px;
  color: #acaec4;
}

.container div p {
  color: #40435b;
  font-family: SourceSansProRegular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-transform: capitalize;
}

.container-hover {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  padding: 8px 16px;
  background-color: #ebf5ff;
}

.container-hover svg {
  margin-right: 16px;
  color: #acaec4;
}

.container-hover div p  {
  color: #40435b;
  font-family: SourceSansProRegular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-transform: capitalize;
}