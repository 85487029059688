.container {
    display: flex;
    width: 56px;
    height: 56px;
    justify-content: center;
    align-items: center;
    border-radius: 80px;
    background: var(--Primary-10, #ebf5ff);
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease;
}

.container-complete {
    background-color: var(--Primary-600, #0171e6);
    animation: fillBackground 1s forwards;
}

/* ANIMATION SVG */
.last-complete > svg {
    animation: fadeIn 0.7s ease-in-out;
}

.container-complete > svg {
    animation: fadeIn 0.7s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* LINE */
/* default */
.line {
    width: 112px;
    height: 8px;
    flex-shrink: 0;
    border-radius: 8px;
    background: var(--Primary-25, #dcedff);
}
/* in-progress */
.inProgress {
    background: linear-gradient(90deg, #dcedff 10.02%, #0f7ff4 50%, #dcedff 89.98%);
    animation: moveBackground 1s linear infinite; /* Configuramos la animación */
}
/* is-complete */
.isComplete {
    background: var(--Primary-600, #0171e6);
}

@keyframes moveBackground {
    0% {
        background-position: -112px 0;
    }
    100% {
        background-position: 112px 0;
    }
}

@keyframes fillBackground {
    from {
        background-position: -100% 0;
    }
    to {
        background-position: 100% 0;
    }
}

.fade-out-right {
    -webkit-animation: fade-out-right 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: fade-out-right 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes fade-out-right {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(50px);
        transform: translateX(50px);
        opacity: 0;
    }
}

@keyframes fade-out-right {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(50px);
        transform: translateX(50px);
        opacity: 0;
    }
}

.fade-in-right {
    -webkit-animation: fade-in-right 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: fade-in-right 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    min-height: 56px;
    min-width: 56px;
    border-radius: 80px;
    background: var(--Semantic-Success-600, #07ad4b);
    z-index: 2;
}

@-webkit-keyframes fade-in-right {
    0% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(-184px) scale(1);
        transform: translateX(-184px) scale(1);
        height: 72px;
        width: 72px;
        opacity: 1;
    }
}

@keyframes fade-in-right {
    0% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(-184px) scale(1);
        transform: translateX(-184px) scale(1);
        height: 72px;
        width: 72px;
        opacity: 1;
    }
}

@-webkit-keyframes expandCircle {
    0% {
        width: 72px;
        height: 72px;
        border-radius: 50%;
        background: var(--Semantic-Success-100, #ddf6e2);
        transform: scale(1);
    }
    100% {
        width: 72px; /* Mantén el tamaño original */
        height: 72px;
        border-radius: 50%;
        background: var(--Semantic-Success-100, #ddf6e2);
        transform: scale(100); /* Incrementa el factor de escala para que crezca en todas direcciones */
    }
}

@keyframes expandCircle {
    0% {
        width: 72px;
        height: 72px;
        border-radius: 50%;
        background: var(--Semantic-Success-100, #ddf6e2);
        transform: scale(1);
    }
    100% {
        width: 72px; /* Mantén el tamaño original */
        height: 72px;
        border-radius: 50%;
        background: var(--Semantic-Success-100, #ddf6e2);
        transform: scale(100); /* Incrementa el factor de escala para que crezca en todas direcciones */
    }
}

.expanding-circle {
    position: fixed;
    width: 72px;
    height: 72px;
    background: var(--Semantic-Success-100, #ddf6e2);
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(1);
    animation: expandCircle 1s ease-out forwards;
    z-index: 1;
}

.failed-icon {
    border-radius: 80px;
    border: 4px solid var(--Semantic-Error-100, #f8cbcb);
    background: var(--Semantic-Error-600, #e22e2e);
}

.heartbeat {
    -webkit-animation: heartbeat 0.7s ease-in-out 0s 1 both;
    animation: heartbeat 0.7s ease-in-out 0s 1 both;
    width: 72px !important;
    height: 72px !important;
    transform: translateX(-184px);
    border: 4px solid var(--Semantic-Error-100, #ddf6e2);
}

@-webkit-keyframes heartbeat {
    from {
        -webkit-transform: translateX(-184px) scale(1);
        transform: translateX(-184px) scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        border: 4px solid var(--Semantic-Error-100, #ddf6e2);
    }
    10% {
        -webkit-transform: translateX(-184px) scale(1.2);
        transform: translateX(-184px) scale(1.2);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        border: 4px solid var(--Semantic-Error-100, #ddf6e2);
    }
    17% {
        -webkit-transform: translateX(-184px) scale(1.3);
        transform: translateX(-184px) scale(1.3);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        border: 4px solid var(--Semantic-Error-100, #ddf6e2);
    }
    33% {
        -webkit-transform: translateX(-184px) scale(1.2);
        transform: translateX(-184px) scale(1.2);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        border: 4px solid var(--Semantic-Error-100, #ddf6e2);
    }
    45% {
        -webkit-transform: translateX(-184px) scale(1);
        transform: translateX(-184px) scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        border: 4px solid var(--Semantic-Error-100, #ddf6e2);
    }
}

@keyframes heartbeat {
    from {
        -webkit-transform: translateX(-184px) scale(1);
        transform: translateX(-184px) scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        border: 4px solid var(--Semantic-Error-100, #ddf6e2);
    }
    10% {
        -webkit-transform: translateX(-184px) scale(1.2);
        transform: translateX(-184px) scale(1.2);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        border: 4px solid var(--Semantic-Error-100, #ddf6e2);
    }
    17% {
        -webkit-transform: translateX(-184px) scale(1.3);
        transform: translateX(-184px) scale(1.3);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        border: 4px solid var(--Semantic-Error-100, #ddf6e2);
    }
    33% {
        -webkit-transform: translateX(-184px) scale(1.2);
        transform: translateX(-184px) scale(1.2);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        border: 4px solid var(--Semantic-Error-100, #ddf6e2);
    }
    45% {
        -webkit-transform: translateX(-184px) scale(1);
        transform: translateX(-184px) scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        border: 4px solid var(--Semantic-Error-100, #ddf6e2);
    }
}

.fade-out {
    -webkit-animation: fade-out 0.2s ease-out 0s both;
    animation: fade-out 0.2s ease-out 0s both;
}

@-webkit-keyframes fade-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@keyframes fade-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
