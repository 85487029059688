/* LAST CELL PADDING ADD WHEN NOT SHOW CDO */
.cell [class^='CellStyles_price-section'] {
    padding-right: 16px;
}

.cell-container {
    /* min-width: 146px; */
    width: var(--cell-max-width);
    /* border-left: 0.5px solid var(--Secondary-300, #ffcdad); */
}

/* CLOSE RECOMMENDATIONS */
.limit-recommendation-close {
    text-align: center;
    position: absolute;
    right: 5vw;
}

.limit-recommendation-close > p {
    font-size: 14px;
    color: rgb(1, 113, 230);
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.limit-recommendation-close img {
    color: rgb(1, 113, 230);
}