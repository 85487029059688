.cell-price {
    height: 82px;
    border-left: 0.5px solid var(--Secondary-300, #bed0e5);
    background: var(--Secondary-25, #f0f5ff);
    box-shadow: 0px 4px 5px -1px rgba(64, 67, 91, 0.1), 0px 2px 4px -1px rgba(64, 67, 91, 0.06);
    min-width: 146px;
    width: var(--cell-max-width);
}

/* CDO */
.cell-cdo {
    height: 82px;
    min-width: 146px;
    border-left: 0.5px solid var(--Secondary-300, #bed0e5);
    box-shadow: 0px 1px 3px 0px rgba(64, 67, 91, 0.1), 0px 1px 2px 0px rgba(64, 67, 91, 0.06);
    width: var(--cell-max-width);
    background: var(--Primary-50, #badbff);
    position: sticky;
    top: 0;
    right: 0;
}

.cell-price-generics {
    height: 82px;
    background: var(--Secondary-25, #f0f5ff);
    box-shadow: 0px 4px 5px -1px rgba(64, 67, 91, 0.1), 0px 2px 4px -1px rgba(64, 67, 91, 0.06);
    min-width: 146px;
    width: var(--cell-max-width);
}

.cell-price-generics:nth-last-child() {
    border-bottom-right-radius: 8px;
}
