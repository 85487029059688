/* SET VALUES DEFAULT  */
.custom-input-number input {
    text-align: center;
    overflow: hidden;
    color: var(--neutral-600, #ACAEC4);
    font-feature-settings: 'clig' off, 'liga' off;
    text-overflow: ellipsis;

    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    min-width: 26px !important;
    width: 26px !important;
    padding: 0px !important;
}

.custom-input-number input:focus {
    color: #40435b;
}

.custom-input-number-complete input {
    color: #40435b;
}

.custom-input-number span {
    display: none !important;
}

/* END */

/* WRAPPER PARENT */
.wrapper-counter {
    display: inline-flex;
    height: 40px;
    width: 90px;
    padding: 10px 0px;
    align-items: center;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid var(--neutral-600, #ACAEC4);
    background: var(--neutral-white, #FFF);
}

.wrapper-loader {
    width: 100%;
    text-align: center;
    margin-top: 5px;
}

.wrapper-counter:active {
    color: #000;
}

.wrapper-counter-error {
    border: 1px solid var(--primary-600, #E22E2E) !important;
}

/* END */

/* BUTTONS */
.wrapper-counter button {
    height: 38px;
    width: 30px;
    padding: 8px;
    background: none;
}

.wrapper-counter button:hover {
    background: var(--primary-10, #EBF5FF);
    color: #0171E6;
}

/* BUTTONS */
.wrapper-counter-buttons button, .wrapper-counter-buttons button:first-child, .wrapper-counter-buttons button:last-child {
    color: #0171E6;
}

/* END */