.cell-price {
    min-width: 146px;
    /* width: var(--cell-max-width); */
    height: 54px;
    border-bottom: 0.5px solid var(--Neutral-200, #bed0e5);
    background-color: #fff;
    border-left: 0.5px solid var(--Secondary-300, #bed0e5);
    border-collapse: collapse;
    display: grid;
    grid-template-columns: minmax(102px, 306px) 1fr;
    column-gap: 4px;
    align-items: center;
    padding: 8px 4px;
}

.container-name {
    grid-column: 1 / 2;
    height: 100%;
}

.name {
    text-align: left;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    white-space: normal;
    text-align: left;
    font-family: SourceSansProSemiBold;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}

.button {
    grid-column: 2 / 3;
    display: flex;
    align-items: center;
    justify-content: end;
}