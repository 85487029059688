.container-wrapper-form {
  max-width:240px;
  cursor: pointer !important;
  margin-right: 16px;

}

.date-picker {
  width: 100% !important;
  
}
.wrapper-container {
  position: relative;
  
}
.container-icon {
  position: absolute;
  left: 10px;
  top: 30%;
  z-index: 99;
}

.placeholder-text {
  padding: 0 20px;
  font-family: SourceSansProRegular;
}
.date-picker div[role='combobox'] div div span {
  padding-left: 20px !important;
  color: #40435b;
  font-family: SourceSansProRegular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.date-picker:disabled{
  background-color: white !important;
  opacity: 1 !important;
  color:#ACAEC4 !important;
}

