.wrapper {
    display: flex;
    width: 334px !important;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 32px;
}

.header h4 {
    color: var(--neutral-800, #40435B);
    font-feature-settings: 'clig'off, 'liga'off;
    font-family: Source Sans Pro;
    font-size: 18px;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 22px;
}

.header span {
    display: none;
}

.body {
    margin: 0;
    padding: 0;
    margin-top: 8px;
}

.body p {
    color: var(--neutral-700, var(--700, #6B6F93));
    font-feature-settings: 'clig'off, 'liga'off;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.buttons {
    margin-top: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttons button:last-child {
    display: flex;
    height: 48px;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: var(--Primary-600, #0171E6);
}



.buttons span {
    font-feature-settings: 'clig'off, 'liga'off;
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.buttons button:first-child span {
    color: var(--Primary-600, #0171E6);
    font-feature-settings: 'liga'off, 'clig'off;
    /* Button/Button 1 */
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 125% */
}

.buttons button:last-child span {
    color: var(--neutral-white, #FFF);
    display: flex;
    height: 40px;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
}