.cell {
    height: 54px;
    border-radius: 0px 8px 8px 0px;
    display: grid;
    column-gap: 4px;
    align-items: center;
    border-bottom: 0.5px solid var(--Neutral-200, #acaec4);
    border-left: 0.5px solid var(--Secondary-300, #bed0e5);
    box-shadow: 0px 1px 3px 0px rgba(64, 67, 91, 0.1), 0px 1px 2px 0px rgba(64, 67, 91, 0.06);
    min-width: 146px;
    width: var(--cell-max-width);
    grid-template-columns: minmax(102px, 306px) 1fr;
}

.container-name {
    grid-column: 1 / 2;
    display: grid;
    padding-left: 8px;
    grid-template-columns: 16px 1fr;
    column-gap: 8px;
    height: 100%;
}

.icon-cdo {
    grid-column: 1 / 2;
}

.name {
    grid-column: 2 / 3;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    white-space: normal;
    text-align: left;
}

.button {
    grid-column: 2 / 3;
    display: flex;
    align-items: center;
    justify-content: end;
}

.icon {
    display: flex;
    width: 24px;
    max-width: 24px;
    height: 24px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    padding-right: 8px;
}
