.middle-without-cdo::-webkit-scrollbar {
    display: none;
}

.middle-without-cdo>div:nth-child(even) {
    background-color: #fff;
}

.middle-without-cdo>div:nth-child(odd) {
    background-color: #f0f5ff;
}

.middle::-webkit-scrollbar {
    display: none;
}

.middle>div:nth-child(even):not(:last-child) {
    background-color: #fff;
}

.middle>div:nth-child(odd):not(:last-child) {
    background-color: #f0f5ff;
}

.row-container {
    border-radius: 8px;
    border-top: 0.5px solid var(--Secondary-300, #bed0e5);
    border-bottom: 0.5px solid var(--Secondary-300, #bed0e5);
    border-left: 0.5px solid var(--Secondary-300, #bed0e5);
    border-right: 0.5px solid var(--Secondary-300, #bed0e5);
    overflow: hidden;
    box-shadow: 0px 1px 3px 0px rgba(64, 67, 91, 0.1), 0px 1px 2px 0px rgba(64, 67, 91, 0.06);
}

.row-container-expanded {
    border-radius: 8px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top: 0.5px solid var(--Secondary-300, #bed0e5);
    border-bottom: 0.5px solid var(--Secondary-300, #bed0e5);
    border-left: 0.5px solid var(--Secondary-300, #bed0e5);
    border-right: 0.5px solid var(--Secondary-300, #bed0e5);
    overflow: hidden;
    box-shadow: 0px 1px 3px 0px rgba(64, 67, 91, 0.1), 0px 1px 2px 0px rgba(64, 67, 91, 0.06);
}

.row-container:hover>div:first-child [class^='CellProduct_cell_']>[class^='CellProduct_product_']>div:first-child {
    display: block;
}

.row-container:hover>div:first-child[class^='CellProduct_cell_']:not([class*='CellProduct_cell-error']) {
    background-color: #f0f5ff;
}

.row-container:hover>div:last-child[class^='Row_middle']>div:not(:last-child):not([class^='CellWrapper_cell-cdo__']) {
    background-color: #f0f5ff;
}

.row-container:hover [class^='CellProduct_trash-icon_'] {
    display: block;
}

.row-generics-container {
    overflow: hidden;
    background: #F5F6FA;
}

.row-generics-container:first-child {
    background: linear-gradient(0deg, rgba(245, 246, 250, 0.15) 79%, rgba(188, 188, 188, 0.15) 93.5%, rgba(108, 108, 108, 0.15) 99.5%), #F5F6FA;
}

.row-generics-container>div:first-child>div>div:nth-child(n+1)>div[class^='CellStyles_cell-price-container'] {
    background: linear-gradient(0deg, rgba(245, 246, 250, 0.15) 79%, rgba(188, 188, 188, 0.15) 93.5%, rgba(108, 108, 108, 0.15) 99.5%), #F5F6FA;
}

.row-generics-container>div>div>div:nth-child(n+1)>div[class^='CellStyles_cell-price-container'] {
    background: #F5F6FA;
    border-right: 0.5px solid var(--Secondary-300, #bed0e5);
    border-bottom: 0.5px solid var(--Secondary-300, #bed0e5);
}

.row-generics-container>div:last-child>div>div:last-child>div[class^='CellStyles_cell-price-container'] {
    border-bottom-right-radius: 8px;
}

.row-generics-container:last-child>div>div {
    border-bottom-left-radius: 8px;
}

.row-generics-container:last-child {
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
}

/*.row-container > div:nth-child(n+1){
    border-top: 0.5px solid var(--Secondary-300, #ACAEC4);
    border-bottom: 0.5px solid var(--Secondary-300, #ACAEC4);
    border-left: 0.5px solid var(--Secondary-300, #ACAEC4);
    border-right: 0.5px solid var(--Secondary-300, #ACAEC4);
}*/

.row-container:hover>div:first-child [class^='CellProduct_cell_']>[class^='CellProduct_product_']>div:first-child {
    display: block;
}

.row-container:hover>div:first-child[class^='CellProduct_cell_']:not([class*='CellProduct_cell-error']) {
    background-color: #f0f5ff;
}

.row-container:hover>div:last-child[class^='Row_middle']>div:not(:last-child):not([class^='CellWrapper_cell-cdo__']) {
    background-color: #f0f5ff;
}

.row-container:hover [class^='CellProduct_trash-icon_'] {
    display: block;
}

/* GENERICS */
.fade-in {
    opacity: 0;
    /* Empieza con opacidad cero */
    transition: opacity 0.4s ease-in-out;
    /* Transición de opacidad durante 0.4 segundos */
}

.fade-in.visible {
    opacity: 1;
    /* Cuando se añade la clase 'visible', la opacidad es 1, mostrando el contenido */
}

.middle-generics {
    background: linear-gradient(0deg,
            rgba(245, 246, 250, 0.15) 79%,
            rgba(188, 188, 188, 0.15) 93.5%,
            rgba(108, 108, 108, 0.15) 99.5%),
        #f5f6fa;
}

.middle-generics::-webkit-scrollbar {
    display: none;
}

/* END GENERICS */

.row-generics {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 1px 3px 0px rgba(64, 67, 91, 0.1), 0px 1px 2px 0px rgba(64, 67, 91, 0.06);
    background: linear-gradient(0deg,
            rgba(245, 246, 250, 0.15) 79%,
            rgba(188, 188, 188, 0.15) 93.5%,
            rgba(108, 108, 108, 0.15) 99.5%),
        #f5f6fa;
}

/* FIX SCROLLBAR  */
.middle, .middle-without-cdo, .middle-generics {
    scrollbar-width: none;
    /* hide horizontal scrollbar in Firefox */
}

/* ----------------------------------------------
 * Generated by Animista on 2024-8-15 15:51:14
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-blurred-right
 * ----------------------------------------
 */
 @-webkit-keyframes slide-out-blurred-right {
    0% {
      -webkit-transform: translateX(0) scaleY(1) scaleX(1);
              transform: translateX(0) scaleY(1) scaleX(1);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      -webkit-filter: blur(0);
              filter: blur(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(1000px) scaleX(2) scaleY(0.2);
              transform: translateX(1000px) scaleX(2) scaleY(0.2);
      -webkit-transform-origin: 0% 50%;
              transform-origin: 0% 50%;
      -webkit-filter: blur(40px);
              filter: blur(40px);
      opacity: 0;
    }
  }
  @keyframes slide-out-blurred-right {
    0% {
      -webkit-transform: translateX(0) scaleY(1) scaleX(1);
              transform: translateX(0) scaleY(1) scaleX(1);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      -webkit-filter: blur(0);
              filter: blur(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(1000px) scaleX(2) scaleY(0.2);
              transform: translateX(1000px) scaleX(2) scaleY(0.2);
      -webkit-transform-origin: 0% 50%;
              transform-origin: 0% 50%;
      -webkit-filter: blur(40px);
              filter: blur(40px);
      opacity: 0;
    }
  }
  

.slide-out-blurred-right {
	-webkit-animation: slide-out-blurred-right 0.35s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
	        animation: slide-out-blurred-right 0.35s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
}