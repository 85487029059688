
/* Estilo para el contenedor del tooltip */
.tooltip-container {
    position: relative;
    display: inline-block;
}

/* Estilo para el contenido del tooltip */
.tooltip-content {
    opacity: 0; /* Inicialmente oculto */
    transform: scale(0.8); /* Reducir el tamaño para que esté oculto */
    background-color: #0B2033;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 5px 10px;
    position: absolute;
    z-index: 1;
    top: calc(100% + 2px); /* Cambiamos a 'bottom' */
    left: 0%;
    white-space: nowrap;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Source Sans Pro' !important;
    transition: opacity 0.3s ease, transform 0.3s ease; /* Transiciones suaves */
}

/* Estilo para el gatillo del tooltip */
.tooltip-trigger:hover + .tooltip-content {
    opacity: 1; /* Hacer visible al hacer hover */
    transform: scale(1); /* Escalar de nuevo a su tamaño original */
}

/* Estilo para la flecha del tooltip */
.tooltip-content::before {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 0 6px 6px 6px;
    border-color: transparent transparent #0B2033 transparent;
    bottom: 100%; /* La base de la flecha en la línea superior del tooltip */
    left: calc(50% - 6px);
}
.date-picker {
    max-width:120px;
    min-width: 80px;
    width: auto;
}
.date-picker div[role="combobox"]{
    padding-right: 0 !important;
    background-color: transparent !important;
}

.date-picker div[role="combobox"]:hover, .date-picker div[role="combobox"]:focus, .date-picker div[role="combobox"]:active {
   background-color: transparent !important;
   text-decoration: underline !important;
   text-decoration-color: #0171e6 !important;
   background: none !important;
   cursor: pointer;
  
}

.date-picker div[role="combobox"] div input{
    color: var(--Primary-600, #0171e6) !important;

    /* Button/Button 2 */
    font-family: 'Source Sans Pro' !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 18px !important; /* 128.571% */
    padding-right: 0px !important;
    height: 98% !important;

}

.date-picker div[role="combobox"] div span {
    color: var(--Primary-600, #0171e6) !important;

    /* Button/Button 2 */
    font-family: 'Source Sans Pro' !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 18px !important; /* 128.571% */
}

.date-picker div svg{
    display: none !important;
}