.container {
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0px 16px;
}

.wrapper-title {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  justify-content: center !important;
  height: 84px !important;
  padding-bottom: 24px !important;
  gap: 8px !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.wrapper-left {
  display: flex;
  align-items: center;
  gap: 16px;
  height: 32px;
}

.button-expanded {
  width: 32px;
  height: 32px;
  background-color: transparent;
}

.button-expanded:hover,
.button-expanded:focus,
.button-expanded:active {
  background-color: #f2f7ff !important;
}

.wrapper-empty-text {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  height: 20px;
}

.empty-text {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: rgba(1, 113, 230, 1);
  text-decoration: underline;
  cursor: pointer;
  padding-left: 2px;
}

.cart {
  color: #40435b;
  font-size: 18px;
  font-weight: 600;
}

.quantity {
  color: #ff6300;
  font-size: 16px;
  font-weight: 600;
}

.go-to-back {
  display: flex;
  align-items: center;
  height: 24px;
  max-width: 200px;
  margin-top: 1px;
  border-radius: 99px;
  border: 0.5px solid var(--primary-50, #badbff);
  background: var(--semantic-information-50, #f2f7ff) !important;
  font-weight: 600;
  padding: 4px 8px !important;
}

.wrapper-button-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.drugManufacturer-name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
