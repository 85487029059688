.cell {
    min-width: 306px;
    height: 54px;
    border-radius: 8px 0px 0px 8px;
    border-bottom: 0.5px solid #bed0e5;
    background: #fff;
    box-shadow: 0px 1px 3px 0px rgba(64, 67, 91, 0.1), 0px 1px 2px 0px rgba(64, 67, 91, 0.06);
    text-align: start;
    display: grid;
    align-items: center;
    padding-left: 8px;

    color: #40435b;

    font-family: SourceSansProSemiBold;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.cell > p {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.label {
    color: #6b6f93;

    font-family: SourceSansProRegular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;

    padding-left: 8px;
}

.skeleton {
    width: 60px !important;
}

.skeleton > div > p {
    width: 60px !important;
}
