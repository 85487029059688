.wrapper-menu-item {
  display: flex;
  align-items: center;
}

/* add padding */
.container-row {
  padding: 8px 16px;
}

.container-row:hover {
  background-color: #EBF5FF !important;
}
.container-row-hover {
  padding: 8px 16px;
  background-color: #EBF5FF !important;
}
.container-row-hover .button-add-item {
  background-color: #0171E6 !important;
  color: #fff !important;
}
.container-row:hover .button-add-item {
  background-color: #0171E6 !important;
  color: #fff !important;
}

.container-image-text-menu {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.wrapper-text-menu-item {
  margin: 0 16px;
}

.wrapper-button-menu-item {
  display: flex;
  justify-content: flex-end;
}

.button-add-item {
  background-color: #DCEDFF;
  color: #0171E6 !important;
  padding: 10px 24px;
  width: 122px;
  height: 40px;
}

.button-add-item:hover,
.button-add-item:focus,
.button-add-item:active {
  background-color: #DCEDFF !important;
  color: #0171E6 !important
}

.text-button-item {
  padding-left: 8px;
}

.wrapper-loader {
  text-align: center;
  margin-top: 5px;
}

/* styles by counter box */
/* SET VALUES DEFAULT  */
.custom-input-number input {
  text-align: center;
  overflow: hidden;
  color: var(--neutral-800, #40435B);
  font-feature-settings: 'clig' off, 'liga' off;
  text-overflow: ellipsis;

  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  min-width: 40px !important;
  padding: 0px !important;
}

.custom-input-number input:focus {
  color: #000;
}

.custom-input-number span {
  display: none !important;
}

/* END */

/* WRAPPER PARENT */
.wrapper-counter {
  display: inline-flex;
  height: 40px;
  width: 122px;
  padding: 10px 0px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--neutral-600, #ACAEC4);
  background: var(--neutral-white, #FFF);
}

.wrapper-loader {
  width: 100%;
  text-align: center;
  margin-top: 5px;
}

.wrapper-counter:active {
  color: #000;
}

.wrapper-counter-error {
  border: 1px solid var(--primary-600, #E22E2E) !important;
}

/* END */

/* BUTTONS */
.wrapper-counter button {
  height: 38px;
  width: 40px;
  padding: 8px;
  background: none;
}

.wrapper-counter button:hover {
  background: var(--primary-10, #EBF5FF);
}

/* SUBS */
.wrapper-counter button:first-child {
  color: #6B6F93;
}

/* ADD */
.wrapper-counter button:last-child {
  color: #0171E6;
}

/* END */

.verticalBar{
  border-left: 1px solid #acaec4; 
    height: 12px; 
    margin: 0 10px;
}
.container-text{
  display: inline-flex;
  align-items: center;
}
.container-image {
  border-radius: 8px;
  border: 0.5px solid #e0e1e9;
  background: #fff;
  padding: 4px;
}