.container-dropdown-button button {
    background-color: transparent !important;
    padding: 2px 8px !important;
}

.hour-configuration:hover {
    text-decoration: underline;
    cursor: pointer;
}
.hour-configuration {
    color: var(--Primary-600, #0171e6);

    /* Button/Button 2 */
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 128.571% */
}