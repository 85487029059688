.container {
    margin: 0 auto;
    width: 600px;
}
.panel-info {
    border-radius: 8px;
    background: var(--Neutral-White, #fff);

    /* xs */
    box-shadow: 0px 1px 3px 0px rgba(64, 67, 91, 0.1), 0px 1px 2px 0px rgba(64, 67, 91, 0.06);
}
.label {
    color: var(--Neutral-800, #40435b);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Heading/Heading 2 Semibold */
    font-family: 'Source Sans Pro';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 120% */
}

.btn-label {
    color: var(--Primary-600, var(--primary, #0171e6)) !important;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;

    /* Button/Button 1 */
    font-family: 'Source Sans Pro';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 125% */
}

.btn-type {
    border-radius: 8px;
    border: 1px solid var(--Primary-600, #0171e6);
    display: flex;
    height: 62px;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}
