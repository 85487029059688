.container{
    display: flex;
    flex-direction: column; /* Apila los elementos uno debajo del otro */
    align-items: center;    /* Centra los elementos horizontalmente */
    justify-content: center; /* Centra los elementos verticalmente si es necesario */
    text-align: center;     /* Centra el texto */
    padding: 20px;  
}
.text-advice {
    margin-right: 8px;
    color: #fff;
    font-family: 'Source Sans Pro';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5;  
    margin-top: 8px;
    text-align: center;
}