
.container-name {
  grid-column: 1 / 2;
  display: grid;
  height: 100%;
}

.name {
  display: flex;
  align-items: center;
  gap: 2px;
  flex: 1 0 0;
  color: #40435B;
  font-size: 16px;
}

.name > span {
  word-break: break-all; /* fix ellipsis */
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  white-space: normal;
  text-align: left;
  font-family: SourceSansProSemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.container-image-text-cdo{
  display: flex;
  align-items: center;
}

.icon-cdo{
  width: 16px;
  height: 16px;
}

.name-cdo{
  padding-left: 8px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  white-space: normal;
  text-align: left;
  font-family: SourceSansProSemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  color: #fff !important;
}

.name-disabled {
  color: #40435B;
  text-align: left;
  font-size: 16px;
}