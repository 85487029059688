
.container-name {
  grid-column: 1 / 2;
  height: 100%;
}

.name {
  text-align: left;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  white-space: normal;
  font-family: SourceSansProSemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  display: flex;
  align-items: center;
  gap: 2px;
}