/* -------------------CONTAINER------------------- */
.container-counterbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90px;
    height: 32px;

    background: var(--Neutral-White, #fff);
    border-radius: 8px;
    border: 1px solid var(--Neutral-600, #acaec4);
    overflow: hidden;
}

.container-counterbox:hover,
.container-counterbox:focus,
.container-counterbox:active {
    border: 1px solid var(--Neutral-600, #0171e6);
}

/* -----------------------VALUE----------------------- */
.counter-value {
    overflow: hidden;
    color: var(--Neutral-800, var(--800, #40435b));
    text-overflow: ellipsis;

    font-family: SourceSansProRegular;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    outline: none; /*hidden outline in p editable*/
}

/* --------------------MIN-------------------- */
.minus-button {
    width: 24px;
    padding: 8px 4px;
    border-radius: 8px 0px 0px 8px;
    background: var(--Neutral-White, #fff) !important;
    color: #40435b !important;
}

.minus-button:hover,
.minus-button:active {
    background: #dcedff !important;
    color: #0171e6 !important;
}

.minus-button:disabled {
    color: #acaec4 !important;
    background: #f5f6fa !important;
}

/* ------------------ADD------------------ */
.plus-button {
    width: 24px;
    padding: 8px 4px;
    border-radius: 0px 8px 8px 0px;
    background: var(--Neutral-White, #fff) !important;
}

.plus-button:hover,
.plus-button:active {
    background: #dcedff !important;
    color: #0171e6 !important;
}

.plus-button:disabled {
    color: #acaec4 !important;
    background: #f5f6fa !important;
}

/* --------------------DISABLED MODE-------------------- */
.disabled {
    border: 1px solid var(--Neutral-100, #e0e1e9) !important;
    background: var(--Neutral-50, #f5f6fa);
}

.counter-value-disabled {
    color: #acaec4 !important;
}

/* -------------------ERROR------------------------ */
.error {
    border: 1px solid var(--Semantic-Error-600, #e22e2e);
}

.custom-input-number input {
    text-align: center;
    overflow: hidden;
    color: var(--neutral-800, #40435B);
    font-feature-settings: 'clig' off, 'liga' off;
    text-overflow: ellipsis;
  
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    min-width: 40px !important;
    padding: 0px !important;
  }
  
  .custom-input-number input:focus {
    color: var(--Neutral-800, var(--800, #40435b));
  }
  
  .custom-input-number span {
    display: none !important;
  }