.content-wrapper {
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.title-wrapper {
    color: #40435b;
    font-family: SourceSansProBold;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    display: flex;
    justify-content: space-between;
  }
  .title {
    display: flex;
    gap: 8px;
    width: 100%;
  }
  .subtitle {
    color: #6b6f93;
    font-family: SourceSansProRegular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
  }
  .close {
    cursor: pointer;
  }
  .container-buttons {
    padding-top: 16px;
    display: flex !important;
    justify-content: flex-end !important;
  }
  .buttons-update {
    color: #0171e6;
    background-color: transparent !important;
    border: 1px solid #0171e6;
    padding: 8px 12 px;
    border-radius: 8px;
  }
  .buttons-update:hover,
  .buttons-update:active,
  .buttons-update:focus {
    color: #dcedff !important;
    background-color: transparent !important;
  }
  
  .button-toolbar-content {
    gap: 16px !important;
    display: flex;
    justify-content: flex-end;
  }

  .tip-wrapper {
    border: 1px solid #BADBFF;
    display: flex;
    gap: 4px;
    padding: 6px 4px;
    background: #EBF5FF;
    border-radius: 8px;
    margin-top: 8px;

  }

  .tip-label {
    color: #40435B;
    font-size: 12px;
    line-height: 18px;
    font-weight: 600;
  }
  .tip-message {
    color: #6B6F93;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
  }