.button-type{
    width: 130px;
    height: 34px;
}
.button-type-required{
    width: 130px;
    height: 34px;
    color:  #ACAEC4 !important;
}
.button-type span {
    gap:0 !important
}