.select-picker {
  width: 231px;
}
.select-input-list {
  height: 40px;
}
.placeholder-text {
  padding: 0px 5px;
}
.wrapper-container {
  position: relative;
}
.container-icon {
  position: absolute;
  left: 10px;
  top: 30%;
  z-index: 99;
}
.placeholder-text {
  padding: 0 20px !important;
  font-family: SourceSansProRegular;
}

.footer-container {
  padding: 10px 2px;
  border-top: 1px solid #e5e5e5;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-button {
  float: right;
  margin-top: 2;
  margin-right: 8px;
}

.button-clear {
  color: #1dcad3;
  background-color: transparent;
}
.button-clear:hover,
.button-clear:focus,
.button-clear:active {
  color: #1dcad3 !important;
  background-color: transparent !important;
}

.clear-button {
  color: #1dcad3;
  background-color: transparent;
  font-family: SourceSansProRegular;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.clear-button:hover, .clear-button :active, .clear-button:focus {
  color: #1dcad3 !important;
  background-color: transparent !important;
}
