.container-header-popover-notification {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 32px;
}
.container-header-popover-notification p {
    color: rgba(64, 67, 91, 0.9);
    text-align: center;

    font-family: SourceSansProRegular;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
}
.container-header-popover-notification svg {
    margin: 8px;
    width: 16px;
    height: 16px;
    color: #0171E6 !important;
    display: flex;
}

.container-popover-menu {
    padding: 8px 0px !important;
    border-radius: 8px;
    border: 1px solid #e0e1e9;
    background: #fff;
    margin-left: 10px !important;
    /* lg */
    box-shadow: 0px 4px 6px -2px rgba(64, 67, 91, 0.05), 0px 10px 15px -3px rgba(64, 67, 91, 0.1);
}

.container-button {
    background-color: transparent !important;
    width: 32px;
    height: 32px;
    color: #0171E6 !important;
  }
  .container-button:active,
  .container-button:hover,
  .container-button:focus {
    background-color: #dcedff !important;
    color: #0171E6 !important;

}

.dropdown-item{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.dropdown-item li {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 16px !important;
}
.icon-item-dropdown {
  width: 20px !important;
  height: 20px !important;
  margin-right: 8px;
}
