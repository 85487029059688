/* NEW STYLES */
.grid-system {
    grid-column: 1 / 4;
    display: grid;
    grid-template-columns:
        minmax(306px, var(--first-column-max-width))
        auto;
}

.grid-column-drugManufacturers {
    display: grid;
    grid-column: 2 / 3;
    grid-auto-flow: column;
    overflow-x: auto;
    /* scrollbar-width: none; hide horizontal scrollbar in Firefox */
}
