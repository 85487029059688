.title {
  font-size: 14px;
  font-weight: 400;
  color: #40435B;
  display: flex;
  align-items: center;
  top: 3px;
  border-radius: 8px;
  position: relative;
}
