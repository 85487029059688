.header {
    padding: 24px;
    background: linear-gradient(180deg, #0f7ff4 0%, #1759df 100%);
    border-radius: 8px 8px 0px 0px;
}

.title {
    font-size: 20px;
    font-weight: 700;
    color: #ffff;
}

.sub-title {
    font-size: 14px;
    font-weight: 600;
    color: #ffff;
}

.rs-placeholder-paragraph {
    color: red;
    padding: 8px;
}
.delivery-number-container {
   display: flex;
   justify-content: flex-end;
   align-items: center;

   color: var(--Neutral-White, #fff);
}
.label {
   color: var(--Neutral-White, #fff);
   text-align: right;

   /* Caption/Caption 1 */
   font-family: 'Source Sans Pro';
   font-size: 14px;
   font-style: normal;
   font-weight: 400;
   line-height: 20px; /* 142.857% */

   margin-left: 4px;
}
.delivery-number {
   display: flex;
   justify-content: flex-end;
   align-items: center;

   color: var(--Neutral-White, #fff);
}

