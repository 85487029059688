.container {
    margin-top: 40px;
    /* grid-row: 2 / 5; */
    grid-column: 1 / 4;
}

.container-image {
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
}

.container-text {
    text-align: center;
}

.text-title {
    color: #333;
    font-family: SourceSansProSemiBold;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    margin-bottom: 16px;
}

.text-label {
    color: #40435b;
    font-family: SourceSansProRegular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 32px;
}

.button-link {
    color: #0171e6;
    font-family: SourceSansProRegular;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}
