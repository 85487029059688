/* WRAPPER */
.wrapper-notification {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    text-align: center;
    line-height: 50px;
    padding: 8px 24px;
    z-index: 1000;
}

/* PARENT DEFAULT */
.wrapper-notification div {
    border-radius: 8px;
    background: #041526;
    color: #fff;
    display: inline-flex;
    height: 48px;
    min-width: max-content;
    align-items: center;
    justify-content: space-between;
    gap: 48px;
    flex-shrink: 0;
}

/* CONTAINER */
.wrapper-notification div div {
    width: 100%;
    height: 48px;
}

.wrapper-notification div div div {
    width: 100%;
    height: 48px;
}

/* PARAGRAPH LEFT */
.wrapper-notification div div div p {
    color: #fff;
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

/* BUTTON */
.wrapper-notification div div div button {
    font-feature-settings: 'clig' off, 'liga' off;
    background: none !important;
    width: 64px;
}

/* TEXT IN BUTTON */
.wrapper-notification div div div button span {
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: #fff;
    text-decoration: underline;
    text-underline-offset: 5px;
}
