.button-fal {
    min-width: 84px !important;
    width: 84px !important;
    padding: 8px 12px !important;
}

.button-fal-max {
    min-width: 100px !important;
    width: 100px !important;
    padding: 8px 12px !important;
}