.input-group-component {
  max-width: 300px !important;
}

.icon-button-action {
  background-color: transparent !important;
}
.icon-button-action:hover,
.icon-button-action:focus,
.icon-button-action:active {
  background-color: transparent !important;
}

.search-icon {
  color: #0171e6;
}

.input-component {
  height: 32px;
  padding: 10px 16px !important;
  padding-left: 48px !important;
}

.blur-tooltip-container{
  width: 300px;
  height: 34px;
  background: rgba(64, 67, 91, 0.7);
  z-index: 5;
  position: absolute;
  border-radius: 6px;
}