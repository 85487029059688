/* ------------------ error text only password and repeat new password ------------------ */
.error {
    color: var(--Semantic-Error-600, #e22e2e);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}
