.wrapper-card-container-fullblur {
  height: 100%;
  width: 100%;
  background: rgba(64, 67, 91, 0.9);
  position: absolute;
  z-index: 2500;
  padding-top:132px;
  display: flex;
  justify-content: center;
}

.container-card {
  display: flex;
  width: 380px;
  padding: 32px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 8px;
  max-height: 363px;
  transition-delay: 3s;

}
.title {
  margin-top: 32px;
  color: var(--neutral-800, #40435b);
  text-align: center;
  font-family: Source Sans Pro;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.subtitle {
  color: #40435b;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 24px;
}

.close-button {
  font-size: 14px;
  font-style: normal;
  padding: 8px 16px;
  font-weight: 600;
  background-color: #e2ecfe !important;
  color: #1a66fc !important;
}

.close-button:hover {
  background-color: #badbff !important;
}

.config-button {
  font-size: 14px;
  font-style: normal;
  padding: 8px 16px;
  font-weight: 600;
  color: #e2ecfe !important;
  background-color: #1a66fc !important;
}

.config-button:hover {
  background-color: #0062c8 !important;
}
