.modal {
    width: 467px;
    position: relative;
}
.modal div[role='document'] > div:first-child {
    padding: 48px !important;
    border-radius: 10px;
}
.title-modal {
    color: #40435b;
    font-family: SourceSansProSemiBold;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
}
.picture-modal {
    margin-right: 6px;
}
.body-text-modal {
    color: var(--Neutral-800, var(--800, #40435b));
    font-family: SourceSansProRegular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
.text-button-active {
    color: #fff;
    font-family: SourceSansProRegular;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-left: 8px;
}
.action-button-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0171e6 !important;
    color: #fff !important;
    padding: 10px 24px !important;
    overflow: hidden;
    position: relative;
    box-shadow: none !important;
    outline: none !important;
}
.action-button-modal:hover,
.action-button-modal:focus,
.action-button-modal:active {
    background-color: #0062c8 !important;
    color: #fff !important;
    box-shadow: none !important;
    outline: none !important;
}
.action-button-modal span {
    margin-right: 8px !important;
}
.action-button-modal-loading:focus,
.action-button-modal-loading:active {
    box-shadow: none !important;
    outline: none !important;
}
.action-button-modal-loading {
    background: linear-gradient(to left, #0171e6 50%, #0062c8 50%);
    background-size: 200% 100%;
    background-position: 100 bottom;
    animation: fillEffect 3s linear forwards, opacityEffect 3s linear forwards 3s;
    opacity: 1;
    box-shadow: none !important;
    outline: none !important;
}

@keyframes fillEffect {
    to {
        background-position: -100% bottom;
    }
}
@keyframes opacityEffect {
    0% {
        background-color: #0171e6 !important;
        opacity: 1;
    }
    
    99%{
        background-color: #0171e6 !important;
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
.header-modal {
    padding-right: 0px !important;
}
.header-modal span[role='button'] svg {
    width: 24px !important;
    height: 24px !important;
    color: #40435b !important;
}
.body-modal {
    padding-bottom: 24px !important;
    margin-top: 8px !important;
}
.button-close-icon {
    background-color: transparent !important;
    color: #40435b !important;
}

.button-close-icon :active,
.button-close-icon :focus,
.button-close-icon :hover {
    background-color: transparent !important;
    color: #40435b !important;
}
.container-button-close {
    position: absolute;
    top: 8px;
    right: 8px;
}
.success-button-active {
    display: flex;
    justify-content: center;
    background-color: #07ad4b;
    color: #fff;
    padding: 10px 24px !important;
    opacity: 1;
}

.success-button-active:hover,
.success-button-active:active,
.success-button-active:focus {
    background-color: #07ad4b;
    color: #fff;
    padding: 10px 24px !important;
}
