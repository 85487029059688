.container {
    display: flex;
    height: 56px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    align-self: stretch;
}

.percentage {
    color: var(--Neutral-800, #40435b);
    text-align: center;
    font-family: 'Source Sans Pro';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
}

.text {
    color: var(--Neutral-700, #6b6f93);
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.button {
    height: 40px !important;
    color: var(--Neutral-White, #fff);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.hidden {
    display: none;
}

/* Animación de salida hacia la izquierda */
.fade-out-left {
    -webkit-animation: fade-out-left 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: fade-out-left 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes fade-out-left {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(-100px); /* Mover hacia la izquierda */
        transform: translateX(-100px); /* Mover hacia la izquierda */
        opacity: 0;
    }
}

@keyframes fade-out-left {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(-100px); /* Mover hacia la izquierda */
        transform: translateX(-100px); /* Mover hacia la izquierda */
        opacity: 0;
    }
}

/* Animación de entrada desde la derecha */
.fade-in-right {
    -webkit-animation: fade-in-right 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: fade-in-right 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes fade-in-right {
    0% {
        -webkit-transform: translateX(100px); /* Entrar desde la derecha */
        transform: translateX(100px); /* Entrar desde la derecha */
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fade-in-right {
    0% {
        -webkit-transform: translateX(100px); /* Entrar desde la derecha */
        transform: translateX(100px); /* Entrar desde la derecha */
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}
