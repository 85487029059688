.modal {
    width: 520px;
    margin: auto !important;
}

.modal div[role='document'] > div:first-child {
    padding: 48px !important;
    width: 520px !important;
}

.modal-prevent-alert {
    display: flex;
    width: 370px !important;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.modal-prevent-alert div[role='document'] > div:first-child {
    padding: 24px !important;
    width: 370px !important;
}

.modal-confirm-user {
    visibility: hidden;
}

/* HEADER */
.close-icon {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    padding: 24px;
    cursor: pointer;
}

.close-icon:hover {
    opacity: 0.7;
}

.modal-header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 0;
}

.extendeal-image {
    width: 40px;
}

.avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #ebf5ff;
}

.avatar-text {
    color: #0171e6;
    font-size: 14px;
    line-height: 16px;
    font-style: normal;
    font-weight: 600;
}

/* BODY */

.modal-body {
    margin-top: 24px;
    padding: 0;
}

.modal-body-prevent-alert {
    margin-top: 0px;
    padding: 0px;
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}