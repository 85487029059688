/* -----------------------HEADER------------------------ */
.table-header {
    /* grid-row: 2; */
    position: sticky;
    z-index: 999;
    /* top: 0; */
    height: 54px;
}

.table-header > div:nth-child(2) > div {
    flex: 1;
}

/* SCROLL BAR HORIZ */
/* ocultar barra de desplazamiento horizontal */
.table-header > div:nth-child(2)::-webkit-scrollbar {
    display: none;
}

/* ocultar barra de desplazamiento horizontal */
.table-header > div:nth-child(2)::-webkit-scrollbar-thumb {
    display: none;
}

/* AFTER ELEMENT HIDDEN ARROW RECOMMENDED */
.table-header::after {
    content: '';
    position: absolute;
    top: 0px;
    right: -18px;
    width: 22px;
    z-index: -1;
    height: 92%;
    border-radius: 0px 4px 4px 4px;
    background: var(--Discount-500, #f0f5ff);
}

/* NEW STYLES */
/* WITH CDO */
.middle::-webkit-scrollbar {
    display: none;
}

.middle {
    box-shadow: 0px 1px 3px 0px rgba(64, 67, 91, 0.1), 0px 1px 2px 0px rgba(64, 67, 91, 0.06);
    background: #f0f5ff;
    border-radius: 0px 8px 8px 0px;

    scrollbar-width: none; /* hide horizontal scrollbar in Firefox */
}

.middle > div:last-child > div {
    background-color: #0056b0;
}

.middle > div:last-child {
    position: sticky;
    top: 0;
    right: 0;
}

/* WITHOUT CDO */
.middle-without-cdo::-webkit-scrollbar {
    display: none;
}

.middle-without-cdo {
    box-shadow: 0px 1px 3px 0px rgba(64, 67, 91, 0.1), 0px 1px 2px 0px rgba(64, 67, 91, 0.06);
    background: #f0f5ff;
    border-radius: 0px 8px 8px 0px;

    scrollbar-width: none; /* hide horizontal scrollbar in Firefox */
}
