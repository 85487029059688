.popover {
  padding: 16px;
}

.container-popover {
  box-shadow: rgba(20, 20, 21, 0.84) 0px 0px 1px 2px,
    rgba(20, 20, 21, 0.84) 0px 0px 0px 1000vh !important;
  z-index: 99999 !important;
}

.col {
  padding: 0px !important;
  display: flex;
  align-items: center;
}

.title {
  color: #40435b;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}

.content {
  color: #6b6f93;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding-left: 12px;
  margin: 8px 0px;
}

.footer {
  justify-content: flex-end;
  margin-top: 16px;
  gap:16px !important;
}

.col-content {
  padding: 0px !important;
  display: flex;
  flex-direction: column;
}

.counter {
  padding-left: 12px;
  margin: 0px;
  color: #6b6f93;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.numbers {
  color: #6b6f93;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}

.text-button,
.text-button:focus {
  color: #6b6f93;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  padding: 10px 16px;
  background-color: white;
}

.text-button:hover {
  color: #6b6f93;
  background-color: #eeeeee;
}

.button,
.button:focus {
  color: #6b6f93;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  padding: 10px 16px;
  background-color: white;
  border-radius: 4px;
  border: 1px solid #6b6f93;
}

.button:hover {
  background-color: #eeeeee;
  border: 1px solid #6b6f93;
  color: #6b6f93;
}

.container-modal-alert {
  padding: 8px 16px;
  width: 400px;
}

.col-content-msg {
  margin-left:10px;
  display: flex;
  flex-direction: column;
  align-content: center;
  line-height: 1.5;
  margin-bottom: 8px;
  margin-top: 8px;
  padding: 16px;
  width: 312px;
  min-height: 72px;
  border-radius: 5px;
  background-color: rgba("#FF3030", 0.15);
  color: #d20612;
  font-size: 13.5px;
  background-color: rgba(255,48,48,0.15);
}

/* Rediseño v3 */

.container-without-selection {
  padding: 16px 24px;
  width: 370px;
}

.without-selection-content-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.content-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-header-left {
  display: flex;
  gap: 8px;
}

.content-header-title {
  color: #40435B;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.without-selection-content-text {
  color: #6B6F93;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.content-footer {
  display: flex;
  flex-wrap: nowrap !important;
  margin-top: 16px;
}

.content-error-msg {
  display: flex;
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid #F8CBCB;
  background: #FFEBEB;
}