.button {
    width: 91px;
}

.close-icon {
    cursor: pointer;
}

.disabled:hover {
    cursor: not-allowed;
}
