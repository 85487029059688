.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
}
.body-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}
.title-text {
    color: var(--Neutral-800, #40435b);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Heading/Heading 3 SemiBold */
    font-family: 'Source Sans Pro';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 122.222% */
}
.subtitle-text {
    color: var(--Neutral-700, #6b6f93);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Body/Body 2/Regular */
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
}

.actions-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    height: 40px;
}

.modal-prevent-alert {
    display: flex;
    width: 370px !important;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.modal-prevent-alert div[role='document'] > div:first-child {
    padding: 24px !important;
    width: 370px !important;
}
