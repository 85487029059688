.container {
  width: 656px;
  background-color: white;
  border-radius: 8px;
  margin-top: 32px;
  padding: 24px;
  box-shadow: 0px 2px 4px -1px rgba(64, 67, 91, 0.06),
    0px 4px 5px -1px rgba(64, 67, 91, 0.1);
}

.line {
  width: 608px;
  height: 1px;
  background: #e0e1e9;
  margin-top: 24px;
  margin-bottom: 24px;
}

.card {
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 2px 4px -1px rgba(64, 67, 91, 0.06),
    0px 4px 5px -1px rgba(64, 67, 91, 0.1);
}

.secondRow-card {
  padding: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
  background-color: #f5f6fa;
  border-radius: 8px;
}

.button-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.button {
  width: 88px;
  height: 34px;
  border-radius: 8px;
  background: #0171e6;
}
