/* -----------------------HEADER------------------------ */
.toolbar-section {
    /* grid-row: 1; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    background-color: #f0f5ff;
}

.custom-position {
    position: sticky;
    z-index: 999;
    top: 0px;
    /* height: 48px; */
    align-items: flex-start;
    padding: 16px 19px 0px 19px;
}

.toolbar-section > div:first-child {
    padding: 0;
}

.toolbar-section > div:first-child > div:first-child {
    border-radius: 8px;
    border: 1px solid var(--Neutral-600, #acaec4);
    overflow: hidden;
}

.toolbar-section > div:first-child > div:first-child > input {
    background-color: #f0f5ff;
}

.toolbar-section > div:first-child > div:first-child > input:focus {
    background-color: white;
    border: 1px solid #0171e6;
}

/* PRODUCT COLUMN */
.toolbar-section > div:first-child {
    grid-column: 1 / 4;
}

.custom-position > div:first-child{
    margin: 0px !important;
    align-items: flex-start;
}