.container {
    display: flex;
    justify-content: flex-end;
    /* align-items: center; */
    gap: 24px;
    align-self: stretch;
}

.box {
    display: flex;
    align-items: center;
    gap: 8px;
}

.text {
    color: #40435b;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}

/* ENABLED */
.switch > span {
    background-color: #ACAEC4;   
}
/* DISABLED */
.switch > input:disabled ~ span {
    background-color: #ACAEC4;
}