.wrapper-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.container-breadcrumb {
  margin-bottom: 0px !important;
}
.text-breadcrumb {
  color: #0b2033;
  font-family: SourceSansProSemiBold;
  font-size: 20px;
}
.icon {
  margin-right: 16px;
}
.href-go-back {
  text-decoration: none !important;
  color: #222;
  display: flex;
  align-items: center;
}

.href-go-back:hover,
.href-go-back:active,
.href-go-back:focus {
  text-decoration: none !important;
  color: #222;
}
