.container {
    position: relative;
    width: 100%;
    margin-top: 20px;
}

.content {
    position: relative;
    top: 0;
    left: 50%;
    width: 1180px;
    transform: translateX(-50%);
    z-index: 1000;
    display: flex;
    justify-content: flex-start;
}

/* Cuando el ancho de la pantalla sea menor a 768px (mobile), centrar el contenido */
@media (max-width: 768px) {
    .content {
        width: 100%;
        left: 0;
        transform: none;
        justify-content: center;
    }
}