.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: linear-gradient(349deg, #fff -31.42%, #dcedff 105.73%);
    /* overflow: auto; */
    height: 100vh;
    padding: 105px 24px 131.842px 24px;
}

/* Estilos para pantallas pequeñas (menos de 768px) */
@media (min-width: 768px) {
    .container {
        display: none;
    }
}
