/* ---------- button styles ---------- */
.dropdown > button {
    min-width: 174px !important;
    height: 32px !important;
    padding: 8px 12px 8px 12px !important;
    gap: 8px !important;
    border-radius: 8px !important;
    border: 1px solid #0171e6 !important;
    background: #fff !important;
    display: flex !important;
    justify-content: flex-start !important;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    color: #0171e6 !important;
}

.dropdown > button > svg {
    height: 18px !important;
    top: 6px;
}

/* Cuando aria-expanded es true, se aplica la rotación */
.dropdown > button[aria-expanded='true'] > svg {
    transform: rotate(180deg);
}

/* ---------- button states ---------- */
.dropdown > button:hover {
    background: #dcedff !important;
}

.dropdown > button:disabled {
    background: none !important;
    border: 1px solid #acaec4 !important;
    color: #acaec4 !important;
    outline: none !important;
}

.dropdown > button:focus-visible {
    background: none !important;
    outline: 2px solid #badbff;
    outline-offset: 2px;
}

.dropdown > button:active {
    outline: 2.5px solid #0171e6;
    background: none;
}

/* ---------- dropdown list ---------- */
.dropdown > ul {
    width: 100%;
    padding: 8px 0px 8px 0px;
    gap: 2px;
    background: #ffffff !important;
    border: 1px solid #f5f6fa !important;
    border-radius: 8px !important;
}

/* ---------- dropdown item ---------- */
.dropdown > ul > li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    font-family: Source Sans Pro;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    height: 44px;
    padding: 12px 16px 12px 16px;
    gap: 8px;
    color: var(--800, #40435b) !important;
}
