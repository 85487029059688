.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;
    align-self: stretch;
    height: 100%;
}

.text-messages {
    color: #6B6F93;
    font-family: SourceSansProRegular;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.link-text-messages {
    color: var(--700, #6B6F93);
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;

    /* Body/Body 1/Regular */
    font-family: 'Source Sans Pro';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
}

.container-text-message {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.icon-or-image-cotainer {
    display: flex;
    justify-content: center;
}

.image {
    width: 100%;
    fill: #0171e6;
    -webkit-animation: AnimationName 5s ease infinite;
    -moz-animation: AnimationName 5s ease infinite;
    animation: AnimationName 5s ease infinite;
}

.container-img-icon {
    position: relative;
}

.container-icon {
    position: absolute;
    bottom: 58px;
    left: 64px;
    color: #fff;
}

.subtitle-text-message {
    color: #6B6F93;
    font-family: SourceSansProRegular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-top: 8px;
}

.button-upload-file {
    margin-top: 16px;
}

.message-icon {
    position: absolute;
    border: 1px solid #C6D9FE;
    bottom: 670px;
}


.message-icon div div svg {
    width: 20px;
    height: 24px;
    color: #1A66FC !important;
}

@-webkit-keyframes AnimationName {
    0% {
        fill: #0171e6;
    }
    50% {
        fill: #dcedff;
    }
    100% {
        fill: #0171e6;
    }
}
@-moz-keyframes AnimationName {
    0% {
        fill: #0171e6;
    }
    50% {
        fill: #fff;
    }
    100% {
        fill: #0171e6;
    }
}
@keyframes AnimationName {
    0% {
        fill: #0171e6;
    }
    50% {
        fill: #dcedff;
    }
    100% {
        fill: #0171e6;
    }
}
