.container-dropzone {
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    border: 0.5px dashed var(--Primary-600, #0171e6);
    background: var(--Neutral-white, #fff);
}

.container-dropzone-disabled {
    border: 0.5px dashed var(--Neutral-600, #acaec4) !important;
    background: var(--Neutral-50, #f5f6fa) !important;
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
}

.container-dropzone-error {
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    border: 0.5px dashed var(--Error-600, #e60000);
    background: var(--Neutral-white, #ffebeb);
}

.container-dropzone-error-missing-file {
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    border: 0.5px dashed var(--Error-600, #e60000);
}

.container-dropzone div[role='document'] > div:first-child {
    border-radius: 8px;
}

.text-dropzone-container {
    display: flex;
    flex-direction: column;
    gap: 0px;
}
.text-dropzone-container-disabled {
    display: flex;
    flex-direction: column;
    gap: 0px;
}
.text-dropzone {
    color: var(--Neutral-700, #6b6f93);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;

    /* Body/Body 2/Regular */
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
}

.text-uploading-file {
    color: var(--800, #40435b);
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;

    /* Body/Body 2/Regular */
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
}

.text-uploaded-file {
    color: var(--800, #40435b);
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;

    /* Body/Body 2/Bold */
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 128.571% */
}

.text-number-progress {
    color: var(--800, #40435b);
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;

    /* Body/Body 2/Regular */
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
}

.container-file-uploaded {
    display: flex;
    padding: 16px 8px 8px 8px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    background-color: #ebf5ff;
}

.container-file-uploaded-text-with-img {
    display: flex;
    justify-content: start;
    gap: 8px;
}

.container-file-uploaded-text-size {
    display: flex;
    justify-content: end;
}
