.modal {
    border-radius: 10px;
    width: 370px;
    gap: 32px;
}
.modal div[role='document'] > div:first-child {
    padding: 24px !important;
}

/* HEADER */
.modal-header {
    padding-right: 0;
    margin-top: 0px !important;
    margin-bottom: 8px !important;
}

.title-modal {
    color: #40435b;
    font-family: SourceSansProSemiBold;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 122.222% */
}
/* END */

/* BODY */
.body-modal {
    margin-top: 0px !important;
    padding-bottom: 32px !important;
    display: flex;
}

.subtitle-modal {
    color: #6b6f93;
    font-family: SourceSansProRegular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    align-self: stretch;
}

.name {
    color: var(--Neutral-700, #6B6F93);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Body/Body 2/SemiBold */
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}
/* END */

/* BUTTONS */
.action-button-modal {
    display: flex;
    height: 40px;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 8px;
    background: #0171e6;
    color: #fff !important;
}
.action-button-modal:hover {
    display: inline-flex;
    height: 40px;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #0062c8;
    color: #fff !important;
}
.action-button-modal:active {
    display: inline-flex;
    height: 40px;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #0056b0;
}

.text-button-active {
    color: #fff;
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 125% */
}
/* END */