.container {
    position: absolute;
    margin-top: 16px;
    /* el tapaño del header (76px), sumado al tamaño del texto */
    top: calc(0px + 100px);
    width: 479px;
    height: 56px;
    padding: 16px;
    background-color: #f2f7ff;
    border-radius: 8px;
    border: 1px solid #c6d9fe;
    display: flex;
    align-items: center;
}

.text {
    color: #6b6f93;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    margin-left: 12px;
}
