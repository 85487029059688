
.container-name {
  /* display: flex;
  align-items: center; */
  height: 100%;
}

.name {
  text-align: left;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  white-space: normal;
  text-align: left;
  font-family: SourceSansProSemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  padding-left: 4px;
}
.popover-container {
  padding: 16px;
  width: 400px;
  border-radius: 4px;
  background: var(--neutral-white, #fff);
  box-shadow: 0px 4px 6px -2px rgba(64, 67, 91, 0.05), 0px 10px 15px -3px rgba(64, 67, 91, 0.1);
}
.error-text {
  padding-top: 0px !important;
  color: #e22e2e;

  font-family: SourceSansProRegular;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;

  text-transform: capitalize !important;

  cursor: pointer;

  text-align: left;

  padding-left: 21px;

}
.image{
  width: 16px;
  height:16px
}
.container-text-image{
  display: flex;
  align-items: center;
}