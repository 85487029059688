.container-body {
    padding-top: 60px;
}
.label-filters {
    color: var(--Neutral-800, #40435b);

    /* Heading/Heading 3 SemiBold */
    font-family: SourceSansProSemiBold;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;

    text-transform: capitalize;
}

.container-filters-item {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.container-checkbox {
    padding-bottom: 8px;
    padding-top: 8px;
}

.container-action {
    margin-bottom: 16px;
}

.container-action-label {
    display: flex;
    align-items: center;
}

