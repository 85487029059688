.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
}

.text-date-time {
  color: #6B6F93;
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;

  margin-bottom: 10px;
}
.text-order-number {
  color: #6B6F93;
  font-family: Source Sans Pro;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}
.row-container-name{
  display: flex;
  flex-direction: column;
}
.text-drugmanufactur-title {
  color: #333;
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}
.text-subtitle{
  color:#6B6F93;
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}
.text-no-medicinals {
  color: #333;
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}
.total-value {
  color: #333;
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.avatar-icon {
  text-align: center;
  font-family: Source Sans Pro;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  width: 24px;
  height: 24px;
}
.image-icon {
  margin-right: 10px;
  width: 24px;
}
.container-footer {
  margin-top: 15px;
}
.wrapper-card {
  background-color: #fff;
  border-radius: 8px;
  width: 660px;

  transition: box-shadow 0.3s ease;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);

  cursor: pointer;

  position: relative;
}
.panel-card {
  padding: 16px 24px;
}
.panel-card div{
  padding: 0px !important;
  
}

.wrapper-card:hover {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
}
.wrapper-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.avatar-images-icon {
  color: rgba(51, 51, 51, 0.8);
  text-align: center;
  font-family: Source Sans Pro;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}
.icon-arrow {
  position: absolute;
  right: 24px;
  top: 42%;
}
.icon {
  font-size: 24px;
  color:#0171E6;
}

.client-tooltip-label {
  color: #fff;
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;

  background-color: #04273f;
}
.drug-tooltip-label {
  color: #fff;
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;

  background-color: #04273f;
}

.container-link  {
  text-decoration: none !important;
  color: #000 !important;
} 


.avatar-container-wrapper{
  display: flex;
  justify-content: center;
}