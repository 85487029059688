.title {
  color: #40435b;
  font-family: SourceSansProBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
.subtitle {
  color: #6b6f93;

  font-family: SourceSansProRegular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.container-buttons {
  padding-top: 16px;
  display: flex !important;
  justify-content: flex-end !important;
}
.buttons-update {
  color: #0171e6;
  background-color: transparent !important;
  border: 1px solid #0171e6;
  padding: 8px 12 px;
  border-radius: 8px;
}
.buttons-update:hover,
.buttons-update:active,
.buttons-update:focus {
  color: #dcedff !important;
  background-color: transparent !important;
}

.button-toolbar-content {
  gap: 16px !important;
  display: flex;
  justify-content: flex-end;
}