.accordion-header {
    padding: 15px;
    font-size: 16px;
    font-weight: bold;
    user-select: none;
    transition: background-color 0.3s ease;
    /* figma */
    width: 100%;
    height: 24px;
    padding: 0px 16px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.accordion-header > div:first-child {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}

.accordion-header > div > p {
    font-family: Source Sans Pro;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    color: var(--800, #40435b);
}

.quantity {
    font-family: Source Sans Pro;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: var(--800, #40435b);
}

.arrow {
    transition: transform 0.3s ease;
}

.arrow.up {
    transform: scaleY(-1);
}

.arrow.down {
    transform: scaleY(1);
}

.label {
    color: #0171e6;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    cursor: pointer;
}

.view-all {
    color: #0171e6;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    cursor: pointer;
    text-decoration: underline;
}

.custom-label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    color: #e22e2e;
    cursor: default;
}
