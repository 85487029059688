.container-panel {
    margin-top: 32px;
}
.title-head-panel {
    color: #000;
    font-family: SourceSansProSemiBold;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
}
.container-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    bottom: 15px;
}
.button-toolbar-content {
    width: 377px;
}
.panel-wrapper {
    /* background: var(--primary-10, #EBF5FF); */
    background: #fff;
}
.panel-wrapper div[tabindex='-1'] {
    background: var(--primary-10, #ebf5ff);
    border: 0.5px solid var(--primary-500, #84c0fe);
    border-radius: 8px 8px 0px 0px;
    height: 48px;
}
.panel-wrapper-success div[tabindex='-1'] {
    background: #fff;
    border: 0.5px solid var(--primary-500, #84c0fe);
    border-radius: 8px 8px 0px 0px;
    height: 48px;
    cursor: not-allowed;
}
.panel-wrapper div:nth-child(2) div[role='region'] {
    padding: 8px 16px 24px 16px !important;
    border-radius: 0px 0px 8px 8px !important;
    border-right: 0.5px solid var(--primary-500, #84c0fe);
    border-bottom: 0.5px solid var(--primary-500, #84c0fe);
    border-left: 0.5px solid var(--primary-500, #84c0fe);
}
.panel-wrapper-success div:nth-child(2) div[role='region'] {
    padding: 8px 16px 24px 16px !important;
    border-radius: 0px 0px 8px 8px !important;
    border-right: 0.5px solid var(--primary-500, #84c0fe);
    border-bottom: 0.5px solid var(--primary-500, #84c0fe);
    border-left: 0.5px solid var(--primary-500, #84c0fe);
}
.icon-button-content {
    background: var(--primary-10, #ebf5ff);
}
.icon-button-content:focus,
.icon-button-content:active,
.icon-button-content:hover {
    background: var(--primary-10, #ebf5ff);
}
.icon-button-content-success {
    background-color: transparent !important;
}
.icon-button-content-success:hover,
.icon-button-content-success:focus,
.icon-button-content-success:active {
    background-color: transparent !important;
}
/* .container-card-body {
  padding: 8px 16px 24px 16px;
} */
.button-toolbar-content {
    width: 377px !important;
    height: 44px !important;
    margin-top: 24px !important;
}
.button-group-content {
    border-radius: 8px;
    background: var(--secondary-50, #e3ecff);
}
.title-body-card {
    color: var(--neutral-700, #6b6f93);
    font-family: SourceSansProRegular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
.button-action {
    padding: 16px;
    width: 100%;
    height: 40px;
    border-radius: 8px;
    background: var(--secondary-50, #e3ecff);
    color: var(--neutral-700, #6b6f93);
    text-align: center;
    text-transform: uppercase;
    font-family: SourceSansProRegular;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 125% */
}
.button-action-disabled {
    padding: 16px;
    width: 100%;
    height: 40px;
    color: var(--Neutral-600, #acaec4) !important;
    text-align: center;
    text-transform: uppercase;
    font-family: SourceSansProRegular;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 125% */
}

.container-button-actions {
    margin-top: 24px;
    border-radius: 8px;
    background: var(--secondary-50, #e3ecff);
    height: 44px;
    padding: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.wrapper-button {
    margin: 8px 0px;
}
.divider {
    color: #bed0e5;
    width: 0px;
    height: 32px;
}
.row-buttons-action {
    display: contents;
}
.container-form {
    margin-top: 16px;
}
.form-control {
    height: 40px !important;
    width: 377px !important;
    padding: 10px 16px !important;
    font-family: SourceSansProRegular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    border-radius: 8px;
    background: var(--Neutral-White, #fff);
}
.form-control input {
    width: 337px !important;
    padding: 10px 16px !important;
}
.form-content {
    margin: 16px 0px;
}
.container-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.button-omit-action {
    display: flex;
    height: 40px;
    padding: 10px 24px;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 8px;
    border-radius: 8px;
    font-family: SourceSansProRegular;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: var(--primary-600, #0171e6);
    background-color: transparent !important;
}
.button-omit-action:active,
.button-omit-action:hover,
.button-omit-action:focus {
    color: var(--primary-600, #0171e6);
    background-color: transparent !important;
}
.button-action-save {
    display: flex;
    height: 40px;
    padding: 10px 24px;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid var(--primary-600, #0171e6);
    color: var(--primary-600, #0171e6);
    background-color: transparent;
    font-family: SourceSansProRegular;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.button-action-save:active,
.button-action-save:focus,
.button-action-save:hover {
    border: 1px solid var(--primary-600, #0171e6);
    color: var(--primary-600, #0171e6);
    background-color: transparent;
}
.title-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.title-content img {
    padding: 0px 8px;
}
.error-container {
    display: flex;
    align-items: center;
}
.error-container img {
    margin-right: 8px;
    width: 16px;
    height: 16px;
}
.error-container p {
    color: var(--Semantic-Error-600, #e22e2e);
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
.container-form-control {
    position: relative;
}
.icon-eye {
    position: absolute;
    right: 0;
    bottom: 2px;
}
.icon-button-eye {
    background: transparent !important;
    color: #6b6f93 !important;
}
.icon-button-eye:hover,
.icon-button-eye:active,
.icon-button-eye:focus {
    background: transparent !important;
    color: #6b6f93 !important;
}

.withOneCredential {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 24px;
    margin-bottom: 4px;
    color: var(--Neutral-800, #40435b);
    font-family: SourceSansProSemiBold;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.type-withOneCredential{
    text-transform: capitalize;
}

.email-withOneCredential{
    color: var(--Neutral-700, #6B6F93);
    font-family: SourceSansProRegular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.liner-withOneCredential {
    margin-top: 0 !important;
    color: #ACAEC4;
}
@media (max-width: 1366px) {
    .form-control input {
        width: 360px !important;
    }
}
