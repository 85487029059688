.panel-container {
  width: 382px;
  padding: 32px 24px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.icon {
  height: 32px;
  width: 240px;
  margin-bottom: 32px;
}

.title {
  color: #40435b;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 8px;
}

.content {
  color: #6b6f93;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  margin: 0px;
  padding: 0px;
}

.button-start {
  margin-top: 32px;
  display: flex;
  width: 240px;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #0171e6;
  outline: none;
  border: none;
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
  transition: all 0.2s linear;
}

.button-start:hover{
  background-color: #0062C8;
}

.button-start:active{
  background-color: #0056B0;
}

.arrow {
  height: 24px;
  width: 24px;
  margin-left: 8px;
}

.close-button {
  color: #e0e1e9;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  margin-top: 24px;
  width: 382px;

}


