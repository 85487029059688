/* contactanos */
.contact-us {
    text-decoration: underline;
    margin: 0px 8px;
    cursor: pointer;
    color: var(--Primary-600, #0171e6);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    display: flex;
    padding: 8px 8px 16px 8px;
    justify-content: center;
    align-items: flex-end;
    gap: 8px;
    align-self: stretch;
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
}

.title-form {
    color: var(--Secondary-700, #1e334a);
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: 'Source Sans Pro';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    padding: 0px 40px;
    width: max-content;
}

.title-form > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}

.subtitle {
    color: var(--Secondary-700, #1e334a);
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: 'Source Sans Pro';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
}

.welcome-text {
    color: var(--Secondary-700, #1e334a);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: 'Source Sans Pro';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}
