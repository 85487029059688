.container-selecter-picker {
    max-width: 284px;
    display: flex;
    justify-content: center;
    margin-right: 24px;
}

.wrapper-select-picker {
    position: relative;
}

.wrapper-select-picker-onBoard {
    position: relative;
    z-index: 20000;
}

.wrapper-icon-pos-reference {
    margin-top: 9px !important;
}

.select-picker {
    max-width: 284px;
    transition: none !important;
}

.reference_svg svg {
    top: 15px !important;
}

.select-picker div {
    border: none !important;
}

.select-picker div:hover {
    border: none !important;
}

.select-picker div:active {
    border: none !important;
}

.select-picker div div[title='clean'] {
    right: 0 !important;
}

.select-picker div div div span {
    padding-left: 30px !important;
    color: #fff;
    font-family: SourceSansProRegular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    padding: 0 25px !important;
}

.wrapper-icon-select {
    position: absolute;
    bottom: 0px;
    left: 8px;
    z-index: 25;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.store-icon-front {
    color: #fff;
}

.store-icon-front-disabled {
    color: #84C0FE;
}

.container-blur {
    height: 43px;
    width: 100%;
    position: absolute;
    z-index: 9999;
    opacity: 0.9;
    /* background: linear-gradient(
        90deg,
        #fff 89.92%,
        rgba(255, 255, 255, 0) 51.07%,
        rgba(255, 255, 255, 0) 34.95%,
        #fff 81.9%
    ); */
}

.menu {
    margin-top: 10px;
    padding-top: 0px !important;
    max-width: 280px !important;
}
.menu div {
    margin-bottom: 0;
}
.menu > div::-webkit-scrollbar {
    width: 0;
    /* Ancho de la barra de desplazamiento */
}

.menu > div::-webkit-scrollbar-thumb {
    background-color: transparent;
    /* Color de fondo transparente para el pulgar de la barra de desplazamiento */
    border: none;
    /* Sin borde en el pulgar de la barra de desplazamiento */
}

.menu div span {
    padding: 0px 0px !important;
}

.disabled {
    background-color: #f5f6fa !important;
    padding: 0px 0px !important;
}

.reference {
    position: relative;
    top: -3px;
    color: #6b6f93;
    text-overflow: ellipsis;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    margin: 0px;
    padding: 0px;
}
.name_pdv {
    position: relative;
    top: -7px;
    margin: 0px;
    padding: 0px;
    overflow: hidden;
    color: #acaec4;
    text-overflow: ellipsis;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}

.name_pdv_noReference {
    overflow: hidden;
    color: #fff;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 400;
    /* nuevo estilo rediseño */
    overflow: hidden;
    color: var(--Neutral-White, var(--Neutral-white, #fff));
    font-feature-settings: 'liga' off, 'clig' off;
    text-overflow: ellipsis;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
.disabled-name {
    color: #84C0FE;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    font-weight: 400;
}
.select-picker div[role='combobox'] div div svg {
    display: flex;
}
.select-picker div[role='combobox'] div > div span {
    color: #fff !important;
}

.disabled-picker div[role='combobox'][disabled='true'] {
    background: var(--Primary-600, #0171e6) !important;
    padding-right: 0px !important;
}

.select-picker > div[role='combobox'] {
    background: var(--Primary-600, #0171e6) !important;
    padding-right: 0px !important;
}

/* -------------------------------------------------- */

.select-picker-border {
    max-width: 284px;
    transition: none !important;
    border-radius: 8px;
}

.select-picker-border div {
    border: none !important;
}

.select-picker-border div:hover {
    border: none !important;
    background: var(--Primary-600, #0171e6) !important;
}

.select-picker-border div:active {
    border: none !important;
    background: var(--Primary-600, #0171e6) !important;
}

.select-picker-border div:focus {
    border: none !important;
    background: var(--Primary-600, #0171e6) !important;
}

.select-picker-borderdiv div[title='clean'] {
    right: 0 !important;
}

.select-picker-border div div div span {
    padding-left: 30px !important;
    color: #fff;
    font-family: SourceSansProRegular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    padding: 0 25px !important;
}

.select-picker-border div div div svg {
    color: #fff;
}

.select-picker-border div[role='combobox'] div > div span {
    color: #fff !important;
}


.select-picker-border div[role='combobox'] div div svg {
    display: flex;
}

.select-picker-border > div[role='combobox'] {
    background: var(--Primary-600, #0171e6) !important;
    padding-right: 0px !important;
}
