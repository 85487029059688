.title {
    color: #6b6f93;
    font-size: 12px;
    font-weight: 600;
}

.product_quantity {
    color: #6b6f93;
    font-size: 12px;
    font-weight: 600;
}

.unit_quantity {
    color: #6b6f93;
    font-size: 12px;
    font-weight: 400;
}

.label {
    display: flex;
    align-items: center;
    color: #40435B;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    gap: 4px;
    justify-content: end;
}

.paymentMethod {
    font-weight: 600;
}

.icon {
    width: 16px !important;
    height: 16px !important;
}