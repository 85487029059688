.container {
  background-color: #fff;
  padding: 16px !important;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  border-bottom: 1px solid #E0E1E9A8;
}

.container:hover {
  background-color:  #F2F7FF;
}

.container-button {
  margin-top: 8px;
}

.button-content {
  padding: 4px 16px;

  background-color: #e2ecfe;
  color: #0171e6;

  font-family: SourceSansProRegular;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  border: 1px solid #0171e6;
}

.button-content:hover,
.button-content:active,
.button-content:focus {
  background-color: #e2ecfe !important;
  color: #0171e6 !important;
}

.title-render-item {
  color: #40435B;
  font-family: SourceSansProSemiBold;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  overflow: hidden;
  white-space: no-wrap;
  text-overflow: ellipsis;
}

.title-render-item-disabled {
  color: #acaec4;
  font-family: SourceSansProSemiBold;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  overflow: hidden;
  white-space: no-wrap;
  text-overflow: ellipsis;
}



.text-address {
  color: #6b6f93;
  font-family: SourceSansProRegular;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;

}

.text-address-disabled {
  color: #acaec4;
  font-family: SourceSansProRegular;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.disabled-item {
  background-color: #f5f6fa;
  padding: 16px !important;
  border-bottom: 1px solid #E0E1E9A8;
}
.disabled-item:hover{
  opacity: 100%;
  background-color: #F2F7FF;

}
.reference-name-text {
  color: #6B6F93;
  font-family: SourceSansProRegular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.reference-name-text-disabled {
  color: #acaec4;
  font-family: SourceSansProRegular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.border-top {
  border-top: 1px solid #E0E1E9A8;
}

.border-bottom {
  border-bottom: 1px solid #E0E1E9A8;
}