.container {
  background-color: white;
  padding: 16px;
}
.inputs-container {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

.icons {
  font-size: 16px !important;
  display: flex;
  color: #0171e6;
}
.icons-disabled {
  font-size: 16px !important;
  display: flex;
  color: #acaec4;
}
.row-container {
  width: 100%;
}
.wrapper-inputs {
  margin: 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
