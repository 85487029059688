.modal {
    width: 520px;
    margin: auto !important;
}

.modal div[role='document'] > div:first-child {
    padding: 48px !important;
    width: 520px !important;
}

/* HEADER */
.close-icon {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    padding: 24px;
    cursor: pointer;
}

.close-icon:hover {
    opacity: 0.7;
}

.modal-header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 0;
}

.extendeal-image {
    width: 40px;
}

.avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #ebf5ff;
}

.avatar-text {
    color: #0171e6;
    font-size: 14px;
    line-height: 16px;
    font-style: normal;
    font-weight: 600;
}

/* BODY */

.modal-body {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 32px;
    padding: 0;
}

.vendor-info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    align-self: stretch;
}

.vendor-info-name {
    color: var(--Neutral-800, #40435b);
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;

    /* Heading/Heading 2 Semibold */
    font-family: 'Source Sans Pro';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 120% */
}

.vendor-info-description {
    color: var(--Neutral-700, #6b6f93);
    font-feature-settings: 'liga' off, 'clig' off;

    /* Body/Body 2/Regular */
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
}

.vendor-button-text-disconnect {
    color: var(--Semantic-Error-600, #e22e2e);
    font-feature-settings: 'liga' off, 'clig' off;

    /* Button/Button 1 */
    font-family: 'Source Sans Pro';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 125% */
}

.vendor-button-disconnect {
    border-radius: 8px;
    border: 1px solid var(--Semantic-Error-600, #e22e2e);
}
