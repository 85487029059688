.price-icon {
    background-color: transparent !important;
    padding: 4px 12px !important;
}
.form-control-style span {
    display: none !important;
}

.container  {
    border-radius: 8px;
    border: 1px solid var(--Neutral-700, #6b6f93);
    background: var(--Neutral-White, #fff);
}


/* Oculta los botones de incremento y decremento en los navegadores basados en Webkit (Chrome, Safari, Opera) */
.container div input[type="number"]::-webkit-inner-spin-button,
.container div input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Oculta los botones de incremento y decremento en Firefox */
.container div input[type="number"] {
    -moz-appearance: textfield;
}

