.container {
    margin: 0px 8px 16px 12px;
    padding: 0px 8px 16px 8px;
    border-radius: 8px;
    border: 0.5px solid var(--Neutral-100, #e0e1e9);
    background: var(--Neutral-50, #f5f6fa);
}
.container-catalog {
    margin: 0px 8px 16px 12px;
    padding: 0px 8px 16px 8px;
    border-radius: 8px;
}
.title-container {
    margin: 16px;
    color: var(--Neutral-800, var(--800, #40435b));
    font-feature-settings: 'liga' off, 'clig' off;

    /* Subtitle/Subtitle 1 SemiBold */
    font-family: 'Source Sans Pro';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 125% */
}

.container-list {
    padding: 10px;
}

.list-items {
    padding: 8px 0px !important;
}

.wrapper-menu-item:hover {
    background-color: #ebf5ff;
}

.wrapper-menu-item:hover .button-add-item {
    background-color: #0171e6 !important;
    color: #fff !important;
}

.wrapper-menu-item {
    display: flex;
    height: 68px;
    padding: 8px;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    background: var(--Neutral-White, #fff);
    margin-bottom: 8px;
}
.wrapper-menu-item-arrow {
    background-color: #ebf5ff;
    display: flex;
    align-items: center;
    height: 60px;
    padding: 8px 16px;
    margin-bottom: 8px;
}

.wrapper-menu-item-arrow .button-add-item {
    background-color: #0171e6 !important;
    color: #fff !important;
}

.wrapper-detail-product {
    position: 'relative';
    z-index: 0;
}

.container-image-text-menu {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.container-image-text-menu svg {
    color: #acaec4;
}
.wrapper-text-menu-item {
    margin: 0 16px;
}
.description-out-catalog:hover {
    cursor: text;
}
.description-out-catalog{
    overflow: hidden;
    color: var(--800, #40435B);
    font-feature-settings: 'liga' off, 'clig' off;
    text-overflow: ellipsis;
    
    /* Subtitle/Subtitle 1 SemiBold */
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 125% */
}
.descriptionHover:hover {
    cursor: pointer;
    text-decoration: underline;
}
.wrapper-button-menu-item {
    display: flex;
    justify-content: flex-end;
}
.button-add-item-notCatalog {
    background-color: #fff;
    color: #0171e6 !important;
    padding: 10px 24px;
    width: 122px;
    height: 40px;
    border-radius: 8px;
border: 1px solid var(--Primary-600, #0171E6);
}
.button-add-item-notCatalog:hover,
.button-add-item-notCatalog:focus,
.button-add-item-notCatalog:active {
    background-color: #0171e6 !important;
    color: #fff !important;
    border-radius: 8px;
    border: 1px solid var(--Primary-600, #0171E6);
}
.button-add-item {
    background-color: #dcedff;
    color: #0171e6 !important;
    padding: 10px 24px;
    width: 122px;
    height: 40px;
}

.button-add-item:hover,
.button-add-item:focus,
.button-add-item:active {
    background-color: #dcedff !important;
    color: #0171e6 !important;
}

.text-button-item {
    padding-left: 8px;
}

.container-panel {
    margin-top: 2px;
}

.container-quantity-item {
    margin: 16px 8px !important;
}

.quantity-items {
    color: #acaec4;
    font-family: SourceSansProRegular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.wrapper-loader {
    text-align: center;
    margin-top: 5px;
}

/* styles by counter box */
/* SET VALUES DEFAULT  */
.custom-input-number input {
    text-align: center;
    overflow: hidden;
    color: var(--neutral-600, #acaec4);
    font-feature-settings: 'clig' off, 'liga' off;
    text-overflow: ellipsis;

    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    min-width: 40px !important;
    padding: 0px !important;
}

.custom-input-number input:focus {
    color: #000;
}

.custom-input-number span {
    display: none !important;
}

/* END */

/* WRAPPER PARENT */
.wrapper-counter {
    display: inline-flex;
    height: 40px;
    width: 122px;
    padding: 10px 0px;
    align-items: center;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid var(--neutral-600, #acaec4);
    background: var(--neutral-white, #fff);
}

.wrapper-loader {
    width: 100%;
    text-align: center;
    margin-top: 5px;
}

.wrapper-counter:active {
    color: #000;
}

.wrapper-counter-error {
    border: 1px solid var(--primary-600, #e22e2e) !important;
}

/* END */

/* BUTTONS */
.wrapper-counter button {
    height: 38px;
    width: 40px;
    padding: 8px;
    background: none;
}

.wrapper-counter button:hover {
    background: var(--primary-10, #ebf5ff);
}

/* SUBS */
.wrapper-counter button:first-child {
    color: #6b6f93;
}

/* ADD */
.wrapper-counter button:last-child {
    color: #0171e6;
}

/* END */
.container-text-out-catalog{
    color: var(--700, #6B6F93);
font-feature-settings: 'liga' off, 'clig' off;

/* Body/Body 2/Regular */
font-family: "Source Sans Pro";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 18px; /* 128.571% */
display: inline-flex;
    align-items: center;
}
.container-text {
    display: inline-flex;
    align-items: center;
}
.verticalBar {
    border-left: 1px solid #acaec4;
    height: 12px;
    margin: 0 10px;
}
.container-image {
    border-radius: 8px;
    border: 0.5px solid #e0e1e9;
    background: #fff;
    padding: 4px;
}

.container-image-withoutDescription {
    border-radius: 8px;
    border: 0.5px solid var(--Neutral-100, #e0e1e9);
    background: var(--Neutral-50, #f5f6fa);
    padding: 4px;
}

.paragraphLoaderImage {
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    overflow: hidden;
    justify-content: center;
    border-radius: 8px;
}

.paragraphLoaderImage div::before {
    border-bottom: 18px solid var(--rs-bg-card) !important;
    border-left: 9px solid transparent !important;
    border-right: 9px solid transparent !important;
    left: 55px !important;
}

.paragraphLoaderImage div::after {
    border-bottom: 36px solid var(--rs-bg-card) !important;
    border-left: 19px solid transparent !important;
    border-right: 19px solid transparent !important;
    left: 25px !important;
}
.container-image-img {
    background-color: #6b6f93;
    width: 48px;
    height: 48px;
    border-radius: 8px;
    border: 0.5px solid var(--Neutral-100, #E0E1E9);
    background: var(--Neutral-50, #F5F6FA);
    display: flex;
    align-items: center;
    justify-content: center;
}