.container-avatar {
    margin: 0px;
}

.avatar-index {
    display: flex;
    width: 32px;
    height: 32px;
    padding: 0px 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 999px;
    background: var(--Primary-10, #ebf5ff);
}

.avatar-index > span {
    width: 15px;
    height: 18px;
    font-weight: 600;
    color: #0171e6;
    color: var(--Primary-600, #0171e6);
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: 'Source Sans Pro';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}
