/* FOOTER SAVING */
.grid-saving {
    display: grid;
    grid-column: 1 / 3;
    grid-template-columns: 1fr 1fr;
    height: 80px;
    position: sticky;
    bottom: 0;
    z-index: 999;
}

/* LEFT BOX */
.grid-saving > div:first-child {
    background-color: #ddf6e2;
    grid-column: 1 / 2;
}

.grid-saving > div:first-child::before {
    content: '';
    position: absolute;
    top: 0;
    left: -20px;
    width: 20px;
    height: 100%;
    background-color: #ddf6e2;
}

/* RIGHT BOX */
.grid-saving > div:last-child {
    background-color: #fff;
    display: grid;
    grid-column: 2 / 3;
    grid-template-columns: 40% 60%;
}

/* BUTTON BOX realizar pedido */
.grid-saving > div:last-child > :last-child {
    /* background-color: blue; */
    width: 100%;
    color: #fff;
}

/* SAVINGS */
.youAreSaving {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 4px;
}
.youAreSaving > div:first-child {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 40%;
}
.youAreSaving > div:last-child {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 60%;
}

.youCanSave {
    display: flex;
    justify-content: center;
    align-self: center;
    flex-wrap: wrap;
}
.youCanSave p {
    display: flex;
    justify-content: center;
    align-self: center;
    margin: 0;
}
/* BUTTON MAKE ORDER */
.box-button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.box-button button span {
    display: flex;
    justify-content: center;
    align-items: center;
}

.arrow {
    opacity: 0;
    transform: translateX(-50%);
    transition: transform 0.5s ease, opacity 0.5s ease;
}

.box-button:hover > button > span:first-child > img {
    opacity: 1;
    transform: translateX(10%);
}
