.drug-tooltip-label {
  color: #fff;
  font-family: SourceSansProRegular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;

  background-color: #04273f;
}
.image-icon {
  margin-right: 8px;
  width: 24px;
}
.avatar-images-icon {
  color: rgba(51, 51, 51, 0.8);
  text-align: center;
  font-family: SourceSansProRegular;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}
.popover-container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  border-radius: 8px !important;
}
.popover-text {
  color: #40435b;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  margin-left: 8px;
}

.wrapper-popover {
  padding: 8px 0px;
  border-radius: 8px;
  margin-top: 4px !important;
}
.not-image-avatar {
  background-color: #ebf5ff;
  height: 24px;
  text-transform: uppercase;
  width: 24px;
  margin-right: -6px !important;
  border: 1px solid #fff;
  box-shadow: 0px 1px 2px 0px rgba(64, 67, 91, 0.06),
    0px 1px 3px 0px rgba(64, 67, 91, 0.1);
}
.not-image-avatar-list {
  background-color: #ebf5ff;
  height: 24px;
  width: 24px;
  border: 1px solid #fff;
  box-shadow: 0px 1px 2px 0px rgba(64, 67, 91, 0.06),
    0px 1px 3px 0px rgba(64, 67, 91, 0.1);
}
.not-image-text {
  color: #0171e6;
  font-family: SourceSansProRegular;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
}

.avatar-moreQuantity {
  display: flex;
  height: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
  border: 0.5px solid #e0e1e9;
  background: #fff;
  width: max-content;
  /* max-width: 92px; */
  box-shadow: 0px 1px 2px 0px rgba(64, 67, 91, 0.06),
    0px 1px 3px 0px rgba(64, 67, 91, 0.1);
}

.text_avatarMoreQuantity {
  color: #40435b;
  text-align: center;
  font-size: 12px;
  padding: 4px 8px !important;
  font-style: normal;
  font-weight: 600;
}

.line-container {
  display: flex;
  margin: 8px 0px;
}
