.container {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 8px;
}

.title {
  color: #40435b;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin-top:16px;
  width: 296px;
  text-align: center;
}
