.form-button-actions-container-client {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
}

.form-button-actions-container-admin {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    width: 98%;
}

.form-button-back-container {
    width: 44px;
}
