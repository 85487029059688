.frameParent {
    position: relative;
    border-radius: 8px;
    background-color: #fff;
    width: 483px;
    display: flex;
    flex-direction: row; /* Alinear título y botón en la misma fila */
    align-items: flex-start; /* Mantenerlos en la parte superior */
    justify-content: space-between; /* Distribuir el espacio */
    box-sizing: border-box;
    gap: 16px;
    text-align: left;
    font-size: 20px;
    color: #0056b0;
    font-family: 'Source Sans Pro';
}

.contentWrapper {
    display: flex;
    flex-direction: column; /* Mantener el título arriba y el texto abajo */
    flex: 1; /* Que ocupe todo el espacio disponible */
}

.necesitasAyuda {
    line-height: 24px;
    font-weight: 600;
}

.siTensDudas {
    font-size: 16px;
    line-height: 20px;
    color: #6b6f93;
    margin-top: 8px; /* Espacio entre título y texto */
}

.closeButton {
    background: none;
    border: none;
    cursor: pointer;
    color: #6b6f93;
    display: flex;
    align-items: center;
    justify-content: center;
}

.closeButton:hover {
    color: #40435b;
}
