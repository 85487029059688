.container-form {
    margin-top: 32px;
    display: flex;
    justify-content: center;
}
.container-button-send {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
}
.button {
    width: 10%;
}
