.container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow: hidden;
}

.without-web {
    width: 100%;
    height: 130px;
    padding: 16px 0px 0px 0px;
    gap: 16px;
    border-radius: 8px 0px 0px 0px;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: flex-start;
    padding-right: 4px;
    align-items: center;
    overflow-y: auto;
}

.content::-webkit-scrollbar {
    width: 4px;
}

.content::-webkit-scrollbar-thumb {
    background: var(--Neutral-700, #6b6f93);
    border-radius: 8px;
}
