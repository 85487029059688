.container-file-system-upload {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 5 !important;
    border-left: 1px solid var(--Primary-50, #badbff);
    width: 64px;
    height: 40px;
}
.container-file-system-upload-home {
    
    z-index: 5 !important;
    border-left: 1px solid var(--Primary-50, #badbff);
    width: 64px;
    height: 40px;
}
.upload-button {
    background-color: transparent !important;
}
.upload-button div button {
    width: 135px;
}
.upload-button:hover,
.upload-button:focus,
.upload-button:active {
    background-color: none !important;
}

.upload-icon {
    color: #ff6300;
}

.button-icon-text {
    background: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 100%;
}

.button-icon-text > span:first-child {
    margin: 0px;
}

.button-icon-text:hover,
.button-icon-text:active,
.button-icon-text:focus {
    background: none !important;
}
