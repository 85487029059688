.wrapper-by-orders {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.image-icon {
  margin-right: 10px;
  width: 24px;
}
.text-by-orders {
  font-family: SourceSansProRegular;
  font-size: 16px;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
}

.name_avatarDrug {
  text-transform: uppercase;
  display: flex;
  width: 24px;
  height: 24px;
  padding: 0px 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ebf5ff;
  border-radius: 999px;
  margin-right: 8px;
}

.text_nameAvatarDrug {
  color: #0171e6;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
}
