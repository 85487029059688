

.container-icon-header {
  display: flex;
  padding: 16px;
  height: 32px;
}
.container-body-popover {
  padding: 24px;
}
.container-image-popover {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;

  margin-bottom: 24px;
}
.text-body {
  color: var(--Neutral-800, var(--800, #40435b));
  text-align: center;

  font-family: SourceSansProSemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
