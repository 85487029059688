.container {
    position: relative;
    width: 100%;
    height: 104;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    padding: 24px 16px;
    /* box-shadow: 0px -4px 6px -2px rgba(64, 67, 91, 0.10), 0px -4px 15px -3px rgba(64, 67, 91, 0.10); */
}

.button {
    margin: 0 auto;
    display: flex;
    width: 100%;
    height: 56px;
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 8px;
    background-color: #FF6300;
    cursor: pointer;
    transition: all 450ms ease-in-out;
}

.arrow {
    opacity: 0;
    transform: translateX(-100%); /* Mueve el icono hacia abajo del contenedor */
    transition: transform 1s ease, opacity 0.5s ease;
}

.button:hover {
    background: var(--discount-discount, #FF6300);
    transform: translateY(-2px);
    border-radius: 8px;
    box-shadow: 0px -4px 0px 0px #F35F02 inset, 0px 0px 0px 4px rgba(255, 99, 0, 0.20);
}

.button:hover .text {
    color: white;
}

.button:hover .arrow {
    opacity: 1;
    transform: translateX(0); /* Mueve el icono hacia arriba del contenedor */
}

@keyframes changeColor {
    0% {
        background-color: #3498db;
    }

    100% {
        background-color: #e74c3c;
    }
}

.text {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.has-scroll {
    box-shadow: 0px -4px 6px -2px rgba(64, 67, 91, 0.10), 0px -4px 15px -3px rgba(64, 67, 91, 0.10);
}