.button {
    text-decoration: none !important;
    max-width: max-content;
    display: flex;
    height: 40px !important; 
    padding: 10px 24px !important; 
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.no-errors {
    margin-top: 16px;
}

.with-errors {
    margin-top: 32px;
}

.with-errors-multiply {
    margin-top: 14px;
}