
.actions-container {
    display: flex;
    justify-content: center;
}

.container-buttons {
    width: 600px;
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
}

.primary-button:disabled {
    border-radius: 8px !important;
    background: var(--Neutral-100, #E0E1E9) !important;
    height: 40px;
    padding: 10px 24px;

    color: var(--Neutral-600, #ACAEC4) !important;
    font-feature-settings: 'clig'off, 'liga'off !important;

    /* Button/Button 1 */
    font-family: "Source Sans Pro" !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    /* 125% */
}
.primary-button {
    width: 166px;
    height: 40px;
    padding: 10px 24px;
}
.secondary-button {
    height: 40px;
    padding: 10px 24px;
}
.secondary-button:disabled {
    border-radius: 8px !important;
    border: 1px solid var(--Neutral-600, #ACAEC4) !important;

    height: 40px;
    padding: 10px 24px;

    color: var(--Neutral-600, #ACAEC4) !important;
    font-feature-settings: 'clig'off, 'liga'off !important;

    /* Button/Button 1 */
    font-family: "Source Sans Pro" !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    /* 125% */
}