.container-sidebar,
.wrapper-sidenav,
.sidenav-content {
  width: 72px;
  height: 100vh;
  background-color: #0b2033;
}

.wrapper-sidenav-blur {
  height: 100%;
  width: 72px;
  background: rgba(64, 67, 91, 0.9);
  position: absolute;
  z-index: 9999;
}
.wrapper-sidenav-focus {
  height: 100%;
  width: 72px;
  background: linear-gradient(180deg, rgba(64, 67, 91, 0.90) 71.97%, rgba(64, 67, 91, 0.00) 83.15%);

  ;
  position: absolute;
  z-index: 9999;
}
